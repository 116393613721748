import { Asset, CleanAsset } from './Asset.flow'

export type SectionId = string

export type Section = {
  id: SectionId,
  assets: Asset[] | CleanAsset[]
}

export type SectionUpdate = {
  assets: Asset[]
}

export type SectionCollection = {
  [key: SectionId]: Section
}
