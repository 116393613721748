import googleClassroom from '../googleClassroom'

const modalStep = ({ id, step, steptype, type }) => {
  var modal = document.querySelector("[data-cauliflower-modal='" + id + "']")
  let modifier = 1
  if (steptype === 'previous') {
    modifier = -1
  }

  modal.classList.remove('step-' + step)

  step = Number(step) + modifier
  modal.classList.add('step-' + step)

  if (step === 1) {
    googleClassroom.validateClassEnrollment(id)
  }

  if (step !== 0) {
    modal.querySelector('.modal__header__title').innerHTML = 'Assign to Google Classroom&trade;'
    switch (type) {
      case 'primary':
        modal.querySelector('.asset-for-modal__thumbnail-subtitle').innerHTML = 'View and Print'
        modal.querySelector('#assignment-type-' + id).value = ''
        break
      case 'studentFillIn':
        modal.querySelector('#assignment-type-' + id).value = 'Student Fill-in'
        modal.querySelector('.asset-for-modal__thumbnail-subtitle').innerHTML = 'Student Fill-in'
        break
    }
  } else {
    modal.querySelector('.modal__header__title').innerHTML = modal.querySelector('.asset-for-modal__step.step-0 .asset-for-modal__title').textContent
  }

  modal.querySelector('#assignment-type-' + id).value = modal.querySelector('#assignment-type-' + id).value === undefined ? '' : modal.querySelector('#assignment-type-' + id).value
}

export default modalStep
