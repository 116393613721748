// @flow

import $ from 'jquery'
import Mustache from 'mustache'
import CauliflowerModal from '../CauliflowerModal'
import Select from '../Select'
import VimeoButton from '../VimeoButton'
import VidyardButton from '../VidyardButton'
import QuickModal from '../QuickModal'
import { get, isUndefined } from 'lodash'
import objectToArray from './convert/objectToArray'
import { Asset, CleanAsset } from './variations/classroom/types/Asset.flow'
import { isRCMLink, hideTopBar, updateProfileName } from './common'
import {
  countAssetsByType,
  filterAndSortAssetsByType,
  sortAssets
} from './get/getAsset'
import { getAttributeFromWindow } from './get/getAttribute'
import { initializeDrawerWithSessions } from '../SessionDrawer'
import { getSection } from './get/getSection'
import { isLessonDetail } from './variations/classroom/toolbox.classroom'
import { getElements } from './get/getElement'
import openToast from './variations/classroom/actions/openToast'

export type DrawerData = {
  title: string,
  assetData: Asset[]
}

// var currentGrade = null
// var spanish = false

var drawerData = {}
var modalData = {}

var ga = window.ga || function () {}
var CONTEXT = window.CONTEXT || ''
var ASSETS = window.ASSETS || {}
var ROWDATA = window.ROWDATA || {}
// var STANDARDS = window.STANDARDS || {}
// var PAGE_PATH = window.PAGE_PATH || ''
var CURRENT_STATE = window.CURRENT_STATE || ''
// var CURRENT_GRADE = window.CURRENT_GRADE || ''
// var PREVIEW_STATE = window.PREVIEW_STATE || ''
// var IS_SPANISH = window.IS_SPANISH || false
// var IS_SPANISH_AUDIO_TAGS = window.IS_SPANISH_AUDIO_TAGS || false
var MESSAGE_SOURCE = window.MESSAGE_SOURCE || {}

var drawerFieldsTemplate = []

var iwlPlayButtonTemplate

var prereqReadyLesssons = []
var env = {}

// Only used on lesson details
var indvLessonId = null

// Our formatted / cleaned assets. Use this data for rendering the modal / indv. assets
var formattedAssets = {}

function contains (arr, asset) {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i].id === asset.id) {
      return true
    }
  }
  return false
}

function safeAdd (a, rowId, type, isPrereq) {
  if (!ROWDATA[rowId]) {
    ROWDATA[rowId] = {}
  }
  var row = ROWDATA[rowId]
  if (!row[type]) {
    row[type] = []
  }
  if (!contains(row[type], a)) {
    var pair = { id: a.id }
    if (isPrereq) {
      pair.prereq = true
    }
    row[type].push(pair)
  }
}

const shouldAssetAppearInPrereqColumn = (asset: Asset) => {
  return asset.assetType === 'rib' && !isLessonDetail()
}

const doesAssetHavePrereqs = (asset: Asset) => {
  return asset.prerequisiteFor && asset.prerequisiteFor !== ''
}

const __addAsset = a => {
  const { assetType, associatedIDs } = a
  if (doesAssetHavePrereqs(a)) {
    $.each(a.prerequisiteFor.split(' '), function (i, p) {
      // Ready Instruction Book prerequisites get their own "prereq" column
      if (shouldAssetAppearInPrereqColumn(a)) {
        safeAdd(a, p, 'prereq')
      } else {
        safeAdd(a, p, assetType, true)
      }
    })
  }
  if (associatedIDs) {
    $.each(associatedIDs.split(' '), function (i, p) {
      safeAdd(a, p, assetType)
    })
  }
}

export const getHeader = (pagePath, isSpanish, stateUrl) => {
  const url = __createHeaderUrl(pagePath, isSpanish, stateUrl)
  $.ajax({
    url,
    type: 'GET',
    cache: false,
    success: function (data) {
      $('header').html(data)
      if (isRCMLink()) {
        hideTopBar()
        $('#header__title-text').text('Teacher Toolbox')
      }
      updateProfileName(data)
      VimeoButton.init() // Now that the header is there, we might see some video buttons
      VidyardButton.init()
      const dropdownSelectors = $('.js-select')
      if (dropdownSelectors) {
        Select.init(dropdownSelectors)
      }
      renderBannerSession()
    }
  })
}

export const getCurrentAsset = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const hash = window.location.hash
  const assetId = urlParams.get('asset')
  let asset = {}
  let lesson = ''
  let displayError = false

  if (hash && hash.indexOf('not-licensed') > -1) {
    resourceNotAvailableAlert('Resource Not Available', 'There was a problem accessing this resource. This resource is not available in your Teacher Toolbox at this time.')

    var uri = window.location.toString()
    var cleanUri = uri.substring(0, uri.indexOf('#not-licensed'))

    window.history.replaceState({}, document.title, cleanUri)
  } else {
    if (assetId !== undefined && assetId != null) {
      asset = ASSETS[assetId]
      if (!asset) displayError = true
    } else {
      if (hash && hash.indexOf('#open-asset;') > -1) {
        const assetLocationInfo = hash.replace('#open-asset;', '').split('/')
        if (assetLocationInfo.length === 2) {
          lesson = assetLocationInfo[0]
          const assetId = assetLocationInfo[1]
          asset = ASSETS[assetId]
          if (!asset) displayError = true
        } else displayError = true
      }
    }

    if (displayError) {
      asset = {}
      resourceNotAvailableAlert('Resource Not Available', 'There was a problem accessing this resource. The resource may have moved or may no longer be available.')
      clearAssetParam()
    }
  }

  return {
    asset,
    lesson
  }
}

export const resourceNotAvailableAlert = (title, message) => {
  const toastData = {
    type: 'warning',
    title,
    message,
    showDismiss: true
  }
  openToast(toastData)
}

export const clearAssetParam = () => {
  const urlParams = new URLSearchParams(window.location.search)
  urlParams.delete('asset')

  window.history.replaceState(
    {},
    '',
    `${window.location.pathname}${urlParams && Object.keys(urlParams).length > 0 ? '?' : ''}${urlParams}`
  )
}

const renderBannerSession = () => {
  let checkboxToggle = document.getElementById('header-banner__toggle')
  if (checkboxToggle) {
    let $headerBannerToggle = $('#header-banner__toggle')
    let $bannerContent = $headerBannerToggle.siblings('.header-banner__content').eq(0)
    let $banner = $headerBannerToggle.closest('.header-banner')

    let displayName = checkboxToggle.dataset.displayname.replace('\\s+', '')
    let sessionKey = 'TT__Banner-Toggle__' + checkboxToggle.dataset.currentstate + '__' + displayName + '__banner-collapsed'
    let collapsed = sessionStorage.getItem(sessionKey)
    let clsExpand = 'expanded'
    let clsCollapsed = 'collapsed'
    let clsTransitioning = 'transitioning'

    if (collapsed === 'true') {
      $headerBannerToggle.prop('checked', true)
      $bannerContent.addClass(clsCollapsed)
      $bannerContent.removeClass(clsExpand)
    } else {
      $headerBannerToggle.prop('checked', false)
      $bannerContent.removeClass(clsCollapsed)
      $bannerContent.addClass(clsExpand)
    }

    $('body').on('click', '.header-banner__content.collapsed', () => {
      $headerBannerToggle.attr('checked', false)
      $headerBannerToggle.trigger('click')
    })

    $('body').on('change', '#header-banner__toggle', () => {
      $banner.addClass(clsTransitioning)
      setTimeout(() => {
        $bannerContent.toggleClass(clsExpand)
        $bannerContent.toggleClass(clsCollapsed)
        $banner.removeClass(clsTransitioning)
      }, 750)
    })
  }
}

const __createHeaderUrl = (pagePath, isSpanish, stateUrl, isRCM) => {
  const spanishSegment = isSpanish ? '~sp~' : ''
  const rcmSegment = isRCM ? 'isRCM=true' : ''

  const params = [stateUrl, rcmSegment].filter(p => {
    return p !== ''
  })

  return `${pagePath}${spanishSegment}.header?${params}`
}

export const createStateUrl = previewState => {
  var stateUrl = ''

  if (!isUndefined(previewState) && previewState !== '') {
    stateUrl += 'previewState=' + previewState
  }
  return stateUrl
}

export function initWithoutHeader (
  pagePath,
  grade,
  previewState,
  isSpanish,
  isIndvLesson,
  isSpanishAudienceTags
) {
  // spanish = typeof IS_SPANISH_AUDIO_TAGS !== 'undefined' && (IS_SPANISH && IS_SPANISH)
  isIndvLesson = isIndvLesson || false

  CauliflowerModal.init()

  const assets = objectToArray(ASSETS)

  assets.forEach(asset => __addAsset(asset, isIndvLesson))

  iwlPlayButtonTemplate = __getPlayButtonTemplate()
  env = getEnvironment()
}

export function init (
  pagePath,
  grade,
  previewState,
  isSpanish,
  isIndvLesson,
  isSpanishAudienceTags
) {
  initWithoutHeader(
    pagePath,
    grade,
    previewState,
    isSpanish,
    isIndvLesson,
    isSpanishAudienceTags
  )
  getHeader(pagePath, isSpanish, createStateUrl(previewState))
}

export const generateDrawerFieldsTemplate = assetId => {
  return [
    'title',
    'subtitle',
    'audience',
    'pages',
    'prereq',
    'levelStatus',
    'copyrightYear'
  ]
    .map(property => generateElementTemplate(property, undefined, assetId))
    .join('')
}
const __getPlayButtonTemplate = () => $('#iwl-play-button-template').html()

const onDrawerImageClick = e => {
  const assetId = $(e.target)
    .closest('.asset')
    .data('id')

  const data = drawerData.assetData.find(asset => asset.id === assetId)
  const html = buildAssetModal(data)

  modalData = data
  modalData.fullLessonName =
    (drawerData.lessonPrefix !== '' ? drawerData.lessonPrefix + ' ' : '') +
    drawerData.title

  QuickModal.openModal(drawerData.title, html)
}

const onSubnavButtonClick = e => {
  const $t = $(e.target)

  $('.sub-nav button').removeClass('selected')
  $t.addClass('selected')

  $('section').hide()
  $('section.' + $t.attr('target')).show()
}

const onAssetClick = e => {
  e.preventDefault()
  var $element = $(e.target)
  var assetId = $element.data('id')

  // We handle this one separately since these two asset types are bundled
  var data = formattedAssets[assetId]

  var html = buildAssetModal(data)

  var lessonPrefix = $('.details-header .lessonNumber')
    .text()
    .trim()
  var lessonTitle = $('.details-header .lessonTitle')
    .text()
    .trim()

  modalData = data
  modalData.fullLessonName =
    (lessonPrefix !== '' ? lessonPrefix + ': ' : '') + lessonTitle

  QuickModal.openModal(data.title, html)
}

const onAssetOpenClick = e => {
  const $cb = $('.modal .confirm_checkbox')
  if ($cb.length > 0) {
    if (!$cb.is(':checked')) {
      e.preventDefault()
      return
    }
  }

  logAssetOpenAnalytics(
    modalData.id,
    modalData.assetType,
    modalData.name,
    modalData.fullLessonName
  )
}

const __attachHandlersForTOC = () => {
  $('.full').on('click', 'a', onDrawerButtonClick)

  $('.drawer').on('click', '.asset img', onDrawerImageClick)

  $('.sub-nav').on('click', 'button', onSubnavButtonClick)

  $('body').on(
    'click',
    '.modal .asset a.img, .modal .asset .open-btn',
    onAssetOpenClick
  )
}

/**
 * Add icons to a page with a resource table.
 *
 */
const __addIconsToTable = () => {
  const handles = Array.from(document.querySelectorAll('.drawer-handle'))
  handles.forEach((handle, index) => {
    const row = handle.parentNode
    const id = row.dataset.id
    const types = handle.dataset.assetType.split(' ')

    types.map(type => {
      const data = get(ROWDATA, [id, type], false)
      if (data) {
        const span = handle.querySelector('span')
        span.classList.add('full')

        if (data.length > 1) {
          span.classList.add('multiple')
        }
      }
    })
  })
}

/**
 * Init for TOC page
 *
 * @param pagePath
 * @param grade
 * @param previewState
 * @param isSpanish
 */
export function initTOC (
  pagePath,
  grade,
  previewState,
  isSpanish,
  isSpanishAudienceTags
) {
  init(pagePath, grade, previewState, isSpanish, false, isSpanishAudienceTags)

  __addIconsToTable()
  __attachHandlersForTOC()
}

/**
 * Logs our analytics when opening an asset
 *
 * @param assetId
 * @param assetType
 * @param fileName
 * @param lesson
 */
export function logAssetOpenAnalytics (assetId, assetType, fileName, lesson, subType) {
  $.ajax({
    url: CONTEXT + '/api/v1/toc/assetLogger',
    data: {
      assetId: assetId,
      lesson: lesson,
      fallbackState: CURRENT_STATE,
      assetSubtype: subType
    },
    method: 'POST',
    beforeSend: function (xhr) {
      xhr.overrideMimeType('text/plain; charset=x-user-defined')
    }
  })

  ga('send', 'event', assetType, fileName)
}

/**
 * Init for individual lesson page
 *
 * @param pagePath
 * @param grade
 * @param previewState
 * @param isSpanish
 * @param lessonId
 * @param isSpanishAudienceTags
 */
export function initIndividualLesson (
  pagePath,
  grade,
  previewState,
  isSpanish,
  lessonId,
  isSpanishAudienceTags
) {
  init(pagePath, grade, previewState, isSpanish, true, isSpanishAudienceTags)
  indvLessonId = lessonId
  const section = getSection(indvLessonId)
  const rows = getElements('.asset-section [data-asset-type]')
  const assetCountsByType = countAssetsByType(section.assets, section.id)
  rows.forEach(row => {
    const types = row.dataset.assetType.split(' ')

    types.forEach(type => {
      const count = assetCountsByType[type]

      if (count > 0) {
        // row.classList.add('lesson__detail__asset-section--is-active')
        var $section = $(row).parent()
        if (!$section.is(':visible')) {
          $section.show()
        }

        const assets = filterAndSortAssetsByType(type, section.assets)

        assets.map(asset => {
          formattedAssets[asset.id] = asset
        })

        $(row).append(assets.map(renderAsset).join(''))
      }
    })
  })

  // TODO clean assets on page load rather than click
  $('.wrapper').on('click', '.asset-section .asset', onAssetClick)

  $('body').on(
    'click',
    '.modal .asset a.img, .modal .asset .open-btn',
    onAssetOpenClick
  )
}

// TODO: Update this to use a Mustache Template file "assetModal.html"
const buildAssetModal = asset => {
  var html
  var drawerCheckbox = ''

  const isTradebook = asset.assetType === 'tradebook'
  const hasStandards = asset.standards && asset.standards.length > 0
  const isVideo = !!asset.videoId
  const isVimeo = asset.videoType === 'vimeo'
  const isVidyard = asset.videoType === 'vidyard'
  const isIWL = asset.assetType === 'iwl'

  if (isTradebook) {
    drawerCheckbox = getDrawerCheckbox()
  }

  var appendedTemplate = ''

  var objectiveTemplate =
    '{{#objective}}<div class="standardsContainer"><p class="head">Objective</p><span class="objective">{{{objective}}}</span></div>{{/objective}}'

  const elementTemplateConfig = {
    escapeHTML: false,
    tag: 'span'
  }

  var standardsTemplate = hasStandards
    ? `
      <div class="standardsContainer">
        <p class="head">Standards</p>
        {{#standards}}
          <div class="asset-for-modal__standards-description">
            <div class="asset-for-modal__standards-label">{{label}}</div>
            <div className="asset-for-modal__standards-text">
                ${generateElementTemplate('description', elementTemplateConfig)}
            </div>
          </div>
        {{/standards}}
      </div>`
    : ''

  if (isVideo) {
    html = '<div class="asset video" data-video-type="{{videoType}}">'

    if (isVimeo) {
      html +=
        '<iframe src="https://player.vimeo.com/video/{{videoId}}" width="640" height="360" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>'
    } else if (isVidyard) {
      html +=
        '<script type="text/javascript" id="vidyard_embed_code_{{videoId}}" src="//play.vidyard.com/{{videoId}}.js?v=3.1.1&type=inline"></script>'
    }

    appendedTemplate += '</div>'
  } else {
    html =
      '<div class="asset"><a class="img asset__image-link" href="{{link}}" target="_blank"><img class="asset__img" src="{{thumbnail}}"/></a>'
    if (isIWL) {
      appendedTemplate += iwlPlayButtonTemplate
      asset.flashNumber = getFlashNumber()
    } else {
      appendedTemplate +=
        '<a class="open-btn button secondary" href="{{link}}" target="_blank">{{openText}}</a>'
    }
    appendedTemplate += '</div>'
  }

  html +=
    drawerFieldsTemplate +
    generateElementTemplate('credit') +
    drawerCheckbox +
    appendedTemplate +
    objectiveTemplate +
    standardsTemplate

  return Mustache.render(html, asset)
}

function getFlashNumber () {
  switch (env.os.name) {
    case 'Mac':
      return getMacNumber()
    case 'Windows':
      return getWindowsNumber()
    case 'Chrome OS':
      return '0039'
  }
  return '0001'
}

function getWindowsNumber () {
  var version = env.os.version
  var browserName = env.browser.name

  var sevenRegex = new RegExp('^(6.1).*')
  var eightRegex = new RegExp('^(6.[23]).*')
  var tenRegex = new RegExp('^(10).*')

  // If this is windows 7
  if (sevenRegex.test(version)) {
    switch (browserName) {
      case 'Internet Explorer':
        return '0016'
      case 'Firefox':
        return '0017'
      case 'Chrome':
        return '0019'
    }
    // If this is windows 8
  } else if (eightRegex.test(version)) {
    switch (browserName) {
      case 'Internet Explorer':
        return '0016'
      case 'Firefox':
        return '0017'
      case 'Chrome':
        return '0019'
    }

    // If this is windows 10
  } else if (tenRegex.test(version)) {
    switch (browserName) {
      case 'Edge':
        return '0065'
      case 'Firefox':
        return '0028'
      case 'Chrome':
        return '0030'
    }
  }

  return '0001'
}

function getMacNumber () {
  var browserName = env.browser.name
  switch (browserName) {
    case 'Safari':
      var version = env.browser.version
      var tenPattern = new RegExp('^(10).*')
      var elevenPattern = new RegExp('^(11).*')

      if (tenPattern.test(version)) {
        return '0033'
      } else if (elevenPattern.test(version)) {
        return '0067'
      }

      return '0066'
    case 'Firefox':
      return '0034'
    case 'Chrome':
      return '0036'
  }

  return '0001'
}

function getDrawerCheckbox () {
  return '<div class="confirm"><label><input class="confirm_checkbox" type="checkbox">By opening this PDF, I agree not to print, electronically distribute, or otherwise disseminate its content.</label></div>'
}

export var generateElementTemplate = function (property, options, assetId) {
  var settings = $.extend(
    {
      tag: 'p',
      conditionalRender: true,
      className: property,
      escapeHTML: true
    },
    options
  )

  var dataAction =
    property === 'title'
      ? `data-id="${assetId}" data-action="open-asset-modal"`
      : ''
  var template =
    '<' +
    settings.tag +
    ' ' +
    dataAction +
    ' class="' +
    settings.className +
    '">{{' +
    (!settings.escapeHTML ? '{' : '') +
    property +
    '}}' +
    (!settings.escapeHTML ? '}' : '') +
    '</' +
    settings.tag +
    '>'
  if (settings.conditionalRender) {
    template = '{{#' + property + '}}' + template + '{{/' + property + '}}'
  }

  return template
}

function onDrawerButtonClick (e) {
  e.preventDefault()

  var $anchor, $td, $tr, id

  $anchor = $(e.target)
  $td = $anchor.closest('td')
  $tr = $td.closest('tr')
  id = $tr.data('id')

  const assetTypes = $td.data('asset-type').split(' ')

  const section = getSection(id)
  const assets = filterAndSortAssetsByType(assetTypes, section.assets)

  setDrawerData(assets, e, assetTypes)
}

export function cleanAssetData (assets: Asset[], lessonId): CleanAsset[] {
  return assets.map(asset => cleanAsset(asset.id, lessonId))
}

/**
 * Cleans an individual asset's data, preparing it to be rendered.
 *
 * @param assetId - the ID of the asset. Used to retrieve the data from ROWDATA
 * @param lessonId - ID of current lesson
 */
function cleanAsset (assetId, lessonId): CleanAsset {
  const ASSETS = getAttributeFromWindow('ASSETS')
  var node = $.extend({}, ASSETS[assetId])

  if (node.assetType === 'iwl' && !node.hasOwnProperty('minutes')) {
    if (node.estimatedTime !== '') {
      node.minutes = Math.round(node.estimatedTime / 60) + ' min'
    } else {
      node.minutes = ''
    }
  }
  if (node.assetType === 'iwl') {
    if (node.minutes.length > 0) {
      node.openText = 'Play - ' + node.minutes
    } else {
      node.openText = 'Play'
    }
  } else {
    node.openText = 'Open'
  }

  // prepend "Pages" to pages property if defined and not empty and "Pages" is not already present.
  if (
    node.pages &&
    typeof node.pages !== 'undefined' &&
    node.pages !== '' &&
    node.pages.indexOf('p.') !== 0
  ) {
    node.pages = 'p. ' + node.pages
  }

  const shouldUseSpanish =
    getAttributeFromWindow('IS_SPANISH_AUDIENCE_TAGS') &&
    getAttributeFromWindow('IS_SPANISH')

  const messagePath =
    'asset.label.audience.' + node.audience + (shouldUseSpanish ? '_sp' : '')
  var applicableMessage = MESSAGE_SOURCE[messagePath]

  node.originalAudience = node.audience
  node.audience = node.audience === 'not-applicable' ? '' : applicableMessage

  const levelStatusBaseMessagePath = 'asset.label.levelstatus.'
  const levelStatusPath =
    levelStatusBaseMessagePath +
    node.levelStatus +
    (shouldUseSpanish ? '_sp' : '')

  node.levelStatus = MESSAGE_SOURCE[levelStatusPath]

  if (~node.prerequisiteFor.indexOf(lessonId)) {
    const prereqPath =
      'asset.label.prerequsite' + (shouldUseSpanish ? '_sp' : '')
    if (!isLessonDetail() && node.assetType !== 'rib') {
      node.prereq = MESSAGE_SOURCE[prereqPath]
    } else if (isLessonDetail()) {
      node.prereq = MESSAGE_SOURCE[prereqPath]
    }
  }

  node.fixedPrereq = node.prereq || 'none'

  return node
}

function getEnvironment () {
  var osData = [
    { name: 'Windows', value: 'Win', version: 'NT' },
    { name: 'Mac', value: 'Mac', version: 'OS X' },
    { name: 'Chrome OS', value: 'CrOS', version: 'CrOS' }
  ]

  var browserData = [
    { name: 'Edge', value: 'Edge', version: 'Edge' },
    { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
    { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
    { name: 'Safari', value: 'Safari', version: 'Version' },
    { name: 'Internet Explorer', value: 'Windows', version: 'Windows' }
  ]

  // Linux x86_64 Mozilla/5.0 (X11; CrOS x86_64 9460.73.1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/59.0.3071.134 Safari/537.36 5.0 (X11; CrOS x86_64 9460.73.1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/59.0.3071.134 Safari/537.36 Google Inc.
  var envString = [
    navigator.platform,
    navigator.userAgent,
    navigator.appVersion,
    navigator.vendor,
    window.opera
  ].join(' ')

  var os = findMatch(osData, envString)
  var browser = findMatch(browserData, envString)

  return {
    os: os,
    browser: browser
  }
}

function findMatch (data, envString) {
  var returnData = {
    name: 'unknown',
    version: 0
  }

  data.some(function (env) {
    var valueRegex = new RegExp(env.value)
    if (valueRegex.test(envString)) {
      var versionRegex = new RegExp(env.version + '[- /:;]([\\d._]+)', 'i')
      var versionMatches = envString.match(versionRegex)
      if (versionMatches && versionMatches[1]) {
        versionMatches = versionMatches[1]
      }

      var version = '0'
      if (versionMatches) {
        var splitMatches = versionMatches.split(/[._]+/)
        if (splitMatches.length > 0) {
          splitMatches[0] += '.'
        }
        version = splitMatches.join('')
      }

      returnData = {
        name: env.name,
        version: version
      }

      return true
    }
    return false
  })

  return returnData
}

function setDrawerData (data, e, assetTypes) {
  // ND TODO: This needs refactoring!
  var $t = $(e.target)
  var $td = $t.closest('td')
  var $drawer = $t.closest('.row').next('.drawer')
  var $drawerContainer = $drawer.find('td .container')
  var $alreadyOpen = $td.hasClass('selected') // TODO: get truth out of the DOM

  $('.drawer').removeClass('selected')

  if (!$alreadyOpen) {
    $drawer.addClass('selected')
  }

  closeDrawers()
  hideBorder()

  if ($alreadyOpen) {
    return
  }

  $td.addClass('selected')

  if (assetTypes.indexOf('rib') > -1) {
    data = data.filter(function (obj) {
      /*
       * If a prereq is present, strip it out
       * It will get shown in the prereqs column instead
       */
      if (prereqReadyLesssons.indexOf(obj.id) > -1) {
        console.log('rejecting: ' + obj.id)
        return false
      }

      return true
    })
  }

  data = sortAssets(data, assetTypes)

  /// Render the Templates
  var mustacheData = {}
  mustacheData.assetData = data
  mustacheData.title = $td
    .closest('tr')
    .find('td.title')
    .attr('title')

  drawerData = mustacheData

  drawerData.lessonPrefix = $td
    .closest('tr')
    .find('a.lessonNumber')
    .text()
    .trim()

  var html = renderDrawerContents(drawerData)
  $drawerContainer.html(html)
  showBorder($t)
  openDrawer($t)
}

function openDrawer ($t) {
  var $drawer = $t.closest('.row').next('.drawer')
  var $drawerContainer = $drawer.find('td .container')
  const pageHasSessionsEnabled =
    getAttributeFromWindow('PAGE_PATH') === 'classroom-resources'

  $drawer.addClass('selected')
  $drawer.show()
  $drawerContainer.slideDown(400, function () {
    // We need to wait until the drawer is completely revealed before we initialize. This is because the drawer will have a height of 0.
    // This throws off the calculations that will occur.
    var hasSessions =
      $drawerContainer.children('.drawer-with-sessions').length > 0
    if (hasSessions && pageHasSessionsEnabled) {
      // If this drawer contains Sessions, we need to add a couple event listeners to show which one is "active";
      initializeDrawerWithSessions($drawerContainer)
    }
  })
}

function showBorder ($t) {
  var $td = $t.closest('td')
  $td
    .prev()
    .prev()
    .addClass('adjacantSelected')

  $t.closest('tr').addClass('selectedRow')
  $t.closest('tr')
    .prev()
    .prev()
    .addClass('adjacantSelected')
}

function hideBorder () {
  $('.selectedRow').removeClass('selectedRow')
  $('.row td').removeClass('selected')
  $('.adjacantSelected').removeClass('adjacantSelected')
}

function closeDrawers () {
  $('.drawer:visible')
    .not('.selected')
    .find('td .container')
    .slideUp({ done: hideDrawer })
}

function hideDrawer (obj) {
  var $container = $(this)
  // $container.closest('.drawer').hide()
  $container.children('*').remove() // Remove everything inside the drawer when it is closed to prevent collisions that caused this issue -> https://jira.cainc.com/browse/TBX-2013
}

/// /////////////////////////////////////////////////////////
/// Sessions                                               //
/// ----------                                             //
/// If every asset in a lesson has a Drawer Label, we'll   //
/// sort and display them by those labels, which           //
/// represent sessions.                                    //
/// /////////////////////////////////////////////////////////

const hasSession = asset => {
  const { drawerLabel } = asset
  const isNotNull = drawerLabel !== null
  const isNotBlank = drawerLabel !== ''
  return isNotNull && isNotBlank
}

/**
 * Determine if all assets have a drawer label.
 * If this fails, content will be
 * displayed normally.
 */
export function doAssetsHaveSessions (assets: Asset[]): Boolean {
  return assets.length > 0 && assets.every(hasSession)
}

/**
 * Format sessionTitle so that it works as an anchor link.
 * i.e. Session 2: Develop => session-2-develop
 * @param {String} sessionTitle
 */
export function formatSessionTitle (sessionTitle) {
  const regex = /[^a-z\d]+/g // anything that isn't alphanumeric. The plus means it's greedy, and will group together every character that matches until it fails.
  return sessionTitle.toLowerCase().replace(regex, '-')
}

/**
 * Takes an array of assets, and returns all the unique
 * sessions in alphabetical order.
 * @param {Asset[]} assets
 */
export function findSessionsFromAssets (assets: Asset[]) {
  const sessions = assets
    .map(asset => asset.drawerLabel)
    .filter((value, index, array) => {
      return array.indexOf(value) === index && value !== null
    })

  return sortSessionLabels(sessions)
}

/**
 * Renders a single asset.
 * @param {Object} asset
 */
function renderAsset (asset) {
  var elementTemplate = generateElementTemplate('minutes')
  var assetTemplate = `
      <div class="asset" data-id="${asset.id}" data-action="open-asset-modal">
        <div class="asset__container">
          <div class="asset__thumbnail-wrapper">
            <img src="${asset.thumbnail}" data-id="${asset.id}" data-action="open-asset-modal"/>
            ${generateAlternatesDrawerTemplate(asset)}
          </div>
          <figcaption class="meta-data asset__meta-data figure-caption" >
              ${elementTemplate}
              ${generateDrawerFieldsTemplate(asset.id)}
          </figcaption>
        </div>
      </div>
    `
  return Mustache.render(assetTemplate, asset)
}

function generateAlternatesDrawerTemplate (asset) {
  var alternatesTemplate = ``
  asset.alternates.sort((a, b) => (a.key > b.key) ? -1 : 1)

  if (asset.alternates.length > 0 && asset.alternates.filter(al => al.key !== 'google').length > 0) {
    alternatesTemplate =
      `<div class="asset__alternates">
        <div class="asset__alternate-type primary"></div>
        ${asset.alternates.map((alternate, i) =>
    `<div class="asset__alternate-type ${asset.alternates[i].key}"></div>`
  ).join('')}
      </div>`
  }

  return alternatesTemplate
}

/**
 * Renders a single asset with a wrapper. Only used by sessions-drawer.
 * @param {Object} asset
 *
 */
function renderAssetWithWrapper (asset) {
  return `<div class="asset__wrapper">${renderAsset(asset)}</div>`
}

/**
 * Renders all assets, wrapped in .assets.
 * @param {Array} assets - Assets to render.
 * @param {Boolean} withWrapper - include optional wrapper for flex sizing.
 *
 */
function renderAssets (assets, withWrapper) {
  var renderFunction = withWrapper ? renderAssetWithWrapper : renderAsset
  return `<div class="assets">${assets.map(renderFunction).join('')}</div>`
}

/**
 * Renders content for Asset drawer.
 * Could be normal
 * (if there are assets without a drawer label)
 * or could be displayed with sessions.
 * @param {Object} drawerData
 */
function renderDrawerContents (drawerData) {
  var assets = drawerData.assetData
  const pageHasSessionsEnabled =
    getAttributeFromWindow('PAGE_PATH') === 'classroom-resources'
  if (doAssetsHaveSessions(assets) && pageHasSessionsEnabled) {
    return renderDrawerContentsWithSessions(drawerData)
  } else {
    return renderDrawerContentsWithoutSessions(drawerData)
  }
}

/**
 * Handles the normal drawer rendering, which
 * lists out assets in order.
 * @param {*} drawerData
 */
function renderDrawerContentsWithoutSessions (drawerData) {
  var assets = renderAssets(drawerData.assetData)
  var title = '<p class="title">{{title}}</p>'
  var render = Mustache.render(title + assets, drawerData)
  return render
}

/**
 * Ties together all the drawer data and renders a complete drawer
 * that meet the criteria of being session compatible.
 * Namely, every asset has a non-empty drawerLabel
 * @param {Object} drawerData
 */
export function renderDrawerContentsWithSessions (drawerData) {
  var assets = drawerData.assetData
  var sessions = findSessionsFromAssets(assets)
  var sessionsWithAssets = sortAssetsBySession(sessions, assets)

  var sidebar = renderSessionSidebar(sessions)
  var content = renderSessionContent(sessionsWithAssets)

  return Mustache.render(
    `
      <div class="drawer-with-sessions">
        ${sidebar}
        ${content}
      </div>
    `
  )
}

/**
 * Render a session, to be displayed in the content
 * section of a drawer.
 * @param {Object} session
 */
function renderSession (session) {
  let formattedSessionTitle = formatSessionTitle(session.title)
  return `
        <div id="${formattedSessionTitle}" class="session">
            <h2 class="session__header">${session.title}</h2>
            ${renderAssets(session.assets, true)}
            <hr/>
        </div>
    `
}

/**
 * Render Sidebar for a drawer with session information
 * @param {Array} sessionsWithAssets - Sessions with their attached assets.
 */
function renderSessionContent (sessionsWithAssets) {
  return `
        <div class="drawer-with-sessions__content">
            ${sessionsWithAssets.map(renderSession).join('')}
        </div>
    `
}

/**
 * Render Sidebar for a drawer with session information
 * @param {Array} sessions
 */
function renderSessionSidebar (sessions) {
  var renderSessionTitle = session =>
    `
        <a class="drawer-with-sessions__sidebar__title" href="#${formatSessionTitle(
    session
  )}">${session}</a>
    `
  var sessionTitles = sessions.map(renderSessionTitle).join('')
  return `
        <div class="drawer-with-sessions__sidebar">
            <div class="drawer-with-sessions__sidebar__header">Sessions</div>
            ${sessionTitles}
        </div>
    `
}

/**
 * Connects sessions and their assets.
 * @param {Array} sessions
 * @param {Array} assets
 */
export function sortAssetsBySession (sessions: string[], assets: Asset[]) {
  return sessions.map(session => ({
    title: session,
    assets: assets.filter(asset => asset.drawerLabel === session) // TODO: Optimize by removing previously sorted assets
  }))
}

/**
 * Sorts session names. Expected session labels are defined within the content app.
 **
 * @param {String[]} sessions - labels for sessions
 */
function sortSessionLabels (sessions) {
  const sorting = getAttributeFromWindow('DRAWER_LABELS')
  const sortedSessions = []
  sorting.forEach(function (key) {
    sessions.forEach(function (session) {
      if (session === key) {
        sortedSessions.push(session)
      }
    })
  })
  return sortedSessions
}

export default {
  init,
  initWithoutHeader,
  initTOC,
  initIndividualLesson
}
