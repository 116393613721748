// @flow
import { get } from 'lodash'
import {
  getValueAccessorForField,
  getDependantFieldNames
} from './RegistrationMappers'
import reduceConcat from '../common/reduce/reduceConcat'
import { isValidField } from './RegistrationValidators'
import {
  getElement,
  getElements,
  getElementFromNode,
  getElementsFromNode
} from '../common/get/getElement'
import { Field, FieldName, Control } from './RegistrationTypes'
import {
  SCHOOL_SEARCH_FIELD_NAMES,
  INSTITUTION_MODAL_FIELD_NAMES
} from './RegistrationConstants'

/// ///////////////////////
/// getOne
/// ///////////////////////
const __getSelectorForField = (fieldName: FieldName) => {
  return `[data-field-name="${fieldName}"]`
}

export const getField = (fieldName: FieldName | Field): Field => {
  // Convieniently skip the search if a Field is accidentally provided.
  if (!isValidField(fieldName)) return null
  if (fieldName && fieldName.nodeName) return fieldName

  // Otherwise look for the Field using this selector.
  const selector = __getSelectorForField(fieldName)
  const field = getElement(selector)
  if (field === null) {
    throw new Error(`No Field found for "${selector}"`)
  } else {
    return field
  }
}

export const getFieldName = (field: Field | FieldName): FieldName => {
  // Convieniently skip the search if a FieldName is accidentally provided.
  if (typeof field === 'string') return field

  // Otherwise look for the fieldName property
  const name = get(field, ['dataset', 'fieldName'], null)
  if (name === null) {
    console.error(field)
    throw new Error(`No FieldName Found`)
  } else {
    return name
  }
}

export const getTypeForControl = (control: Control): string => {
  return control.type
}

export const getControlForField = (field: Field): Control => {
  return getElementFromNode(field, 'input, select')
}

export const getSelectedSchool = (): HTMLElement => {
  return getElement(
    '[data-search-results] [data-school-result][data-selected="true"]'
  )
}

export const getSelectedSchoolInfo = () => {
  const school = getSelectedSchool()
  const id = school.dataset.id
  const name = school.dataset.name
  const region = school.dataset.region
  const country = school.dataset.country
  const stateOrProvince = school.dataset.stateOrProvince
  const city = school.dataset.city

  return {
    name,
    region,
    id,
    country,
    stateOrProvince,
    city
  }
}

export const getInstitutionInfo = () => {
  const name = getElement('[data-field-name="institution-name"] input').value
  const city = getElement('[data-field-name="institution-city"] input').value
  const select = getElement('[data-field-name="institution-state"] select')
  const fullState = select.options[select.selectedIndex].innerHTML

  const id = ''

  return {
    name,
    city,
    fullState,
    id
  }
}

/// ///////////////////////
/// getAll
/// ///////////////////////

export const getAllFields = (): Field[] => {
  const selector = `[data-field-name]`
  return getElements(selector)
}

export const getAllControls = () => {
  return getAllFields()
    .map(getControlsForField)
    .reduce(reduceConcat)
}

/// ///////////////////////
/// get Some
/// ///////////////////////
export const getControlsForField = (field: Field): Control[] => {
  return getElementsFromNode(field, 'input, select')
}

export const getDependantFields = (field: Field) => {
  const fieldNames = getDependantFieldNames(getFieldName(field))
  return fieldNames.map(getField)
}

export const getSchoolSearchFields = (): Field[] => {
  return SCHOOL_SEARCH_FIELD_NAMES.map(getField)
}

export const getInstitutionModalFields = (): Field[] => {
  return INSTITUTION_MODAL_FIELD_NAMES.map(getField)
}

export const getSchoolSearchControls = (): Field[] => {
  return getSchoolSearchFields()
    .map(getControlsForField)
    .reduce(reduceConcat)
}

export const getInstitutionModalControls = (): Field[] => {
  return getInstitutionModalFields()
    .map(getControlsForField)
    .reduce(reduceConcat)
}

/// ///////////////////////
/// get Value
/// ///////////////////////

export const getFieldValue = (fieldOrFieldName: Field | FieldName) => {
  const field = getField(fieldOrFieldName)
  const accessor = getValueAccessorForField(field)
  return accessor(field)
}

export const getValueFromTextControl = (control: Control) => {
  return control.value
}
export const getValueFromRadioControls = (radios: Control[]): string => {
  const selectedRadio = radios.find(radio => radio.checked)
  return selectedRadio ? selectedRadio.value : ''
}

export const getValueFromSelectOptionControls = (
  options: Control[]
): string => {
  const selectedOption = options.find(option => option.selected)

  if (typeof selectedOption === 'undefined') {
    return selectedOption
  } else {
    return selectedOption.value
  }
}
