import $ from 'jquery'

const toggleMenu = (data) => {
  let $menu = $('.user-menu__toggle').closest('.user-menu')
  if (!$menu.hasClass('initialized')) {
    init($menu)
  }
  $menu.toggleClass('open')
}

const init = ($menu) => {
  $(document).on('mouseup', e => {
    if (!$menu.is(e.target) && $menu.has(e.target).length === 0) {
      $menu.removeClass('open')
    }
  })
  $menu.on('click', 'a', e => {
    $menu.removeClass('open')
  })

  $menu.addClass('initialized')
}

export default toggleMenu
