import $ from 'jquery'

var initialized = false

var currentName = ''
var closeAlerts = []
var canClose = true

var _modalOpen = false

var init = function () {
  if (initialized) {
    // console.error('QuickModal.init should only be called once.')
    return
  }

  initialized = true

  $('body').prepend('<div class="modal-overlay"></div>')
  $('body').prepend(
    '<div class="modal"><div class="header"><span class="title"></span><div class="close-btn"></div></div><div class="content"></div></div>'
  )
  $('.modal .close-btn, .modal-overlay').on('click', closeModal)

  $('body').focus(checkFocus)
}

var openModal = function (title, content, name, closable) {
  if (!initialized) {
    init()
  }
  if (typeof closable === 'undefined') {
    closable = true
  }

  _modalOpen = true

  canClose = closable

  if (canClose) {
    $('.modal .close-btn').show()
  } else {
    $('.modal .close-btn').hide()
  }

  $('.modal, .modal-overlay').show()
  $('body').addClass('modal-open')
  $('.modal .title').html(title)
  $('.modal .content').html(content)

  currentName = name || ''

  $('body').on('keydown, keyup', checkFocus)
}

var closeModal = function (callbackParam, force) {
  force = typeof force !== 'undefined' ? force : false

  if (!canClose && !force) {
    console.error(
      'QuickModal cannot close, closable parameter on open was set to false.'
    )
    return
  }

  _modalOpen = false

  $('.modal, .modal-overlay').hide()
  $('body').removeClass('modal-open')

  for (var i = 0; i < closeAlerts.length; i++) {
    if (currentName === closeAlerts[i]['name']) {
      currentName = closeAlerts[i]['callback'](callbackParam)
    }
  }

  $('.modal .title').empty()
  $('.modal .content').empty()
  $('body').off('keydown, keyup', checkFocus)
}

var addCloseAlert = function (name, callback) {
  closeAlerts.push({ name: name, callback: callback })
}

var checkFocus = function (e) {
  if (!modalOpen) {
    // Precondition: Modal should be opened to continue.
    return
  }

  var modalInFocus = $(document.activeElement).closest('.modal').length
  if (!modalInFocus) {
    takeFocus()
  }
}

var takeFocus = function (e) {
  console.log('taking focus')
  $('.modal input[type="text"], button')
    .first()
    .focus()
}

var modalOpen = function () {
  return _modalOpen
}

export default {
  init: init,
  openModal: openModal,
  closeModal: closeModal,
  addCloseAlert: addCloseAlert,
  takeFocus: takeFocus,
  modalOpen: modalOpen
}
