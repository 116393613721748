import Mustache from 'mustache'
import $ from 'jquery'

import { Toast } from './templates'

const successIcon = `<i class="___Icon__ca-icon ca-icon-performance-check header-icon ca-fw"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28" height="22">
<path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M24 4.33L10.67 17.67 4 11"></path>
</svg></i>`
const errorIcon = `<i class="___Icon__ca-icon ca-icon-event-error header-icon ca-fw"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28" height="22">
<g fill="none" stroke="currentColor" stroke-miterlimit="10">
  <circle cx="14" cy="11" r="10" stroke-width="2" stroke="black"></circle>
  <path stroke-width="1.5" d="M21.47 3.53L6.53 18.47" stroke="black"></path>
</g>
</svg></i>`
const warningIcon = `<i class="___Icon__ca-icon ca-icon-exclamation-triangle header-icon ca-fw"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="22" height="22">
<path d="M1.2 17.6L9.69 2.89a1.51 1.51 0 0 1 2.62 0L20.8 17.6a1.52 1.52 0 0 1-1.32 2.27h-17A1.52 1.52 0 0 1 1.2 17.6z" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="1.6"></path>
<path d="M11 15.19a1.48 1.48 0 1 0 1.48 1.48A1.48 1.48 0 0 0 11 15.19z" fill="currentColor"></path>
<path fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.2" d="M11 6.31v6.51"></path>
</svg></i>`

export const closeToast = () => {
  $('.toast').fadeOut('slow', function () {
    $(this).remove()
  })
}

export const renderToast = data => {
  closeToast()
  let icon = successIcon
  if (data && data.type) {
    // type set to err instead of error to avoid conflicting styling with other error classes
    if (data.type === 'err') {
      icon = errorIcon
    } else if (data.type === 'warning' || data.type === 'gray-warning') {
      icon = warningIcon
    }
  }
  // add toast to body so it can be used anywhere on ttbox
  $('body').prepend(Mustache.render(Toast, { ...data, icon }))

  if (data.noClose === undefined || data.noClose == null || data.noClose === false) {
    // start timeout after toast is rendered
    setTimeout(function () {
      closeToast()
    }, (typeof data.timeout !== 'undefined' && data.timeout && !isNaN(data.timeout) ? data.timeout : 7000))
  }

  $('.js-close-toast').on('click', function () {
    closeToast()
  })
}
