// @flow

export const getElementFromNode = (node: HTMLElement, selector: string) => {
  return node.querySelector(selector)
}

export const getElementsFromNode = (node: HTMLElement, selector: string) => {
  return Array.from(node.querySelectorAll(selector))
}

export const getElement = (selector: string) => {
  return getElementFromNode(document, selector)
}

export const getElements = (selector: string) => {
  return getElementsFromNode(document, selector)
}
