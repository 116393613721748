import $ from 'jquery'
import { getAttributeFromWindow } from '../../get/getAttribute'
import Select from '../../../Select'
import MultiSelect from '../../../MultiSelect'
import flatpickr from 'flatpickr'
import Form from '../../../Form'
import openToast from './actions/openToast'
import logGoogleAnalytics from './actions/logGoogleAnalytics'
import CauliflowerModal from '../../../CauliflowerModal'

let GOOGLECLASSROOMINFO = {
  courses: [],
  topics: [],
  students: []
}
const CONTEXT = getAttributeFromWindow('CONTEXT')
const apiEndpoints = {
  getUserStatus: CONTEXT + '/api/v1/google/auth/loggedIn',
  getClassroomInfo: CONTEXT + '/api/v1/google/classroom/courses/info',
  getCourses: CONTEXT + '/api/v1/google/classroom/courses',
  getCourse: CONTEXT + '/api/v1/google/classroom/course/',
  getTopics: CONTEXT + '/api/v1/google/classroom/courses/topics?courseIds=',
  getStudents: CONTEXT + '/api/v1/google/classroom/course/'
}

const callApi = (endpoint) => {
  let call = $.ajax({
    url: endpoint,
    method: 'GET',
    cache: false,
    dataType: 'json',
    error: function (errMsg) {
      callError(errMsg)
    }
  })

  setTimeout(function () {
    call.abort()
  }, 30000)

  return call
}

const callError = (errMsg) => {
  if (errMsg.statusText === 'abort' || errMsg.status === 500) {
    const toastData = {
      type: 'err',
      title: 'Error',
      message:
        'An error has occurred. Please reload your browser or try again.',
      showDismiss: true
    }
    CauliflowerModal.closeAll()
    openToast(toastData)
  }
  if (errMsg.status === 401) {
    window.location = window.CONTEXT + '/login.html'
  }
}

const postClassroomAsset = data => {
  const CONTEXT = getAttributeFromWindow('CONTEXT')
  const assetId = data.assetId

  return $.ajax({
    url: CONTEXT + '/api/v1/google/classroom/assignment/create',
    method: 'POST',
    cache: false,
    contentType: 'application/json',
    dataType: 'json',
    data: JSON.stringify(data),
    success: function (data) {
      const toastData = {
        type: 'success',
        title: 'Assignment Successful',
        message:
          'The resource is being uploaded to your Google Drive and an assignment is being created in your Google Classroom. It may take a moment for it to appear.',
        showDismiss: true
      }
      CauliflowerModal.closeAll()
      resetModal(assetId)
      openToast(toastData)
    },
    error: function (errMsg) {
      if (errMsg.status === 401) {
        window.location = window.CONTEXT + '/login.html'
      }
      const toastData = {
        type: 'err',
        title: 'Assignment Unsuccessful',
        message:
          'There was a problem assigning to Google Classroom. Please try again.',
        showDismiss: true
      }
      CauliflowerModal.closeAll()
      resetModal(assetId)
      openToast(toastData)
    }
  })
}

const getIDModifier = $modal => {
  let id = $modal.attr('data-cauliflower-modal')
  return '-' + id
}

const initFormInfo = ($modal, modalSelector, assetType) => {
  if (Object.keys(GOOGLECLASSROOMINFO.courses).length > 0) {
    if (!$modal.hasClass('initialized')) {
      initFields($modal, modalSelector, assetType)
      $modal.addClass('initialized')
      showHideLoader($modal, false)
    } else {
      showHideLoader($modal, false)
    }
  } else {
    callApi(apiEndpoints.getCourses).done(function (response) {
      GOOGLECLASSROOMINFO.courses = response.courses
      GOOGLECLASSROOMINFO.courses.forEach(course => {
        course.courseId = course.id
        course.teacherFolderId = course.teacherFolder.id
        course.courseName = course.name
      })
      let courseIds = GOOGLECLASSROOMINFO.courses.map(function (course) {
        return course.id
      }).join(',')
      callApi(apiEndpoints.getTopics + courseIds).done(function (topicsResponse) {
        GOOGLECLASSROOMINFO.topics = []
        topicsResponse.forEach(classTopics => {
          GOOGLECLASSROOMINFO.topics.push(...classTopics.topic.map(classTopic => classTopic.name))
        })
        GOOGLECLASSROOMINFO.topics = [...new Set(GOOGLECLASSROOMINFO.topics)]
        GOOGLECLASSROOMINFO.topics.sort(function (a, b) {
          if (a < b) { return -1 }
          if (a > b) { return 1 }
          return 0
        })
        if (!$modal.hasClass('initialized')) {
          initFields($modal, modalSelector, assetType)
          $modal.addClass('initialized')
          showHideLoader($modal, false)
        } else {
          showHideLoader($modal, false)
        }
      })
    })
  }
}

const initClassroom = id => {
  const modalSelector = '[data-cauliflower-modal="' + id + '"]'
  const $modal = $(modalSelector)
  const idModifier = getIDModifier($modal)
  const urlParams = new URLSearchParams(window.location.search)
  const assetType = urlParams.get('assetType')
  const isRedirect = urlParams.get('isRedirect')
  showHideLoader($modal, true)

  callApi(apiEndpoints.getUserStatus).done(function (result) {
    $modal.find('.asset-for-modal__link.google-classroom-link').show()
    if (result.code === 201) {
      $modal.find('.asset-for-modal__google-classroom-banner').hide()
      $modal.find('.asset-for-modal__link.google-classroom-link').attr('data-action', 'modal-step')
      $modal.find('.tooltip').addClass('tooltip--hidden')

      initFormInfo($modal, modalSelector, assetType)
    } else {
      if (result.code === 401 && isRedirect === 'true') {
        const toastData = {
          type: 'warning',
          title: 'Authentication Unsuccessful',
          message:
            'Teacher Toolbox could not authenticate with Google services. Please check all of the permissions boxes to allow access to both Google Drive and Google Classroom and try again.',
          showDismiss: true
        }
        openToast(toastData)
      }
      $modal.find('.asset-for-modal__google-classroom-banner').show()
      $modal.find('.asset-for-modal__link.google-classroom-link').attr('data-action', 'assign-to-google-classroom')
      showHideLoader($modal, false)
    }

    urlParams.delete('asset')
    urlParams.delete('assetType')
    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}` +
        (urlParams.length > 0 ? `${urlParams}` : ``) +
        `${window.location.hash}`
    )
  })

  $modal.on('click', '.asset-for-modal__link.google-classroom-link', function (e) {
    e.preventDefault()
  })

  $modal.on('change', 'input[name=assign-to]', function () {
    if ($(this).val() === 'schedule-assignment') {
      $('#assignment-date-container' + idModifier).removeClass('disabled')
      $('#assignment-time-container' + idModifier).removeClass('disabled')
    } else {
      $('#assignment-date-container' + idModifier).addClass('disabled')
      $('#assignment-time-container' + idModifier).addClass('disabled')
    }
  })
}

const initFields = ($modal, modalSelector, assetType) => {
  const id = $modal.attr('data-cauliflower-modal')
  const idModifier = getIDModifier($modal)
  let classroomInfo = GOOGLECLASSROOMINFO

  let $dateFields = $modal.find('.date-picker')
  if ($dateFields.length > 0) {
    flatpickr(modalSelector + ' #due-date' + idModifier, {
      prevArrow: '&#9664;',
      nextArrow: '&#9654;',
      monthSelectorType: 'static',
      dateFormat: 'm/d/y',
      position: 'above center',
      positionElement: $modal.find('#due-date-container' + idModifier)[0],
      static: true,
      disable: [
        function (date) {
          let today = new Date()
          return (
            date.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0)
          )
        }
      ],
      onChange: function (selectedDates, dateStr, instance) {
        if (selectedDates.length > 0) {
          flatpickr(modalSelector + ' .time-picker#due-time' + idModifier, {
            enableTime: true,
            noCalendar: true,
            dateFormat: 'h:i K',
            position: 'above center',
            positionElement: $modal.find('#due-time-container' + idModifier)[0],
            static: true,
            defaultHour: 23,
            defaultMinute: 59,
            onChange: () => dueTimeValidation($modal)
          })
          $modal.find('#due-time-container' + idModifier).show()

          dueTimeValidation($modal)
        } else {
          $modal.find('#due-time-container' + idModifier).hide()
        }
      }
    })

    flatpickr(modalSelector + ' #assignment-date' + idModifier, {
      prevArrow: '&#9664;',
      nextArrow: '&#9654;',
      monthSelectorType: 'static',
      dateFormat: 'm/d/y',
      position: 'below center',
      positionElement: $modal.find('#assignment-date-container' + idModifier)[0],
      static: true,
      disable: [
        function (date) {
          let today = new Date()
          return (
            date.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0)
          )
        }
      ],
      onChange: function (selectedDates, dateStr, instance) {
        if (selectedDates.length > 0) {
          flatpickr(modalSelector + ' .time-picker#assignment-time' + idModifier, {
            enableTime: true,
            noCalendar: true,
            dateFormat: 'h:i K',
            position: 'below center',
            positionElement: $('#assignment-time-container' + idModifier)[0],
            static: true,
            defaultHour: 8,
            defaultMinute: 0,
            onChange: function (selectedTimes, timeStr, timeInstance) {
              let validTime = assignmentTimeValidation($modal)
              if (validTime && selectedTimes.length > 0 && $modal.find('#assignment-date' + idModifier).val() !== '') {
                $modal.find('#step-2-submit' + idModifier).removeClass('disabled')
              } else {
                $modal.find('#step-2-submit' + idModifier).addClass('disabled')
              }
            }
          })
          $modal.find('#assignment-time-container' + idModifier).show()

          let validTime = assignmentTimeValidation($modal)

          if (validTime && $modal.find('#assignment-time' + idModifier).val() !== '') {
            $modal.find('#step-2-submit' + idModifier).removeClass('disabled')
          } else {
            $modal.find('#step-2-submit' + idModifier).addClass('disabled')
          }
        } else {
          $('#assignment-time-container' + idModifier).hide()
          $modal.find('#step-2-submit' + idModifier).addClass('disabled')
        }
      }
    })
  }

  $modal.find('#points-select' + idModifier).text('100').val('100')

  if (Array.isArray(classroomInfo.courses) && classroomInfo.courses.length) {
    let $courseSelect = $modal.find('#class-select' + idModifier).siblings('.ca-select-options')
    $courseSelect.html('')
    classroomInfo.courses.forEach(course => {
      $courseSelect.append(`
      <li data-value="${course.id}" data-display-name="${course.name}" role="option" tabindex="-1">
        <input type="checkbox" id="class-${course.id}-${id}" value="${course.id}" name="class" />
        <label for="class-${course.id}-${id}">${course.name}</label>
      </li>
      `)
    })
    $('body').on('input', modalSelector + ' #class-select' + idModifier, function (e) {
      let courseIdsVal = $(this).attr('data-value')
      let $studentsSelect = $modal
        .find('#student-select' + idModifier)
        .siblings('.ca-select-options')
      $studentsSelect.html('')
      $studentsSelect.closest('.js-multi-select').removeClass('selected').attr('aria-expanded', 'false')
      $studentsSelect.siblings('.ca-select-options').removeClass('hidden-all')

      if (courseIdsVal.length > 0) {
        $studentsSelect.closest('.form__field').removeClass('disabled')
        $modal
          .find('#student-select' + idModifier)
          .attr('data-display-name', 'All Students')
          .attr('data-value', '')
          .val('All Students')
          .text('All Students')
        $studentsSelect.append(`
        <li data-value="" data-display-name="All Students" role="option" tabindex="-1">
          <input type="checkbox" id="student-all-students" value="" name="student" checked="checked" default-option />
          <label for="student-all-students">All Students</label>
        </li>
        `)
        let courseIds = courseIdsVal.split(',')
        if (courseIds.length === 1) {
          let courseIndex = classroomInfo.courses.findIndex(c => c.id === courseIds[0])
          let courseInfo = classroomInfo.courses.find(c => c.id === courseIds[0])
          if (courseInfo !== undefined) {
            $studentsSelect.closest('.js-multi-select').removeClass('disabled')
            $studentsSelect.closest('.form__field').removeClass('multiple-selected')

            if (courseInfo.students === undefined) {
              callApi(apiEndpoints.getStudents + courseInfo.id + '/students').done(function (response) {
                classroomInfo.courses[courseIndex].students = response.students !== undefined ? response.students : []
                classroomInfo.courses[courseIndex].students = [...new Set(classroomInfo.courses[courseIndex].students)]
                populateStudentsSelect($modal, courseInfo)
              })
            } else {
              populateStudentsSelect($modal, courseInfo)
            }
          }
        } else {
          $studentsSelect
            .closest('.form__field')
            .addClass('multiple-selected')
          $studentsSelect.closest('.js-multi-select').addClass('disabled')
        }
      } else {
        $modal
          .find('#student-select' + idModifier)
          .attr('data-display-name', '')
          .attr('data-value', '')
          .val('')
          .text('')
        $studentsSelect.closest('.js-multi-select').removeClass('disabled')
        $studentsSelect
          .closest('.form__field')
          .addClass('disabled')
          .removeClass('multiple-selected')
      }
    })
  }

  if (classroomInfo.topics != null) {
    let $topicSelectInput = $modal.find('#topic-select' + idModifier)
    let $topicSelect = $topicSelectInput.siblings('.ca-select-options')

    $topicSelectInput.text('No Topic').val('No Topic').prop('readonly', true)
    $topicSelect.html('')
    $topicSelect.append(`
      <li data-value="" data-display-name="No Topic" role="option" tabindex="-1" data-default-option data-readonly>No Topic</li>
      <li data-value="" data-display-name="Create Topic" role="option" tabindex="-1" placeholder-option>Create Topic</li>
    `)
    classroomInfo.topics.forEach(topic => {
      $topicSelect.append(`
        <li data-value="${topic}" data-display-name="${topic}" role="option" tabindex="-1" data-readonly>${topic}</li>
      `)
    })
    $topicSelectInput.on('blur', function (e) {
      if ($(e.relatedTarget).attr('data-display-name') === undefined && $topicSelectInput.val() === '') {
        if (!$topicSelectInput.closest('.ca-select').hasClass('editable')) {
          $topicSelectInput
            .text('No Topic')
            .val('No Topic')
            .prop('readonly', true)
            .removeAttr('placeholder')
            .trigger('focusout')
        }
      }
    })
  }
  if (assetType !== undefined && assetType != null) {
    $modal.find('.asset-for-modal__link.google-classroom-link[data-type="' + assetType + '"]').trigger('click')
    $modal.removeClass('step-0')
    $modal.addClass('step-1')
    switch (assetType) {
      case 'primary':
        $modal
          .find('.asset-for-modal__thumbnail-subtitle')
          .html('View and Print')
        $modal.find('#assignment-type' + idModifier).val('')
        break
      case 'studentFillIn':
        $modal
          .find('.asset-for-modal__thumbnail-subtitle')
          .html('Student Fill-in')
        $modal.find('#assignment-type' + idModifier).val('studentFillIn')
        break
    }
  }

  const singleSelectSelectors = $modal.find('.js-select')
  if (singleSelectSelectors) {
    Select.init(singleSelectSelectors)
  }
  const multiSelectSelectors = $modal.find('.js-multi-select')
  if (multiSelectSelectors) {
    MultiSelect.init(multiSelectSelectors)
  }

  initStepOneForm($modal)
  initStepTwoForm($modal)
  if (assetType !== undefined && assetType != null) {
    validateClassEnrollment()
  }
}

const populateStudentsSelect = ($modal, courseInfo) => {
  const idModifier = getIDModifier($modal)

  let $studentsSelect = $modal
    .find('#student-select' + idModifier)
    .siblings('.ca-select-options')

  if (courseInfo !== undefined) {
    if (courseInfo.students !== undefined) {
      courseInfo.students.forEach(student => {
        $studentsSelect.append(`
        <li data-value="${student.userId}" data-display-name="${student.profile.name.fullName}" role="option" tabindex="-1">
          <input type="checkbox" id="student-${student.userId}${idModifier}" value="${student.userId}" name="student" />
          <label for="student-${student.userId}${idModifier}">${student.profile.name.fullName}</label>
        </li>
        `)
      })
    }
  }
}

const initStepOneForm = $modal => {
  const idModifier = getIDModifier($modal)
  let $stepOneForm = $modal.find('.asset-for-modal__step.step-1 .form')

  if (!$stepOneForm.hasClass('initialized')) {
    $stepOneForm.on('submit', function (e) {
      stepOneValidation(e, $modal)
    })
    $stepOneForm.find('input').on('keypress', function (e) {
      if (e.which === 13) {
        stepOneValidation(e, $modal)
      }
    })

    $stepOneForm.find('#assignment-title' + idModifier).on('input', function (e) {
      if (
        $stepOneForm.find('#assignment-title' + idModifier).val() !== '' &&
        $stepOneForm.find('#assignment-title' + idModifier).val() !== null &&
        $stepOneForm.find('#class-select' + idModifier).val() !== '' &&
        $stepOneForm.find('#class-select' + idModifier).val() !== null
      ) {
        $stepOneForm.find('#step-1-submit' + idModifier).removeClass('disabled')
      } else {
        $stepOneForm.find('#step-1-submit' + idModifier).addClass('disabled')
      }
    })

    $stepOneForm.find('#class-select' + idModifier).on('input', function (e) {
      if (
        $stepOneForm.find('#assignment-title' + idModifier).val() !== '' &&
        $stepOneForm.find('#assignment-title' + idModifier).val() !== null &&
        $stepOneForm.find('#class-select' + idModifier).val() !== '' &&
        $stepOneForm.find('#class-select' + idModifier).val() !== null
      ) {
        $stepOneForm.find('#step-1-submit' + idModifier).removeClass('disabled')
      } else {
        $stepOneForm.find('#step-1-submit' + idModifier).addClass('disabled')
      }
    })

    $stepOneForm.addClass('initialized')
  }
}

const stepOneValidation = (e, $modal) => {
  e.preventDefault()
  const id = $modal.attr('data-cauliflower-modal')
  const idModifier = getIDModifier($modal)
  let allowNextStep = true

  if ($modal.find('#assignment-title' + idModifier).val() === '' || $modal.find('#assignment-title' + idModifier).val() === null) {
    allowNextStep = false
    Form.showErrorForField(
      'step-1' + idModifier,
      'assignment-title-field',
      'Title is required'
    )
  } else {
    if ($modal.find('#assignment-title' + idModifier).val().length > 3000) {
      allowNextStep = false
      Form.showErrorForField(
        'step-1' + idModifier,
        'assignment-title-field',
        'Title may not be more than 3000 characters'
      )
    } else {
      Form.hideValidationForField(id + '-step-1', 'assignment-title-field')
    }
  }

  if ($modal.find('#instructions' + idModifier).val() !== null) {
    if ($modal.find('#instructions' + idModifier).val().length > 30000) {
      allowNextStep = false
      Form.showErrorForField(
        'step-1' + idModifier,
        'assignment-instructions-field',
        'Instructions may not be more than 30000 characters'
      )
    }
  }
  if ($modal.find('#class-select' + idModifier).val() === '' || $modal.find('#class-select' + idModifier).val() === null) {
    allowNextStep = false
    Form.showErrorForField(
      'step-1' + idModifier,
      'assignment-classes-field',
      'Must select at least 1 class'
    )
  } else {
    Form.hideValidationForField('step-1' + idModifier, 'assignment-classes-field')
  }

  if (isNaN($modal.find('#points-select' + idModifier).val()) && $modal.find('#points-select' + idModifier).val() !== 'Ungraded') {
    allowNextStep = false
    Form.showErrorForField(
      'step-1' + idModifier,
      'assignmnet-points-field',
      'Numerical Values Only'
    )
  } else {
    Form.hideValidationForField('step-1' + idModifier, 'assignment-points-field')
  }

  allowNextStep = dueTimeValidation($modal) ? allowNextStep : false

  if (allowNextStep) {
    $modal.removeClass('step-1').addClass('step-2')
  }
}

const dueTimeValidation = $modal => {
  let allowNextStep = true
  const idModifier = getIDModifier($modal)

  let dueDatePickr = $modal.find('#due-date' + idModifier)[0]._flatpickr
  let dueTimePickr = $modal.find('#due-time' + idModifier)[0]._flatpickr
  if (dueDatePickr.selectedDates[0] !== undefined && dueTimePickr.selectedDates[0] === undefined) {
    allowNextStep = false
    Form.showErrorForField(
      'step-1' + idModifier,
      'due-time-container',
      'Time Required'
    )
  } else if (dueDatePickr.selectedDates[0] !== undefined) {
    let now = new Date()
    if (dueDatePickr.selectedDates[0].toLocaleDateString('en-US') === now.toLocaleDateString('en-US')) {
      let selectedTime = Number(dueTimePickr.selectedDates[0].getHours()).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) + ':' + Number(dueTimePickr.selectedDates[0].getMinutes()).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
      let currentTime = Number(now.getHours()).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) + ':' + Number(now.getMinutes()).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })

      if (selectedTime <= currentTime) {
        allowNextStep = false
        Form.showErrorForField(
          'step-1' + idModifier,
          'due-time-container',
          'Future Time Required'
        )
      } else {
        Form.hideValidationForField('step-1' + idModifier, 'due-time-container')
      }
    } else {
      Form.hideValidationForField('step-1' + idModifier, 'due-time-container')
    }
  } else {
    Form.hideValidationForField('step-1' + idModifier, 'due-time-container')
  }

  return allowNextStep
}

const assignmentTimeValidation = $modal => {
  let allowNextStep = true
  const id = $modal.attr('data-cauliflower-modal')
  const idModifier = getIDModifier($modal)

  let assignmentDatePickr = $modal.find('#assignment-date' + idModifier)[0]._flatpickr
  let assignmentTimePickr = $modal.find('#assignment-time' + idModifier)[0]._flatpickr

  if (assignmentDatePickr.selectedDates[0] !== undefined && assignmentTimePickr.selectedDates[0] === undefined) {
    allowNextStep = false
    Form.showErrorForField(
      'step-2' + idModifier,
      'assignment-schedule-time-field',
      'Time required'
    )
  } else if (assignmentDatePickr.selectedDates[0] !== undefined) {
    let now = new Date()
    if (assignmentDatePickr.selectedDates[0].toLocaleDateString('en-US') === now.toLocaleDateString('en-US')) {
      let selectedTime = Number(assignmentTimePickr.selectedDates[0].getHours()).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) + ':' + Number(assignmentTimePickr.selectedDates[0].getMinutes()).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
      let currentTime = Number(now.getHours()).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) + ':' + Number(now.getMinutes()).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })

      if (selectedTime <= currentTime) {
        allowNextStep = false
        Form.showErrorForField(
          'step-2' + idModifier,
          'assignment-schedule-time-field',
          'Future Time Required'
        )
      } else {
        Form.hideValidationForField('step-2' + idModifier, 'assignment-schedule-time-field')
      }
    } else {
      Form.hideValidationForField('step-2' + idModifier, 'assignment-schedule-time-field')
    }
  } else {
    Form.hideValidationForField('step-2' + idModifier, 'assignment-schedule-time-field')
  }

  return allowNextStep
}

const initStepTwoForm = $modal => {
  let $stepTwoForm = $modal.find('.asset-for-modal__step.step-2 .form')
  const idModifier = getIDModifier($modal)

  if (!$stepTwoForm.hasClass('initialized')) {
    $stepTwoForm.on('submit', function (e) {
      $stepTwoForm.find('#step-2-submit' + idModifier).addClass('disabled')
      stepTwoValidation(e, $modal)
    })
    $stepTwoForm.find('input').on('keypress', function (e) {
      if (e.which === 13) {
        $stepTwoForm.find('#step-2-submit' + idModifier).addClass('disabled')
        stepTwoValidation(e, $modal)
      }
    })
    $stepTwoForm
      .find('input[type=radio][name="assign-to"]')
      .on('change', function () {
        if (this.value === 'assign-immediately') {
          Form.hideValidationForField(
            'step-2' + idModifier,
            'assignment-schedule-date-field'
          )
          Form.hideValidationForField(
            'step-2' + idModifier,
            'assignment-schedule-time-field'
          )
          $modal.find('#step-2-submit' + idModifier).removeClass('disabled')
        } else {
          $modal.find('#step-2-submit' + idModifier).addClass('disabled')
        }
      })

    $stepTwoForm.addClass('intialized')
  }
}

const stepTwoValidation = (e, $modal) => {
  e.preventDefault()
  const id = $modal.attr('data-cauliflower-modal')
  const idModifier = getIDModifier($modal)
  let allowNextStep = true

  if ($modal.find('input[name="assign-to"]:checked').val() === 'schedule-assignment') {
    if (
      $modal.find('#assignment-date' + idModifier).val() === '' ||
      $modal.find('#assignment-date' + idModifier).val() === null
    ) {
      allowNextStep = false
      Form.showErrorForField(
        'step-2' + idModifier,
        'assignment-schedule-date-field',
        'Date required'
      )
    } else {
      Form.hideValidationForField(
        'step-2' + idModifier,
        'assignment-schedule-date-field'
      )
    }

    allowNextStep = assignmentTimeValidation($modal) ? allowNextStep : false
  } else {
    Form.hideValidationForField(
      'step-2' + idModifier,
      'assignment-schedule-date-field'
    )
    Form.hideValidationForField(
      'step-2' + idModifier,
      'assignment-schedule-time-field'
    )
  }

  if (allowNextStep) {
    submitData($modal, id)
  } else {
    setTimeout(() => {
      $modal.find('#step-2-submit' + idModifier).removeClass('disabled')
    }, 1500)
  }
}

const showHideLoader = ($modal, show) => {
  if (show) {
    $modal.find('.loader-overlay').removeClass('hidden')
    setLoaderInterval($modal)
  } else {
    $modal.find('.loader-overlay').addClass('hidden')
  }
}

const setLoaderInterval = ($modal) => {
  let loaderCount = 30
  let loaderInterval = window.setInterval(function () {
    if (!$modal.find('.loader-overlay').hasClass('hidden')) {
      if (loaderCount-- <= 0) {
        window.clearInterval(loaderInterval)
        showHideLoader($modal, false)
      }
    } else {
      window.clearInterval(loaderInterval)
    }
  }, 1000)
}

const submitData = ($modal, id) => {
  const idModifier = getIDModifier($modal)

  showHideLoader($modal, true)
  $modal.find('#step-1-submit' + idModifier).addClass('disabled')
  $modal.find('#step-2-submit' + idModifier).addClass('disabled')

  let selectedCourses = $modal.find('#class-select' + idModifier).attr('data-value').split(',')
  let courses = []
  courses = GOOGLECLASSROOMINFO.courses.filter(function (course) {
    return selectedCourses.indexOf(course.id) !== -1
  }).map(function (course) {
    return {
      courseId: course.courseId,
      courseName: course.courseName,
      teacherFolderId: course.teacherFolderId
    }
  })

  let points = $modal.find('#points-select' + idModifier).val()
  points = points === 'Ungraded' ? 0 : points === '' ? 100 : points
  let topic = {}
  if ($modal.find('#topic-select' + idModifier).val() !== '' && $modal.find('#topic-select' + idModifier).val() !== null && $modal.find('#topic-select' + idModifier).val() !== 'No Topic') {
    topic = {
      topicName: $modal.find('#topic-select' + idModifier).val()
    }
  }

  let alternateKey = $modal.find('#assignment-type' + idModifier).val() !== undefined && $modal.find('#assignment-type' + idModifier).val() !== 'undefined' ? $modal.find('#assignment-type' + idModifier).val() : ''
  if (alternateKey === 'Student Fill-in') {
    alternateKey = 'studentFillIn'
  }

  let data = {
    assetId: id,
    alternateKey: alternateKey,
    assignmentTitle: $modal.find('#assignment-title' + idModifier).val(),
    assignmentDescription: $modal.find('#instructions' + idModifier).val(),
    courses: courses,
    points: Number(points),
    studentIds:
      $modal.find('#student-select' + idModifier).attr('data-value') !== ''
        ? $modal
          .find('#student-select' + idModifier)
          .attr('data-value')
          .split(',')
        : []
  }

  if (Object.keys(topic).length > 0) {
    data.topic = topic
  }

  var dueDateOffset = 0
  let dueTimePickr = $modal.find('#due-time' + idModifier)[0]._flatpickr
  let dueDatePickr = $modal.find('#due-date' + idModifier)[0]._flatpickr
  if (dueDatePickr.selectedDates[0] !== undefined && $modal.find('#due-time' + idModifier).val() !== '') {
    if (dueTimePickr.selectedDates[0] !== undefined && $modal.find('#due-time' + idModifier).val() !== '') {
      let hours = (Number(dueTimePickr.selectedDates[0].getHours()) + Number(dueTimePickr.selectedDates[0].getTimezoneOffset()) / 60)
      let mins = dueTimePickr.selectedDates[0].getMinutes()
      if ((hours > 23 && mins > 0) || (hours > 24)) {
        dueDateOffset += 1
        hours -= 24
      } else if (hours < 0) {
        dueDateOffset -= 1
        hours += 24
      }
      hours = hours.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })
      mins = mins.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })
      data['dueTime'] = hours + ':' + mins
    }

    let dateStr =
      dueDatePickr.selectedDates[0].getTime() -
      dueDatePickr.selectedDates[0].getTimezoneOffset() * 60000
    let newDate = new Date(dateStr)
    newDate.setDate(newDate.getDate() + dueDateOffset)
    data['dueDate'] = newDate.toISOString().split('T')[0]
  }

  if ($modal.find('input[name="assign-to"]:checked').val() === 'schedule-assignment') {
    let assignmentDatePickr = $modal.find('#assignment-date' + idModifier)[0]._flatpickr
    let assignmentTimePickr = $modal.find('#assignment-time' + idModifier)[0]._flatpickr
    let dateStr = assignmentDatePickr.selectedDates[0].getTime() - (assignmentDatePickr.selectedDates[0].getTimezoneOffset() * 60000)
    let timeStr = assignmentTimePickr.selectedDates[0].getTime()

    let hours = (Number(assignmentTimePickr.selectedDates[0].getHours()) + Number(assignmentTimePickr.selectedDates[0].getTimezoneOffset()) / 60)
    let mins = assignmentTimePickr.selectedDates[0].getMinutes()
    var assignmentDateOffset = 0
    if ((hours > 23 && mins > 0) || (hours >= 24)) {
      assignmentDateOffset += 1
    } else if (hours < 0) {
      assignmentDateOffset -= 1
    }

    let newDate = new Date(dateStr)
    newDate.setDate(newDate.getDate() + assignmentDateOffset)
    dateStr = newDate.getTime()

    var scheduledDateTime = new Date(dateStr).toISOString().split('T')[0] + 'T' + new Date(timeStr).toISOString().split('T')[1]
    data['scheduledTime'] = scheduledDateTime
  }

  logGoogleAnalytics(id, 'Google Classroom', 'assign_to_classroom')
  postClassroomAsset(data)
}

const resetModal = id => {
  resetSteps(id)
  resetFields(id)
}

const resetSteps = function (id) {
  let $modal = $('[data-cauliflower-modal="' + id + '"]')
  const idModifier = getIDModifier($modal)

  if ($modal.find('.asset-for-modal__step').length > 0) {
    $modal.removeClass('step-1').removeClass('step-2')
    let assetTitle = $modal
      .find('.asset-for-modal__step.step-0 .asset-for-modal__title')
      .eq(0)
      .text()
    $modal.find('.modal__header__title').html(assetTitle.trim())

    $modal.find('#step-2-submit' + idModifier).removeClass('disabled')
  }
}

const resetFields = function (id) {
  let $modal = $('[data-cauliflower-modal="' + id + '"]')
  const idModifier = getIDModifier($modal)
  let $flatpickrs = $modal.find('.flatpickr-input')
  if ($flatpickrs.length > 0) {
    Array.from($flatpickrs).forEach(fp => $(fp).val('').text(''))
  }

  let dueDate = $modal.find('#due-date' + idModifier)[0]._flatpickr
  if (dueDate !== undefined) dueDate.selectedDates = []

  let dueTime = $modal.find('#due-time' + idModifier)[0]._flatpickr
  if (dueTime !== undefined) dueTime.selectedDates = []

  let assignmentDate = $modal.find('#assignment-date' + idModifier)[0]._flatpickr
  if (assignmentDate !== undefined) assignmentDate.selectedDates = []

  let assignmentTime = $modal.find('#assignment-time' + idModifier)[0]._flatpickr
  if (assignmentTime !== undefined) assignmentTime.selectedDates = []

  $modal.find('#assignment-title' + idModifier)
    .val($modal.find('#assignment-title' + idModifier).attr('data-title'))
    .text($modal.find('#assignment-title' + idModifier).attr('data-title'))

  $modal.find('#instructions' + idModifier).val('').text('')

  $modal.find('#due-time-container' + idModifier).hide()

  let $assignRadio = $modal.find('[value="assign-immediately"]')
  if ($assignRadio.length > 0) {
    $assignRadio.prop('checked', true)
  }

  $modal.find('.ca-select').removeClass('editable')
  $modal.find('.ca-select').removeClass('selected')
  let $caSelects = $modal.find('.ca-select input[type="text"]')
  if ($caSelects.length > 0) {
    $caSelects
      .val('')
      .text('')
      .attr('data-value', '')
      .attr('data-display-name', '')
  }

  $modal.find('#topic-select' + idModifier).text('No Topic').val('No Topic').attr('data-value', '')
  $modal.find('#points-select' + idModifier).text('100').val('100').attr('data-value', '100')

  let $caSelectValues = $modal.find('.ca-select .ca-select-options input')
  if ($caSelectValues.length > 0) {
    $caSelectValues.prop('checked', false)
  }

  $modal.find('#step-1-submit' + idModifier).addClass('disabled')
  $modal.find('#assign-immediately' + idModifier).prop('checked', true)
  $modal.find('#assignment-date-container' + idModifier).addClass('disabled')
  $modal.find('#assignment-time-container' + idModifier).hide().addClass('disabled')
  $modal.find('#step-2-submit' + idModifier).removeClass('disabled')
}

const validateClassEnrollment = function (id) {
  let classroomInfo = GOOGLECLASSROOMINFO
  if (!Array.isArray(classroomInfo.courses) || !classroomInfo.courses.length) {
    const toastData = {
      type: 'err',
      title: 'You do not have any Classes in Google Classroom.',
      message: 'To Assign to Google Classroom, please create a Class first.',
      showDismiss: true
    }
    CauliflowerModal.close()
    resetModal(id)
    openToast(toastData)
  }
}
export default {
  resetModal,
  initClassroom,
  apiEndpoints,
  callApi,
  showHideLoader,
  stepOneValidation,
  validateClassEnrollment
}
