// @flow
import $ from 'jquery'
import { attachActionHandler } from './actions/actions'
import CauliflowerModal from '../../../CauliflowerModal'
import { getHeader, getCurrentAsset, createStateUrl, findSessionsFromAssets, sortAssetsBySession, resourceNotAvailableAlert, clearAssetParam } from '../../toolbox'
import { getAttributeFromWindow } from '../../get/getAttribute'
import {
  countAssetsByType,
  filterAndSortAssetsByType
} from '../../get/getAsset'
import { convertAssetToAssetThumbnail } from '../../convert/convertAsset'
import { getAllLessonRows, getAllAssessmentRows } from '../../get/getRow'
import { getAssetCells, getGenericContainer, getAssessmentCells } from '../../get/getCell'
import {
  getElement,
  getElements,
  getElementFromNode
} from '../../get/getElement'
import { getSection } from '../../get/getSection'
import { isRCMLink } from '../../common'
import assetIcon from '../../../../img/icon-page.svg'
import interactiveIcon from '../../../../img/icon-interactive.svg'
import { Section } from './types/Section.flow'

export type ToolboxInitializationOptions = {
  enableSessions: boolean
}

let openAsset = {
  asset: {},
  lesson: ''
}
let assetFound = false
const init = (options: ToolboxInitializationOptions) => {
  openAsset = getCurrentAsset()
  CauliflowerModal.init()
  addAssetCellIconStyles()
  attachActionHandler(options)
  checkIsRCM()

  getHeader(
    getAttributeFromWindow('PAGE_PATH'),
    getAttributeFromWindow('IS_SPANISH'),
    createStateUrl(getAttributeFromWindow('PREVIEW_STATE')),
    isRCMLink()
  )

  let lessonRows = getAllLessonRows()

  if (lessonRows.length === 0) {
    lessonRows = getAllAssessmentRows()
  }

  if (lessonRows.length > 0) {
    lessonRows.forEach(__initTableOfContentsRow)
  }

  if (openAsset !== null && Object.keys(openAsset.asset).length > 0 && assetFound === false && lessonRows.length > 0) {
    let asset = window.ASSETS[openAsset.asset.id]
    let resourceFound = false

    if (asset) {
      let section = asset.associatedIDs.split(',')[0]
      let $cell = $(`[data-row-id="${section}"][data-asset-type="${asset.assetType}"]`)
      if ($cell.length > 0) {
        resourceFound = true
        $cell.trigger('click')
      }
    }

    if (!resourceFound) {
      resourceNotAvailableAlert('Resource Not Available', 'There was a problem accessing this resource. The resource may have moved or may no longer be available.')
      clearAssetParam()
    }
  }
}

const __initTableOfContentsRow = row => {
  const sectionId = row.dataset.id
  const section = getSection(sectionId)
  const { assets } = section
  const assetCountsByType = countAssetsByType(assets, sectionId)
  const sectionMatch = openAsset.lesson === '' || (openAsset.lesson !== '' && sectionId === openAsset.lesson)

  let cells = getAssetCells(row)
  if (cells.length === 0) {
    cells = getAssessmentCells(row)
  }

  if (cells.length > 0) {
    cells.forEach(cell => {
      const types = cell.dataset.assetType.split(' ')
      types.forEach(type => {
        const count = assetCountsByType[type]
        if (count > 0) {
          setCellAsAssetCell(cell, type)

          if (openAsset !== null && sectionMatch && Object.keys(openAsset.asset).length > 0 && assetFound === false) {
            let matchingAsset = section.assets.find(a => a.id === openAsset.asset.id)
            if (matchingAsset !== undefined) {
              let cellAssetTypes = $(cell).attr('data-asset-type').split(' ')
              if (cellAssetTypes.indexOf(matchingAsset.assetType) > -1) {
                $([document.documentElement, document.body]).animate({
                  scrollTop: $(cell).offset().top
                }, 500)
                $(cell).trigger('click')
                assetFound = true
              }
            }
          }
        }
      })
    })
  }
}

const initDetail = () => {
  init()
  const id = getElement('[data-lesson-detail]').dataset.id
  const typeRows = getElements('[data-asset-type-row]')
  const section = getSection(id)

  typeRows.forEach(row => {
    const type = row.dataset.assetType
    const container = getGenericContainer(row)
    let assets = filterAndSortAssetsByType(type, section.assets)

    let sessions = findSessionsFromAssets(assets)
    assets.forEach(a => {
      if (a.drawerLabel === null) a.drawerLabel = ''
    })
    if (assets.filter(a => a.drawerLabel === '').length > 0) sessions.unshift('')

    var sessionsWithAssets = sortAssetsBySession(sessions, assets)
    let sortedAssets = []
    sessionsWithAssets.forEach(swa => {
      sortedAssets = sortedAssets.concat(swa.assets)
    })

    if (sortedAssets.length > 0) assets = sortedAssets

    let rowHTMLContent: String = ''

    if (assets.length > 0) {
      row.classList.add('lesson__detail__asset-section--is-active')
      rowHTMLContent += assets.map(convertAssetToAssetThumbnail).join(' ')
    }

    if (rowHTMLContent !== '') {
      container.innerHTML = rowHTMLContent

      // set asset meta-data width to width of asset thumbnail
      assets.map(asset => {
        const el = document.querySelector(`img[data-id="${asset.id}"]`)
        const parent = el.closest('.asset__container')
        const metaData = parent.querySelector('.meta-data')

        el.addEventListener('load', function () {
          const elRect = el.getBoundingClientRect()
          if (metaData) {
            metaData.style.width = `${elRect.width}px`
          }
        })
        if (Object.keys(openAsset.asset).length > 0 && asset.id === openAsset.asset.id) {
          $(el).trigger('click')
          clearAssetParam()
        }
      })
    } else {
      container.parentElement.style.display = 'none'
    }
  })
}

const addAssetCellIconStyles = context => {
  const style = document.createElement('style')
  document.head.appendChild(style)

  const sheet = style.sheet
  sheet.insertRule(`.asset-icon {background-image: url(${assetIcon})}`)
  sheet.insertRule(
    `.asset-icon.asset-icon--is-iwl {background-image: url(${interactiveIcon})}`
  )
}

export const isLessonDetail = () => !!getAttributeFromWindow('LESSON_ID')

export const findActiveSectionName = () => {
  let name: String

  if (isLessonDetail()) {
    // Lesson ID will only be set on Lesson Detail Pages.
    name = __findSectionNameForLessonDetailPage()
  } else {
    name = __findActiveSectionNameForTableOfContentsPage()
  }

  return name
}

const __findSectionNameForLessonDetailPage = () => {
  // Lesson Detail Pages don't have drawers to grab
  // the lesson ID from, but they do have lesson headers.
  const header = getElement('.lesson-detail__header')
  return header.innerText
}

const __findActiveSectionNameForTableOfContentsPage = () => {
  const drawer = getElement('.drawer--is-active')
  const id = drawer.dataset.drawerId
  const row = findRowForSectionId(id)
  if (row) {
    const titleCell = getElementFromNode(row, '.section__title')
    return titleCell.innerText.trim()
  }
  const assessmentRow = getElement(`[data-assessment-row][data-id="${id}"]`)
  if (assessmentRow) {
    const titleCell = getElementFromNode(assessmentRow, '.section__title')
    return titleCell.innerText.trim()
  }
}

export const findActiveSection = (): Section => {
  let name: Section

  if (getAttributeFromWindow('LESSON_ID')) {
    // Lesson ID will only be set on Lesson Detail Pages.
    name = __findSectionForLessonDetailPage()
  } else {
    name = __findActiveSectionForTableOfContentsPage()
  }

  return name
}

const __findSectionForLessonDetailPage = () => {
  // Lesson Detail Pages don't have drawers to grab
  // the lesson ID from, but they do have lesson headers.
  const elementWithDataId = getElement('[data-lesson-detail][data-id]')
  const sectionId = elementWithDataId.dataset.id
  return getSection(sectionId)
}

const __findActiveSectionForTableOfContentsPage = () => {
  const drawer = getElement('.drawer--is-active')
  const id = drawer.dataset.drawerId
  return getSection(id)
}

const findRowForSectionId = id => {
  return getElement(`[data-lesson-row][data-id="${id}"]`)
}

const setCellAsAssetCell = (cell, type) => {
  cell.setAttribute('role', 'button')
  cell.setAttribute('tabindex', '0')
  cell.classList.add(`asset-icon`)
  cell.classList.add(`asset-icon--is-${type.replace(' ', '-')}`)
  cell.dataset.action = 'toggle-asset-drawer'
}

const checkIsRCM = () => {
  if (isRCMLink()) {
    document.querySelector('body').classList += 'isRCM'
  }
}

export default {
  init,
  initDetail
}
