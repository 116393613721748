export const SELECTORS = {
  profileModal: '[data-cauliflower-modal="profile"]',
  profileModalMainForm: '[data-profile-modal-main-form]',
  profileModalSearchForm: '[data-profile-modal-search-form]',
  profileSectionsAll: '[data-profile-modal-section]',
  profileSectionMain: '[data-profile-modal-section="main"]',
  profileSectionSearch: '[data-profile-modal-section="search"]',
  profileSectionResults: '[data-profile-modal-section="results"]',
  profileSectionActive: '.profile-section--is-hidden',
  profileModalButtons: '[data-profile-modal-button]',
  profileModalFinishButton: '[data-action="submit-profile-modal-form"]',
  schoolReselectSection: '[data-profile-modal-section="school-reselect"]',
  schoolReselectHideButton: '[data-action="hide-school-search"]',
  schoolReselectResults: '[data-school-search-results]',
  buttonSectionsAll: '[data-profile-modal-button-section]',
  buttonSectionMain: '[data-profile-modal-button-section="main"]',
  buttonSectionSearch: '[data-profile-modal-button-section="search"]',
  buttonSectionResults: '[data-profile-modal-button-section="results"]',
  schoolReselectSearchButton: '[data-action="search"]',
  schoolReselectSelectButton: '[data-action="select"]',
  schoolReselectStateSelect: '[data-school-search-state]',
  schoolReselectSearchTypeRadios: '[data-school-search-type]',
  schoolReselectSchoolQueryInput: '[data-school-search-query]',
  schoolResult: '[data-school-result]',
  tooltips: '[data-cauliflower-modal="profile"] .tooltip'
}

export const SCHOOL_SEARCH_FIELD_NAMES = ['state', 'schoolMode', 'schoolInput']
