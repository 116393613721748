import $ from 'jquery'
import {
  getElement,
  getElements
  // getElementFromNode
} from './common/get/getElement'

const getSelector = (key, shouldAddDot = true) => {
  const SELECTORS = {
    DRAWER: 'drawer-with-sessions',
    SIDEBAR: 'drawer-with-sessions__sidebar',
    SESSION: 'session',
    CONTENT: 'drawer-with-sessions__content',
    LINK: 'drawer-with-sessions__sidebar__title',
    ACTIVE_LINK: 'drawer-with-sessions__sidebar__title--is-active'
  }

  const withoutDot = SELECTORS[key]
  const withDot = `.${withoutDot}`

  return shouldAddDot ? withDot : withoutDot
}

// TODO: Render Session Drawer

const highlightActiveBoundary = boundary => {
  const activeClass = getSelector('ACTIVE_LINK', false)
  boundary.link.classList.add(activeClass)
}

const deactivateActiveBoundaries = () => {
  const selector = getSelector('ACTIVE_LINK')
  const activeClass = getSelector('ACTIVE_LINK', false)
  getElements(selector).map(el => {
    el.classList.remove(activeClass)
  })
}

const findBoundaryFromPosition = (position, boundaries) => {
  return boundaries.find(boundary => position < boundary.end)
}

const findBoundaryFromId = (id, boundaries) => {
  return boundaries.find(boundary => id === '#' + boundary.id)
}

const getLinkBySessionId = id => {
  const selector = getSelector('LINK') + `[href="#${id}"]`
  return getElement(selector)
}

export const getCurrentScrollPosition = (container: HTMLDivElement) =>
  container.scrollTop

const findBoundariesForSessionElement = (session: HTMLDivElement) => {
  const id = session.id
  const bounds = session.getBoundingClientRect()
  const top = session.offsetTop
  const bottom = top + bounds.height
  const padding = 50

  const start = top - padding
  const end = bottom - padding

  const link = getLinkBySessionId(id)

  return {
    id,
    start,
    top,
    session,
    link,
    end
  }
}

export const createSessionBoundaryFinder = () => {
  let boundaries = []
  let sessions = getElements(getSelector('SESSION'))
  const isZero = a => a.length === 0

  const noSessions = isZero(sessions)
  const noBoundaries = isZero(boundaries)

  return (shouldReevaluate = false) => {
    if (noSessions || noBoundaries || shouldReevaluate) {
      boundaries = sessions.map(findBoundariesForSessionElement)
    }
    return boundaries
  }
}

const getLastBoundaryHeight = boundaries => {
  const last = boundaries[boundaries.length - 1]
  return last.end - last.start
}

const getContainerHeight = content => content.offsetHeight || 400

const getPosition = (content, boundaries) => {
  let position = getCurrentScrollPosition(content)
  const lastBoundaryHeight = getLastBoundaryHeight(boundaries)
  const containerHeight = getContainerHeight(content)

  // if the last boundary is less than the height of the parent container,
  // add the difference to the position to ensure the last session can always be active in view
  if (lastBoundaryHeight < containerHeight) {
    // cap the extra spacing at 100
    const diff =
      containerHeight - lastBoundaryHeight < 100
        ? containerHeight - lastBoundaryHeight
        : 100
    return (position += diff)
  } else {
    return position
  }
}

const __addListeners = (
  content: HTMLDivElement,
  sidebar: HTMLDivElement,
  getBoundaries,
  scrollToBoundary
) => {
  content.addEventListener(
    'scroll',
    __makeScrollListener(content, getBoundaries)
  )
  sidebar.addEventListener(
    'click',
    __makeLinkClickListener(content, getBoundaries, scrollToBoundary)
  )
}

/**
 * Adds some event listeners for a drawer with sessions.
 * @param {*}
 */
export function initializeDrawerWithSessions () {
  const content = getElement(getSelector('CONTENT'))
  const sidebar = getElement(getSelector('SIDEBAR'))
  const getBoundaries = createSessionBoundaryFinder()
  const scrollToBoundary = createBoundaryScroller(content)
  const boundaries = getBoundaries(true)
  highlightActiveBoundary(boundaries[0])
  __addListeners(content, sidebar, getBoundaries, scrollToBoundary)
}

const createBoundaryScroller = content => boundary => {
  $(content).animate(
    {
      scrollTop: boundary.top,
      complete: () => highlightActiveBoundary(boundary)
    },
    500
  )
}

// Listeners

const __makeLinkClickListener = (
  content,
  getBoundaries,
  scrollToBoundary
) => e => {
  const target = e.target

  const isLink = element => element.matches(getSelector('LINK'))
  if (isLink(target)) {
    e.preventDefault()
    const boundary = findBoundaryFromId(target.hash, getBoundaries(true))
    scrollToBoundary(boundary)
  }
}

const __makeScrollListener = (content, getBoundaries) => e => {
  const boundaries = getBoundaries()
  const position = getPosition(content, boundaries)
  const boundary = findBoundaryFromPosition(position, boundaries)

  deactivateActiveBoundaries()
  highlightActiveBoundary(boundary)
}
