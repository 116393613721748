import { getCurrentSchoolInformationFromUser } from './ProfileModalGetters'

export const SchoolStatus = (() => {
  let originalSchool = getCurrentSchoolInformationFromUser()
  let currentSchool = originalSchool
  let isDirty = false

  const updateSchool = newSchool => {
    if (typeof newSchool === 'undefined') return
    if (typeof newSchool.schoolId === 'undefined') return

    if (newSchool.schoolId !== originalSchool.schoolId) {
      isDirty = true
    }

    return { ...newSchool, isDirty }
  }

  const getOriginalSchool = () => {
    return currentSchool
  }

  const getCurrentSchool = () => {
    return currentSchool
  }

  const getSchoolStatus = () => {
    return { isDirty }
  }

  const clearSchool = () => {
    return updateSchool(originalSchool)
  }

  return {
    updateSchool,
    clearSchool,
    getOriginalSchool,
    getCurrentSchool,
    getSchoolStatus
  }
})()
