import $ from 'jquery'

const logGoogleAnalytics = (id, methodOfAccess, eventType, subType) => {
  const modalSelector = '[data-cauliflower-modal="' + id + '"]'
  const $modal = $(modalSelector)

  const assetName = $modal.find('.asset-for-modal__details .asset-for-modal__title').length > 0
    ? $modal.find('.asset-for-modal__details .asset-for-modal__title')[0].innerText.trim()
    : ''
  let assetVersion = subType === undefined
    ? ($modal.find('.asset-for-modal__thumbnail-subtitle').length > 0 ? $modal.find('.asset-for-modal__thumbnail-subtitle')[0].outerText : '')
    : subType

  if (assetVersion === 'studentFillIn') assetVersion = 'Student Fillin'
  else if (assetVersion === 'editable') assetVersion = 'Editable'

  const thumbnailSelector = $modal.find('.asset-for-modal__thumbnail')
  const assetType = thumbnailSelector.attr('data-type')

  const bookCode = $('#state-select').closest('.js-select').find('[selected]').attr('data-state')

  const contentPageRegex = /(.*\/toolbox\/)([M,R,W,S])\.([K,1-8])(\/.*)/
  const currentPath = window.location.pathname
  const matches = currentPath.match(contentPageRegex)

  const grade = matches[3]
  let subject = matches[2]

  let subjectSelect = $('#header-subject-select')
  if (subjectSelect.find('input').length > 0) {
    let sub = subjectSelect.find('input').val()
    if (sub === 'Science') subject = sub.charAt(0)
  } else if (subjectSelect.find('.header__single-item').length > 0) {
    let sub = subjectSelect.find('.header__single-item span').text()
    if (sub === 'Science') subject = sub.charAt(0)
  }

  const gtag = window.gtag || function () {}
  gtag('event', eventType, {
    'access_method': methodOfAccess,
    'asset_name': assetName,
    'asset_version': assetVersion,
    'asset_type': assetType,
    'book_name': bookCode,
    'asset_grade': grade,
    subject
  })
}

export default logGoogleAnalytics
