import $ from 'jquery'

var $body = null

// Element Templates
var $wrapper = $('<div class="cauliflower" id="notification-wrapper"></div>')
var $banner = $('<div class="notification-banner"></div>')
var $alert = $('<div class="alert"></div>')
var $alertContent = $('<div class="alert-content"></div>')
var $close = $('<button class="button link notification-close"></button>')
var timeout

var init = function () {
  $body = $('body')
  __findOrCreateWrapper()

  $body.on('click', '.notification-banner .notification-close', close)
  $body.on('mouseenter', '.notification-banner', __pauseTimeout)
}

/**
 * Opens a new banner and displays it.
 *
 *  @param {string} text - Text to display in banner
 *  @param {'success' | 'error' | 'info' | 'warning' | 'danger'} type - Determines color of the banner
 * */
var open = function (text, type, timeoutInterval) {
  var $wrapper = __findOrCreateWrapper()
  var $banner = __createBanner(text, type)

  timeout = setTimeout(function () {
    close()
  }, timeoutInterval || 5000)

  $wrapper.append($banner)
  $wrapper.show('fast')
}

var __createAlert = function (html, type) {
  var $newAlert = $alert.clone()
  var $newAlertContent = $alertContent.clone()
  var newClass = type ? 'alert-' + type : 'alert-success'

  // We have to use .html() since we have an HTML-formatted response
  // when you try to log into an account that isn't activated
  $newAlertContent.html(html)
  $newAlert.append($newAlertContent)
  $newAlert.addClass(newClass)

  return $newAlert
}

var __createBanner = function (text, type) {
  var $newBanner = $banner.clone()
  var $alert = __createAlert(text, type)
  var $close = __createClose()

  $newBanner.append($alert)
  $newBanner.append($close)

  return $newBanner
}

var __createClose = function () {
  return $close.clone()
}

var __findOrCreateWrapper = function () {
  var $existingWrapper = $('#notification-wrapper.cauliflower')

  if ($existingWrapper.length > 0) {
    $wrapper = $existingWrapper
  } else {
    $('body').append($wrapper)
  }

  return $wrapper
}

var __pauseTimeout = function () {
  clearTimeout(timeout)
}

/**
 * Transitions out the Notification and removes it from the DOM.
 * */
var close = function () {
  $wrapper.hide('fast', function () {
    $wrapper.remove()
  })
}

export default {
  init: init,
  open: open,
  close: close
}
