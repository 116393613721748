import { getElementsFromNode, getElementFromNode } from './getElement'

export const getAssetCells = (node = document) => {
  return getElementsFromNode(node, '[data-asset-cell]')
}

export const getGenericContainer = (node = document) => {
  return getElementFromNode(node, '[data-generic-container]')
}

export const getAssessmentCells = (node = document) => {
  return getElementsFromNode(node, '[data-assessment-cell')
}
