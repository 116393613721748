import CauliflowerModal from './CauliflowerModal'
import $ from 'jquery'

var init = function () {
  var $buttons = $('.button.video[data-video-type="vidyard"]')
  $buttons.on('click', onClick)
}
export var onClick = function (e) {
  var videoId = e.target.dataset.videoId
  var title = e.target.dataset.modalTitle

  var modal = CauliflowerModal.find(videoId)

  if (modal.length === 0) {
    modal = CauliflowerModal.create(
      {
        title: title,
        onClose: function () {
          pause(videoId)
        },
        body:
          '<script type="text/javascript" id="vidyard_embed_code_' +
          videoId +
          '" src="//play.vidyard.com/' +
          videoId +
          '.js?v=3.1.1&type=inline&autoplay=1"></script>'
      },
      videoId
    )
  }

  CauliflowerModal.open(videoId)
}

var getVideoByVideoId = function (videoId) {
  // eslint-disable-next-line new-cap
  return new window.Vidyard.player(videoId)
}

var pause = function (videoId) {
  var player = getVideoByVideoId(videoId)
  player.pause()
}

export default {
  init: init
}
