import { getElements } from './get/getElement'
import { getAttributeFromWindow } from './get/getAttribute'

const __getBaseUrl = () => {
  const { origin } = getAttributeFromWindow('location')
  const context = getAttributeFromWindow('CONTEXT')
  return origin + context + '/'
}

export const getPagePath = () => getAttributeFromWindow('PAGE_PATH')
export const getLandingPageUrl = () => __getBaseUrl() + 'toolbox'
export const getSchoolResultElements = () => getElements('[data-school-result]')

export const getUser = () => getAttributeFromWindow('userJSON')

export const getUserInstitution = () => {
  const { schoolId, state } = getUser()
  return {
    stateOrProvince: state,
    schoolId,
    parentAccountIdName: '',
    name: 'N/A'
  }
}
