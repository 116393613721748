const assignToGoogleClassroom = ({ id, type, download }) => {
  let path = window.location.pathname + '?asset=' + id
  if (type !== '') path += '&assetType=' + type
  if (download) path += '&isDownload=true'

  path += '&isRedirect=true'

  const url = window.CONTEXT + '/api/v1/google/auth/url?path=' + encodeURIComponent(path)
  fetch(url)
    .then(r => {
      const contentType = r.headers.get('content-type')
      if (contentType && contentType.indexOf('application/json') !== -1) {
        r.json().then(response => {
          window.location = response
        })
      } else {
        window.location = window.CONTEXT + '/login.html' + '?path=' + encodeURIComponent(path)
      }
    }).catch(function (err) {
      console.log(err)
      window.location = window.CONTEXT + '/login.html' + '?path=' + encodeURIComponent(path)
    })
}

export default assignToGoogleClassroom
