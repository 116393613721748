// @flow
import $ from 'jquery'
import { Asset, AssetType } from '../types/Asset.flow'
import { convertAssetToAssetThumbnail } from '../../../convert/convertAsset'
import { getSection } from '../../../get/getSection'
import {
  getElements,
  getElement,
  getElementFromNode
} from '../../../get/getElement'
import { getAssetType, filterAndSortAssetsByType } from '../../../get/getAsset'
import {
  doAssetsHaveSessions,
  renderDrawerContentsWithSessions,
  getCurrentAsset,
  formatSessionTitle,
  clearAssetParam
} from '../../../toolbox'
import { initializeDrawerWithSessions } from '../../../../SessionDrawer'
import mustache from 'mustache'
import { ToolboxInitalizationOptions } from '../toolbox.classroom'
// TODO: Organize this file

const BASE_DRAWER_CLASS = 'drawer'
const ACTIVE_DRAWER_CLASS = `${BASE_DRAWER_CLASS}--is-active`
const BASE_ASSET_CLASS = 'asset-icon'
const ACTIVE_ASSET_CLASS = `${BASE_ASSET_CLASS}--is-active`

const asCssClass = (string: string) => '.' + string

const __closeOtherDrawers = (activeDrawer?: HTMLElement) => {
  const openDrawers = getElements(`${asCssClass(ACTIVE_DRAWER_CLASS)}`)

  openDrawers.forEach(drawer => {
    if (activeDrawer && activeDrawer.isSameNode(drawer)) {
      return false
    } else {
      __closeDrawer(drawer)
    }
  }) // Hopefully there's only one open drawer, but might as well be safe.
}

const __deactivateOtherAssetCells = activeCell => {
  const cells = Array.from(
    document.querySelectorAll(asCssClass(ACTIVE_ASSET_CLASS))
  )
  cells.forEach(cell => {
    if (activeCell && activeCell.isSameNode(cell)) {
      return false
    } else {
      __deactivateAssetCell(cell)
    }
  })
}

const __createDrawerContents = (assets: Asset[]): HTMLElement => {
  const assetsHTML = assets.map(convertAssetToAssetThumbnail).join('')
  return mustache.render(
    `<div class="drawer__container"><div class="drawer__spacer"></div><div class="drawer__content">${assetsHTML}</div></div>`
  )
}

export const createAssetTypeFilter = (assetType: AssetType) => {
  return (asset: Asset) => {
    let types = assetType
    if (!Array.isArray(types)) types = assetType.split(' ')

    return types.some(type => getAssetType(asset) === type)
  }
}

const __openDrawer = (drawer: HTMLElement) => {
  drawer.classList.add(ACTIVE_DRAWER_CLASS)
}

const __closeDrawer = (drawer: HTMLElement) => {
  drawer.classList.remove(ACTIVE_DRAWER_CLASS)
  const container = getElementFromNode(drawer, '[data-drawer-container] > *')
  container.remove() // Remove everything inside the drawer when it is closed to prevent collisions that caused this issue -> https://jira.cainc.com/browse/TBX-2013
}

const __activateAssetCell = cell => {
  return cell.classList.add(ACTIVE_ASSET_CLASS)
}

const __deactivateAssetCell = cell => {
  return cell.classList.remove(ACTIVE_ASSET_CLASS)
}

const __isCellActive = (cell: HTMLElement) => {
  return cell.classList.contains(ACTIVE_ASSET_CLASS)
}

const __getElements = ({ rowId, assetType, event }) => {
  const cell = event.target

  const drawer = getElement(`.drawer[data-drawer-id="${rowId}"]`)
  const container = getElementFromNode(drawer, `[data-drawer-container]`)

  return { cell, drawer, container }
}

const __closeAll = () => {
  __closeOtherDrawers()
  __deactivateOtherAssetCells()
}

const __openNewDrawer = (
  { cell, drawer, container, assets, section },
  { enableSessions = false }
) => {
  __deactivateOtherAssetCells(cell)
  __closeOtherDrawers(drawer)

  if (doAssetsHaveSessions(assets) && enableSessions) {
    const drawer = renderDrawerContentsWithSessions({
      assetData: assets
    })

    container.innerHTML = drawer

    initializeDrawerWithSessions(container)
  } else {
    container.innerHTML = __createDrawerContents(assets)
  }

  __openDrawer(drawer)
  __activateAssetCell(cell)

  const urlParams = new URLSearchParams(window.location.search)
  const openAsset = getCurrentAsset()

  // set asset meta-data width to width of asset thumbnail
  assets.map(asset => {
    const el = document.querySelector(`img[data-id="${asset.id}"]`)
    if (el !== null) {
      const parent = el.closest('.asset__container')
      const metaData = parent.querySelector('.meta-data')

      el.addEventListener('load', function () {
        const elRect = el.getBoundingClientRect()
        if (metaData) {
          metaData.style.width = `${elRect.width}px`
        }

        if (Object.keys(openAsset.asset).length > 0) {
          if (openAsset.asset.id === asset.id) {
            if (openAsset.asset.drawerLabel !== '' &&
              openAsset.asset.drawerLabel &&
              openAsset.lesson !== '') {
              const assetSession = '#' + formatSessionTitle(openAsset.asset.drawerLabel)
              const $sidebarTitle = $(el.closest('.drawer')).find(`.drawer-with-sessions__sidebar__title[href="${assetSession}"]`)
              if ($sidebarTitle.length > 0) {
                $sidebarTitle.first()[0].click()
              }
            }
            el.click()
            clearAssetParam()
          }
        }
      })
    }
  })
}

const handleOpenAssetDrawer = (
  { rowId, assetType, event },
  options: ToolboxInitalizationOptions
) => {
  const { cell, drawer, container } = __getElements({ rowId, assetType, event })
  if (__isCellActive(cell)) {
    __closeAll()
    return null
  } else {
    const section = getSection(rowId)
    let assets = filterAndSortAssetsByType(assetType, section.assets)
    assets.forEach(a => {
      if (a.alternates.length > 0 && a.alternates.filter(al => al.key !== 'google').length > 0) a.showIcons = true
      else a.showIcons = false
    })
    return __openNewDrawer(
      {
        cell,
        drawer,
        container,
        section,
        assets
      },
      options
    )
  }
}

export default handleOpenAssetDrawer
