// @flow
import {
  getField,
  getFieldValue,
  getDependantFields,
  getFieldName,
  getSchoolSearchFields,
  getInstitutionModalFields
} from './RegistrationGetters'
import { groupFieldsByValidationStatus } from './RegistrationGroupers'
import { getElement } from '../common/get/getElement'
import { getValidatorForField } from './RegistrationMappers'
import { Field, FieldName } from './RegistrationTypes'
import {
  VALID_FIELD_NAMES,
  SCHOOL_MODES,
  INSTITUTION_MODAL_TYPE
} from './RegistrationConstants'
import { getAttributeFromWindow } from '../common/get/getAttribute'

export const validateField = (fieldName: Field | FieldName): boolean => {
  const field = getField(fieldName)
  const validator = getValidatorForField(field)
  const value = getFieldValue(field)
  return validator(value)
}

export const shouldFieldBeDisabled = (field: Field): boolean => {
  const fields = getDependantFields(field)
  const validation = fields.map(validateField)
  const hasInvalidDependantFields = validation.some(value => value !== true)

  return hasInvalidDependantFields
}

const isValidFieldName = (possibleFieldName: string): boolean => {
  return VALID_FIELD_NAMES.includes(possibleFieldName)
}

export const isValidSchoolMode = (possibleSchoolMode: string): boolean => {
  return SCHOOL_MODES.includes(possibleSchoolMode)
}

export const isValidInstitutionType = (
  possibleInstitutionType: string
): boolean => {
  return INSTITUTION_MODAL_TYPE.includes(possibleInstitutionType)
}

export const isValidField = (possibleField: Field | FieldName): boolean => {
  return isValidFieldName(getFieldName(possibleField))
}

export const isStepTwo = (): boolean => {
  const title = getElement('[data-panel-title]')
  return title.innerHTML.indexOf('Step 2') > -1
}

export const isPopulatedStepTwo = () => {
  const step2ClassList = getElement('[data-step="2"]').classList
  return step2ClassList.contains('step-two--has-result')
}

export const isPopulatedInstitution = () => {
  const step2ClassList = getElement('[data-step="2"]').classList
  return step2ClassList.contains('step-two--is-institution')
}
// TODO: Define User Object
export const doesUserHaveActiveLicenses = (user?: Object = getAttributeFromWindow('userJSON')) => {
  const activeCodes = user.activeLicenseCodes || []
  const result = activeCodes.length > 0
  return result
}

export const isSchoolSearchValid = () => {
  const { invalid = [] } = groupFieldsByValidationStatus(
    getSchoolSearchFields()
  )
  return invalid.length === 0
}

export const isInformationModalValid = () => {
  const { invalid = [] } = groupFieldsByValidationStatus(
    getInstitutionModalFields()
  )
  return invalid.length === 0
}
