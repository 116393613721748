import type { CleanAsset } from '../types/Asset.flow'
import { isAssetVideo } from '../../../get/getAsset'
import {
  IWLAssetForModalTemplate,
  ToolAssetForModalTemplate,
  PdToolAssetForModalTemplate,
  VimeoAssetForModalTemplate,
  VidyardAssetForModalTemplate,
  GenericAssetForModalTemplate,
  GoogleClassroomGenericAssetForModalTemplate,
  DesmosToolAssetForModalTemplate,
  PdActivityAssetForModalTemplate
} from './templates'
import Mustache from 'mustache'
import { getAttributeFromWindow } from '../../../get/getAttribute'

import desmosFlyer from '../../../../../img/ready-classroom-math-digital-math-demos-tools-flyer-2020.pdf'

const renderToolAssetForModal = renderData => {
  return Mustache.render(ToolAssetForModalTemplate, renderData)
}

const renderDesmosToolAssetForModal = renderData => {
  return Mustache.render(DesmosToolAssetForModalTemplate, renderData)
}

const renderPdToolAssetForModal = renderData => {
  let pdData = {
    ...renderData,
    desmosFlyer: desmosFlyer
  }
  return Mustache.render(PdToolAssetForModalTemplate, pdData)
}
const renderPdActivityAssetForModal = renderData => {
  let pdData = {
    ...renderData,
    showTermsOfUse: false
  }
  return Mustache.render(PdActivityAssetForModalTemplate, pdData)
}

const renderIWLAssetForModal = renderData => {
  return Mustache.render(IWLAssetForModalTemplate, renderData)
}

const renderGenericAssetForModal = renderData => {
  const googleClassroomEnabled = getAttributeFromWindow('IS_GOOGLE_CLASSROOM_ENABLED')
  const assetMicroserviceEnabled = getAttributeFromWindow('IS_ASSET_MICROSERVICE_ENABLED')

  if (assetMicroserviceEnabled) renderData.link = renderData.linkMicroservice
  if (!googleClassroomEnabled) renderData.alternates = renderData.alternates.filter(a => a.key !== 'google')
  renderData.isPPT = (renderData.attachmentExtension === 'pptx' || renderData.attachmentExtension === 'ppt')
  renderData.showGoogleClassroom = renderData.attachmentExtension === 'pdf'

  for (let i = 0; i < renderData.alternates.length; i++) {
    if (renderData.alternates[i].key === 'google') renderData.alternates[i].isGoogle = true
    if (assetMicroserviceEnabled) renderData.alternates[i].link = renderData.alternates[i].linkMicroservice
  }

  if (googleClassroomEnabled && (renderData.audience === 'Student' || renderData.originalAudience === 'student') && renderData.showGoogleClassroom) {
    return Mustache.render(GoogleClassroomGenericAssetForModalTemplate, renderData)
  } else {
    return Mustache.render(GenericAssetForModalTemplate, renderData)
  }
}

const renderVideoAssetForModal = renderData => {
  switch (renderData.videoType) {
    case 'vimeo':
      return Mustache.render(VimeoAssetForModalTemplate, renderData)
    case 'vidyard':
      return Mustache.render(VidyardAssetForModalTemplate, renderData)
    default:
      console.error(
        `⁉️ Video is neither vidyard for vimeo: ${
          renderData.id
        }. Defaulting to Vimeo.`
      )
      return Mustache.render(VimeoAssetForModalTemplate, renderData)
  }
}

export const renderAssetForModal = (asset: CleanAsset, lessonName: string) => {
  let { assetType } = asset

  // Make fixed prereq a boolean for testing inside the template
  const isVideo = isAssetVideo(asset)

  if (assetType === 'iwl') {
    if (typeof window.userJSON !== 'undefined' && window.userJSON && window.userJSON.userType !== 'TEACHER_TOOLBOX') {
      let iReadyUrl = 'pd.i-ready'
      if (typeof window.IREADY_SERVER !== 'undefined' && window.IREADY_SERVER) iReadyUrl = window.IREADY_SERVER + '.i-ready'
      if (typeof asset.html5Link !== 'undefined' && asset.html5Link) asset.html5Link = asset.html5Link.replace('login.i-ready', iReadyUrl)
      if (typeof asset.link !== 'undefined' && asset.link) asset.link = asset.link.replace('login.i-ready', iReadyUrl)
      if (typeof asset.linkMicroservice !== 'undefined' && asset.linkMicroservice) asset.linkMicroservice = asset.linkMicroservice.replace('login.i-ready', iReadyUrl)
    }
    return renderIWLAssetForModal({ ...asset, lessonName })
  } else if (assetType === 'tool') {
    const ireadyServer = getAttributeFromWindow('IREADY_SERVER')
    const link = asset.link || ''
    const toolUrl = asset.toolUrl || ''

    if (ireadyServer === 'pd') {
      if (
        (link && link.includes('desmos')) ||
        (toolUrl && toolUrl.includes('desmos'))
      ) {
        return renderPdToolAssetForModal({ ...asset, lessonName, ireadyServer })
      }
    }
    return renderToolAssetForModal({ ...asset, lessonName, ireadyServer })
  } else if (isVideo) {
    return renderVideoAssetForModal({ ...asset, lessonName })
  } else if (assetType === 'desmosactivity') {
    const ireadyServer = getAttributeFromWindow('IREADY_SERVER')
    const link = asset.link || ''
    const toolUrl = asset.desmosToolUrl || ''

    if (ireadyServer === 'pd') {
      if (
        (link && link.includes('desmos')) ||
        (toolUrl && toolUrl.includes('desmos'))
      ) {
        return renderPdActivityAssetForModal({ ...asset, lessonName, ireadyServer })
      }
    }
    return renderDesmosToolAssetForModal({ ...asset, lessonName, ireadyServer })
  } else {
    const context = getAttributeFromWindow('CONTEXT')
    return renderGenericAssetForModal({ ...asset, lessonName, context })
  }
}
