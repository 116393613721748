import $ from 'jquery'

// when JS is loaded, set up our starting point
// if JS fails to load, the custom select remains a plain text input
// create and set start point for the state tracker
let csState = 'initial'

const init = selectors => {
  $(selectors).each(function () {
    const selector = $(this)
    let csSelector, csInput, csList, csOptions, csStatus, aOptions

    csSelector = selector
    if (!csSelector.hasClass('initialized')) {
      csInput = selector.find('input')
      csList = selector.find('ul')
      csOptions = csList.find('li')
      csStatus = selector.closest('.js-select-status')
      aOptions = Array.from(csOptions)

      // inform assistive tech (screen readers) of the names & roles of the elements in our group
      csSelector.attr('role', 'combobox')
      csSelector.attr('aria-haspopup', 'listbox')
      csSelector.attr('aria-owns', 'custom-select-list') // container owns the list...
      csInput.attr('aria-autocomplete', 'both')
      csInput.attr('aria-controls', 'custom-select-list') // ...but the input controls it
      csList.attr('role', 'listbox')
      csOptions.each(function () {
        $(this).attr('role', 'option')
        $(this).attr('tabindex', '-1') // make li elements keyboard focusable by script only
      })
      // set up a message to keep screen reader users informed of what the custom input is for/doing
      csStatus.textContent =
        csOptions.length +
        ' options available. Arrow down to browse or start typing to filter.'

      if (selector) {
        // EVENTS
        // /////////////////////////////////
        csSelector.off('click') // Remove previous bindings
        csSelector.on('click', function () {
          const currentSelector = $(this)
          const currentFocus = findFocus()
          const list = currentSelector.find('ul')
          const input = currentSelector.find('input[type="text"]')
          const options = list.find('li')
          const arrOptions = Array.from(options)
          const isOpen = list.hasClass('hidden-all')

          // if a dropdown is already open and you click a different one, close all
          if (csState === 'opened' && $(currentFocus).is('input') && isOpen) {
            $('.js-select, .js-multi-select').each(function () {
              toggleList($(this), $(this).find('ul'), 'Shut')
              setState('initial')
            })
          }

          switch (csState) {
            case 'initial':
              toggleList(currentSelector, list, 'Open')
              setState('opened')
              break
            case 'opened':
              if ($(currentFocus).is(input)) {
                toggleList(currentSelector, list, 'Shut')
                setState('initial')
              } else if ($(currentFocus).is('li')) {
                makeChoice(
                  currentSelector,
                  input,
                  options,
                  arrOptions,
                  currentFocus
                )
              }
              break
          }
        })

        csSelector.on('keydown', function (e) {
          e.preventDefault()
        })
        csSelector.on('click', '.ca-select-cancel', function (e) {
          const input = csSelector.find('input[type="text"]')
          csSelector.find('input[type="checkbox"]').prop('checked', false)
          makeChoice(csSelector, input)
        })

        $(document).on('click', function (e) {
          if (!e.target.closest('.js-multi-select')) {
            toggleList(csSelector, csList, 'Shut')
            setState('initial')
          }
        })

        let $parentModal = csSelector.closest('.modal__main')
        if ($parentModal) {
          $parentModal.on('click', function (e) {
            if (!e.target.closest('.js-multi-select')) {
              toggleList(csSelector, csList, 'Shut')
              setState('initial')
            }
          })
        }
      }

      csSelector.addClass('initialized')
    }
  })
}

// FUNCTIONS
// /////////////////////////////////
function setPlaceholder (input, value) {
  input.attr('placeholder', value).val('')
}

function removePlaceholder (input) {
  if (input.attr('placeholder')) {
    input.removeAttr('placeholder')
  }
}

function setDefaultValue (input, value) {
  removePlaceholder(input)
  input.val(value)
}

function toggleList (selector, list, toggle) {
  if (toggle === 'Open') {
    list.removeClass('hidden-all')
    selector.attr('aria-expanded', 'true')
  } else {
    list.addClass('hidden-all')
    selector.attr('aria-expanded', 'false')
  }
}

function doFilter (input, status, arrOptions) {
  const terms = input.val()

  const arrFilteredOptions = arrOptions.filter(function (option) {
    if (option.innerText.toLowerCase().includes(terms.toLowerCase())) {
      return true
    }
  })

  arrOptions.forEach(option => {
    $(option).hide()
  })

  arrFilteredOptions.forEach(function (option) {
    $(option).show()
  })

  setState('filtered')
  updateStatus(status, arrFilteredOptions.length)
}

function updateStatus (status, howMany) {
  status.textContent = howMany + ' options available.'
}

function makeChoice (selector, input, options, arrOptions, choice) {
  let values = []
  let displays = []
  let selectedValue = choice !== undefined ? $(choice).attr('data-value') : ''
  let selectedInputs = selector.find('input[type="checkbox"]:checked')
  let $defaultOption = selector.find('input[type="checkbox"][default-option]')
  let defaultText = ''
  if ($defaultOption !== undefined) {
    defaultText = $defaultOption.length === 1 ? $defaultOption.closest('li').attr('data-display-name') : ''
  }
  let defaultSelected = selectedValue.length === 0
  if (selectedInputs.length > 0) {
    selector.addClass('selected')
    selectedInputs.each(function () {
      let $li = $(this).closest('li')
      if (!defaultSelected) {
        if ($li.attr('data-value') !== '') {
          values.push($li.attr('data-value'))
          displays.push($li.attr('data-display-name'))
        } else {
          $li.find('input').prop('checked', false)
        }
      } else {
        if ($li.attr('data-value') !== '') {
          $li.find('input').prop('checked', false)
        }
      }
    })
  } else {
    selector.removeClass('selected')
    if ($defaultOption.length === 1) {
      $defaultOption.prop('checked', true)
      defaultSelected = true
    }
  }

  if (!defaultSelected) {
    values = values.filter(el => {
      return el != null && el !== ''
    })
    displays = displays.filter(el => {
      return el != null && el !== ''
    })
  } else {
    values = ['']
    displays = [defaultText]
  }

  if (values.length === 0) {
    if (Object.keys($defaultOption).length > 0) {
      $defaultOption.prop('checked', true)
      values = ['']
      displays = [defaultText]
    }
  }

  let newValue = values.join(',')
  let newDisplay = displays.join(', ')
  input.attr('data-value', newValue)
  input.attr('data-display-name', newDisplay)
  input.val(newDisplay)
  input.trigger('input')
}

function findFocus () {
  const focusPoint = document.activeElement
  return focusPoint
}

function setState (newState) {
  switch (newState) {
    case 'initial':
      csState = 'initial'
      break
    case 'opened':
      csState = 'opened'
      break
    case 'filtered':
      csState = 'filtered'
      break
    case 'closed':
      csState = 'closed'
  }
}

function doKeyAction (currentSelector, whichKey) {
  const currentFocus = findFocus()
  const list = currentSelector.find('ul')
  const input = currentSelector.find('input')
  const options = list.find('li')
  const arrOptions = Array.from(options)
  const status = currentSelector.closest('.js-select-status')

  switch (whichKey) {
    case 'Enter':
      if (csState === 'initial') {
        // if state = initial, toggleOpen and set state to opened
        toggleList(currentSelector, list, 'Open')
        setState('opened')
      } else if (csState === 'opened' && $(currentFocus).is('li')) {
        makeChoice(currentSelector, input, options, arrOptions, currentFocus)
        toggleList(currentSelector, list, 'Shut')
        setState('closed')
      } else if (csState === 'opened' && $(currentFocus).is('input')) {
        toggleList(currentSelector, list, 'Shut')
        setState('closed')
      } else if (csState === 'filtered' && $(currentFocus).is('li')) {
        makeChoice(currentSelector, input, options, arrOptions, currentFocus)
        toggleList(currentSelector, list, 'Shut')
        setState('closed')
      } else if (csState === 'filtered' && $(currentFocus).is('input')) {
        toggleList(currentSelector, list, 'Open')
        setState('opened')
      } else {
        toggleList(currentSelector, list, 'Open')
        setState('filtered')
      }
      break

    case 'Escape':
      if (csState === 'opened' || csState === 'filtered') {
        toggleList(currentSelector, list, 'Shut')
        setState('initial')
      }
      break

    case 'ArrowDown':
      if (csState === 'initial' || csState === 'closed') {
        toggleList(currentSelector, list, 'Open')
        setState('opened')
      } else {
        toggleList(currentSelector, list, 'Open')
      }
      break
    case 'ArrowUp':
      if (csState === 'initial' || csState === 'closed') {
        toggleList(currentSelector, list, 'Open')
        setState('opened')
      }
      break
    default:
      if (csState === 'initial') {
        toggleList(currentSelector, list, 'Open')
        // doFilter(input, status, arrOptions)
        setState('opened')
      } else if (csState === 'opened') {
        doFilter(input, status, arrOptions)
        setState('filtered')
      } else if (csState === 'closed') {
        doFilter(input, status, arrOptions)
        setState('filtered')
      } else {
        doFilter(input, status, arrOptions)
      }
      break
  }
}

export default {
  init
}
