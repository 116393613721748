import { getElements } from '../../get/getElement'
import { SELECTORS, SCHOOL_SEARCH_FIELD_NAMES } from './ProfileModalConstants'
import { get } from 'lodash'
import { getField } from '../../../registration/RegistrationGetters'
import { getUser, getUserInstitution } from '../../CommonGetters'

export const getProfileModalSelector = key => get(SELECTORS, key)

export const getProfileModalElements = () => {
  const selectorKeys = Object.keys(SELECTORS)
  return selectorKeys.reduce((acc, key) => {
    acc[key] = getElements(getProfileModalSelector(key))
    return acc
  }, {})
}
/// ///
/// Get All
/// ///
const getAllSections = () => {
  const { profileSectionsAll } = getProfileModalElements()
  return profileSectionsAll
}

const getAllButtonSections = () => {
  const { buttonSectionsAll } = getProfileModalElements()
  return buttonSectionsAll
}

export const getAllButtonsAndSections = () => {
  return [...getAllButtonSections(), ...getAllSections()]
}

/// ///
/// Get Some
/// ///

export const getSchoolSearchElements = () => {
  const {
    profileSectionSearch,
    buttonSectionSearch
  } = getProfileModalElements()

  return [...profileSectionSearch, ...buttonSectionSearch]
}

export const getSchoolSearchFields = () => {
  return SCHOOL_SEARCH_FIELD_NAMES.map(getField)
}

export const getMainElements = () => {
  const { profileSectionMain, buttonSectionMain } = getProfileModalElements()

  return [...profileSectionMain, ...buttonSectionMain]
}

export const getResultsElements = () => {
  const {
    profileSectionResults,
    buttonSectionResults
  } = getProfileModalElements()

  return [...profileSectionResults, ...buttonSectionResults]
}

export const getCurrentSchoolInformationFromUser = () => {
  const user = getUser()
  const school = user.school || getUserInstitution()
  return school
}
