import {
  getMainElements,
  getSchoolSearchElements,
  getAllButtonsAndSections,
  getResultsElements
} from './ProfileModalGetters'
import $ from 'jquery'

const HIDDEN_SECTION_CLASS = 'hide'

const __hide = element => element.classList.add(HIDDEN_SECTION_CLASS)
const __show = element => element.classList.remove(HIDDEN_SECTION_CLASS)

const __showSection = elements => {
  getAllButtonsAndSections().map(__hide)
  elements.map(__show)
}

export const showSchoolReselectSection = e => {
  __showSection(getSchoolSearchElements())
}

export const showMainSection = e => {
  __showSection(getMainElements())
}

export const showResultsSection = e => {
  __showSection(getResultsElements())
}

export const updateSchoolInformationSection = ({
  schoolId,
  schoolName,
  regionName,
  stateOrProvince
}) => {
  $('#profile form.profile input[name="user.state"]').val(stateOrProvince)
  $('#profile form.profile [data-selected-school-id]').val(schoolId)
  $('#profile form.profile [data-selected-school-name]').text(schoolName)
  $('#profile form.profile [data-selected-school-region]').text(regionName)
}
