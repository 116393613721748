import { VideoType } from '../types/Asset.flow'
import { onClick as onClickVimeoButton } from '../../../../VimeoButton'
import { onClick as onClickVidyardButton } from '../../../../VidyardButton'

const openVideoModal = ({
  event,
  videoType
}: {
  videoType: VideoType,
  event: Event
}) => {
  if (videoType === 'vidyard') {
    onClickVidyardButton(event)
  }

  if (videoType === 'vimeo') {
    onClickVimeoButton(event)
  }
}

export default openVideoModal
