// @flow
import { groupBy } from 'lodash'
import { shouldFieldBeDisabled, validateField } from './RegistrationValidators'
import { getTypeForControl } from './RegistrationGetters'
import {
  Field,
  Control,
  ValidationStatus,
  DisabledStatus
} from './RegistrationTypes'
// Groupers will sort through arrays
// And categorize them.

export const groupFieldsByValidationStatus = (
  fields: Field[]
): ValidationStatus => {
  const base: ValidationStatus = {
    valid: [],
    invalid: []
  }
  const result = groupBy(fields, field => {
    const isFieldValid = validateField(field)
    return isFieldValid ? 'valid' : 'invalid'
  })

  return Object.assign(base, result)
}

export const groupFieldsByDisabledStatus = (
  fields: Field[]
): DisabledStatus => {
  const base: DisabledStatus = {
    enabled: [],
    disabled: []
  }
  const result = groupBy(fields, field => {
    return shouldFieldBeDisabled(field) ? 'disabled' : 'enabled'
  })

  return Object.assign(base, result)
}

export const groupControlsByType = (controls: Control[]) => {
  return groupBy(controls, control => {
    return getTypeForControl(control)
  })
}
