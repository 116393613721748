// @flow
import { Asset } from '../variations/classroom/types/Asset.flow'
import { SectionId } from '../variations/classroom/types/Section.flow'
import assetTemplate from '../templates/asset.html'
import mustache from 'mustache'
import {
  generateElementTemplate,
  generateDrawerFieldsTemplate
} from '../toolbox'
import { isLessonDetail } from '../variations/classroom/toolbox.classroom'
import { getAttributeFromWindow } from '../get/getAttribute'
import { isAssetPrerequisiteForSection } from '../get/getAsset'

export const isAssetRib = asset => {
  return asset.assetType === 'rib'
}
export const isAssetCurrentGrade = asset => {
  return asset.grade === getAttributeFromWindow('CURRENT_GRADE')
}

export const shouldAssetDisplayInPrereqColumn = (asset, sectionId) => {
  return (
    isAssetPrerequisiteForSection(asset, sectionId) &&
    isAssetRib(asset) &&
    !isLessonDetail() // Lesson Detail doesn't have a prereq column.
  )
}

export const convertAssetIntoPrerequisite = (
  asset: Asset,
  sectionId: SectionId
) => {
  if (!isAssetPrerequisiteForSection(asset, sectionId)) return asset

  let prereqAsset = Object.assign({}, asset, {
    isPreqreqForSection: true
  })

  if (shouldAssetDisplayInPrereqColumn(asset, sectionId)) {
    return Object.assign(prereqAsset, {
      assetType: 'prereq',
      originalAssetType: asset.assetType
    })
  }

  return prereqAsset
}

export const convertAssetToAssetThumbnail = (asset: Asset) => {
  asset.alternates.sort((a, b) => (a.key > b.key) ? -1 : 1)

  return mustache.render(assetTemplate, {
    ...asset,
    drawerFieldsTemplate: generateDrawerFieldsTemplate(),
    elementTemplate: generateElementTemplate('minutes')
  })
}
