import CauliflowerModal from '../../../../CauliflowerModal'

const openModal = data => {
  if (data.event) {
    data.event.preventDefault()
  }
  CauliflowerModal.open(data.modal)
}

export default openModal
