// @flow
import {
  Asset,
  CleanAsset,
  AssetType,
  AssetId
} from '../variations/classroom/types/Asset.flow'
import { SectionId } from '../variations/classroom/types/Section.flow'
import { get, groupBy } from 'lodash'
import { convertAssetIntoPrerequisite } from '../convert/convertAsset'
import { getSection } from './getSection'
import { createAssetTypeFilter } from '../variations/classroom/actions/toggleAssetDrawer'
import { SORT_AUDIENCES, SORT_GRADES } from '../consts'

export const getAllAssetsAsArray = (): CleanAsset[] => {
  return get(window, 'ASSET_ARRAY', {})
}

export const getAssetByIdForSection = (
  assetId: AssetId,
  sectionId: SectionId
): CleanAsset => {
  if (!assetId) throw new Error('Asset ID must be specified.')
  if (!sectionId) throw new Error('Section ID must be specified.')
  const { assets } = getSection(sectionId)

  const asset = assets.find(({ id }) => id === assetId)

  if (!asset) throw new Error('No Asset found.')

  return asset
}

export const getAssetType = (asset: Asset) => {
  return asset.assetType || ''
}

/**
 * Returns an array with an array of associated ids for an asset
 */
export const getAssociatedIdsFromAsset = (asset: Asset): String[] => {
  return asset.associatedIDs.split(' ')
}

export const getPrerequisitesFromAsset = (asset: Asset): String[] => {
  return asset.prerequisiteFor.split(' ').filter(id => id !== '')
}

export const countAssetsByType = (
  assets: Asset[] = [],
  sectionId?: SectionId
) => {
  const typesAndAssets = groupAssetsByType(assets, sectionId)
  return Object.keys(typesAndAssets).reduce((acc, type) => {
    acc[type] = typesAndAssets[type].length
    return acc
  }, {})
}

export const isAssetVimeo = ({ videoType }) => {
  return videoType === 'vimeo'
}

export const isAssetVidyard = ({ videoType }) => {
  return videoType === 'vidyard'
}

export const isAssetVideo = (asset: Asset) => {
  const { videoId } = asset
  const isVimeo = isAssetVimeo(asset)
  const isVidyard = isAssetVidyard(asset)
  const isVideo = !!videoId && (isVimeo || isVidyard)

  return isVideo
}

export const isAssetPrerequisiteForSection = (
  asset: Asset | CleanAsset,
  sectionId: SectionId
) => {
  return getPrerequisitesFromAsset(asset).includes(sectionId)
}

export const sortAssets = (
  assets: CleanAsset[],
  assetTypes: AssetType[] = []
): Asset[] => {
  assets = [...assets]
  assets.forEach(function (obj, index) {
    obj.originalSortOrder = index
  })

  const indexOfOrElse = (array: Array, value, notFoundValue) => {
    const i = array.indexOf(value)
    return i === -1 ? notFoundValue : i
  }

  const isPrereqValue = (asset: CleanAsset) => (asset.prereq ? 1 : 0)

  const toInt = (value: String, defaultValue: Number) => {
    const i = parseInt(value)
    return isNaN(i) ? defaultValue : i
  }

  const notEmpty = (s: String) => {
    return s && s !== 'undefined'
  }

  const alphanumericCompare = (l1: String, l2: String) => {
    if (notEmpty(l1) && notEmpty(l2)) {
      return l1.localeCompare(l2, 'en', { numeric: true, sensitivity: 'base' })
    } else if (notEmpty(l1)) {
      return 1
    } else if (notEmpty(l2)) {
      return -1
    }
    return 0
  }

  let comparator = function (a: CleanAsset, b: CleanAsset) {
    let comparisons = [
      // Asset type
      [indexOfOrElse(assetTypes, a.assetType, Number.MAX_SAFE_INTEGER), indexOfOrElse(assetTypes, b.assetType, Number.MAX_SAFE_INTEGER)],

      // Is prerequisite?
      [isPrereqValue(a), isPrereqValue(b)],

      // Grades
      [indexOfOrElse(SORT_GRADES, a.grade, Number.MAX_SAFE_INTEGER), indexOfOrElse(SORT_GRADES, b.grade, Number.MAX_SAFE_INTEGER)],

      // Lessons
      () => alphanumericCompare(a.lesson, b.lesson),

      // Drawer Labels
      () => alphanumericCompare(a.drawerLabel, b.drawerLabel),

      // Non-lesson asset order (Blank first)
      [toInt(a.order, Number.MIN_SAFE_INTEGER), toInt(b.order, Number.MIN_SAFE_INTEGER)],

      // Copyright year (Reverse)
      [toInt(b.copyrightYear, Number.MAX_SAFE_INTEGER), toInt(a.copyrightYear, Number.MAX_SAFE_INTEGER)],

      // Audience
      [indexOfOrElse(SORT_AUDIENCES, a.originalAudience, Number.MAX_SAFE_INTEGER), indexOfOrElse(SORT_AUDIENCES, b.originalAudience, Number.MAX_SAFE_INTEGER)],

      // Original Magnolia order
      [a.originalSortOrder, b.originalSortOrder]
    ]

    for (let index = 0; index < comparisons.length; index++) {
      let result = 0

      if (Array.isArray(comparisons[index])) {
        result = comparisons[index][0] - comparisons[index][1]
      } else if (typeof comparisons[index] === 'function') {
        result = comparisons[index]()
      }

      if (!isNaN(result) && result !== 0) {
        return result
      }
    }

    return 0
  }

  return assets.sort(comparator)
}

// Provide a sectionId to evaluate assets as prerequisites
export const groupAssetsByType = (assets: Asset[], sectionId: SectionId) => {
  const assetsWithUpdatedTypes = assets.map(asset => {
    if (isAssetPrerequisiteForSection(asset, sectionId)) {
      return convertAssetIntoPrerequisite(asset, sectionId)
    } else {
      return asset
    }
  })
  return groupBy(assetsWithUpdatedTypes, ({ assetType }) => assetType)
}

export const filterAndSortAssetsByType = (
  assetTypes: AssetType[] | AssetType,
  assets: Asset[]
) => {
  let types = assetTypes
  if (!Array.isArray(types)) types = assetTypes.split(' ')

  const filter = createAssetTypeFilter(types)
  return sortAssets(assets.filter(filter), types)
}

export const groupAssetsBySession = (assets: Asset[]) => {
  return groupBy(assets, ({ drawerLabel }) => drawerLabel || 'none')
}
