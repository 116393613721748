import { getElements } from './getElement'

export const getAllLessonRows = () => {
  return getElements('tr[data-lesson-row]')
}

export const getAllAssetTypeRows = () => {
  return getElements('[data-asset-type-row]')
}

export const getAllAssessmentRows = () => {
  return getElements('[data-assessment-row]')
}
