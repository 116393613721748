import { isUndefined } from 'lodash'
import HANDLERS from './handlers'
import { ToolboxInitializationOptions } from '../toolbox.classroom'

const __attachmentTracker = () => {
  let hasBeenAttached = false
  let options = {}
  return (newOptions: ToolboxInitializationOptions) => {
    if (typeof newOptions !== 'undefined') {
      options = newOptions
    }
    if (!hasBeenAttached) {
      document.addEventListener('click', e => actionHandler(e, options), true)
      document.addEventListener('change', e => actionHandler(e, options), true)
    }
    hasBeenAttached = true
  }
}

export const attachActionHandler = __attachmentTracker()

const actionHandler = (event, options) => {
  if (!event.target.dataset.action) return true
  const data = event.target.dataset
  const action = data.action
  const type = data.listenerType || 'click'
  const handler = HANDLERS[action]
  const hasHandler = !isUndefined(handler)
  const isTypeMatch = type === event.type

  if (hasHandler && isTypeMatch) {
    if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
      console.log(
        `%cAction Handler: ${action}`,
        'color: green; font-weight: bold; text-decoration: underline'
      )
      console.log({ ...data, options })
    }
    handler({ ...data, event }, options)
  } else {
    if (!hasHandler) {
      console.warn(`No Handler found for this action: ${action}`)
    }
  }
}
