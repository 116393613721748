import './scss/landing.scss'

import { getElement } from './js/common/get/getElement'
import { setElementToDisplayBlock } from './js/common/CommonPresenters'

const getPlatformLoginSection = () => {
  return navigator.cookieEnabled ? 'session' : 'cookies'
}

document.addEventListener('DOMContentLoaded', function (event) {
  const platformLoginSection = getPlatformLoginSection()
  const sectionElement = getElement(`section#${platformLoginSection}`)
  setElementToDisplayBlock(sectionElement)
})
