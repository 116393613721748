import {
  addSchoolReselectButtonListeners,
  addSchoolSelectListener,
  addProfileModalFormListeners,
  addSchoolSearchValidationListeners
} from './ProfileModalListeners'
import { attachActionHandler } from '../../variations/classroom/actions/actions'

export default () => {
  addSchoolSearchValidationListeners()
  addSchoolReselectButtonListeners()
  addProfileModalFormListeners()
  addSchoolSelectListener()
  attachActionHandler()
}
