// Note: This Component depends on CauliflowerModal and Vimeo.Player;
import CauliflowerModal from './CauliflowerModal'
import $ from 'jquery'
import Player from '@vimeo/player'

var placeholderDivId = 'vimeo-placeholder'
var player
var $modal
var $title

var init = function () {
  var $buttons = $('.button.video[data-video-type="vimeo"]')
  CauliflowerModal.init()
  $modal = CauliflowerModal.findOrCreate(
    {
      title: '', // No Title
      onClose: pause,
      body:
        '<div id="' + placeholderDivId + '" class="vimeo-modal__video"></div>'
    },
    'VimeoPlayer'
  )

  $modal.hide()
  $buttons.on('click', onClick)
}

export var onClick = function (e: Event) {
  var videoId = e.target.dataset.videoId
  var title = e.target.dataset.modalTitle
  $('#' + placeholderDivId).show()

  updateVideo(videoId, function () {
    updateModalTitle(title)
    CauliflowerModal.open('VimeoPlayer')
  })
}

var pause = function (e) {
  player.pause()
}

var updateModalTitle = function (title) {
  $title = $modal.find('.modal__header__title')
  $title.text(title)
}

var updateVideo = function (videoId, callback) {
  if (!player) {
    player = new Player(placeholderDivId, {
      url: 'https://vimeo.com/' + videoId
    })
  }

  player.getVideoId().then(function (currentVideoId) {
    var newVideoId = parseInt(videoId, 10)
    if (currentVideoId !== newVideoId) {
      player.loadVideo(newVideoId).then(function (id) {
        callback(id)
        player.play()
      })
    } else {
      callback(currentVideoId)
      player.play()
    }
  })
}

export default {
  init: init
}
