import $ from 'jquery'
import { get } from 'lodash'
import { getAssetByIdForSection, isAssetVideo } from './common/get/getAsset'

var modalPrefix = 'data-cauliflower-modal'
var activeModal = null
var section = null
var onClose = {} // {[modalName]: {function} callback }
var shouldDisableClose = false
// Element Templates
var $wrapper = $('<div id="modal__wrapper"></div>')
var $overlay = $('<div class="modal__overlay"></div>')
var $modal = $('<div class="modal__main"></div>')
var $header = $('<div class="modal__header"></div>')
var $title = $('<span class="modal__header__title"></span>')
var $subtitle = $('<span class="modal__header__subtitle"></span>')
var $close = $('<div class="modal__header__close"></div>')
var $content = $('<div class="modal__content"></div>')
var $termsOfUse = $('<div class="modal__termsOfUse"></div>')
var $footer = $('<div class="modal__footer"></div>')

var init = function () {
  var stopProp = function (e) {
    e.stopImmediatePropagation()
  }

  __findOrCreateWrapper()

  $wrapper.on('click', '.modal__main', stopProp)
  $wrapper.on('click', '.modal__header__close, .modal__overlay', close)
}

/**
 * Creates a new modal and appends it to the overlay.
 *
 * @param {Object} options - Options for the new modal. Can have a title, subtitle, and/or body.
 *    title {string}
 *    subtitle {string}
 *    body {HTML Element}
 *    className {string}
 *    onClose {function}
 *
 * @param {string} name - A unique identifier for this modal.
 * */
var create = function (options, name) {
  var $newModal = __makeNewModal(options)
  __addNameToModal($newModal, name)
  __updateModalOptions(name, options)
  $overlay.append($newModal)
  return $newModal
}

const __updateModalOptions = (name, options) => {
  if (typeof options.onClose === 'function') {
    onClose[name] = options.onClose
  }
}

/** Either finds `.cauliflower #modal__wrapper` or appends a new one to 'body'. */
var __findOrCreateWrapper = function () {
  if ($('.cauliflower #modal__wrapper').length > 0) {
    $wrapper = $('.cauliflower #modal__wrapper')
    $overlay = $wrapper.children('.modal__overlay')
  } else {
    var $cauliflowerParent = $('<div class="cauliflower"></div>')
    $wrapper.append($overlay)
    $cauliflowerParent.append($wrapper)
    $('body').append($cauliflowerParent)
  }

  return $wrapper
}

/**
 * Find if a modal with a particular name already exists.
 * @param {string} name
 */
const doesModalExist = (name: string) => {
  if (!name) console.error('No `name` provided.')
  return find(name).length > 0
}

/**
 * Creates a new modal and appends it to the overlay.
 *
 * @param {Object} options - Options for the new modal. Can have a title, subtitle, and/or body.
 *    title {string}
 *    subtitle {string}
 *    className {string}
 *    body {HTML Element}
 *    onClose {function}
 *
 * @param {string} name - A unique identifier for this modal.
 * */
const findOrCreate = (options, name) => {
  const alreadyExists = doesModalExist(name)

  if (alreadyExists) {
    return find(name)
  } else {
    return create(options, name)
  }
}

/**
 * If a modal with "name" exists, add the options to it.
 * @param {string} name
 * @param {object} options
 */
export const addOptionsToModal = (name, options) => {
  if (doesModalExist(name)) {
    __updateModalOptions(name, options)
  } else {
    console.log(`%cModal does not exist: ${name}`, 'color: red;')
  }
}

/**
 * Adds the attr we use to find modals (in the shape of [data-${modalPrefix}=${name}])
 *
 * @param {jQuery Element} $aModal - A jquery element to add the attribute to
 * @param {string} name - A unique string to be added to the modal
 * */
var __addNameToModal = function ($aModal, name) {
  if (!name) throw new Error('No name specified for modal')

  return $aModal.attr(modalPrefix, name)
}

/** Adds the attr we use to find modals (in the shape of [data-${modalPrefix}=${name}]) */
var __makeNewModal = function (options) {
  var $newModal = $modal.clone()

  // Add a classname, if one is specificied in options.
  // This was added for the Asset Modal in classroom mode -- specs are a little different
  // and we need to be able to make exceptions for it in the styling.
  $newModal.addClass(get(options, ['className'], ''))

  var $newContent = __makeNewContent(options)
  var $newHeader = __makeNewHeader(options)

  $newModal.append($newHeader)
  $newModal.append($newContent)

  return $newModal
}

/**
 * Creates the header section of a modal.
 *
 * @param {Object} options - An object that can have a title or subtitle property, both strings.
 * */
var __makeNewHeader = function (options) {
  var $newHeader = $header.clone()
  var $newTitle = $title.clone()
  var $newSubtitle = $subtitle.clone()
  var $newClose = $close.clone()

  var title = options.title || 'Modal'
  var subtitle = options.subtitle || null

  $newTitle.text(title)

  if (subtitle) {
    $newSubtitle = $newSubtitle.text(subtitle)
    $newTitle.append($newSubtitle)
  }

  $newHeader.append($newTitle)
  $newHeader.append($newClose)
  return $newHeader
}

/**
 * Creates the Terms of Use section of a modal.
 *
 * @param {Object} options - An object that can have a boolean showTermsOfUse.
 * */
var __makeNewTermsOfUse = function (options) {
  var $newTermsOfUse = $termsOfUse.clone()
  var link = window.TERMS_OF_USE || '#'

  if (options.showTermsOfUse) {
    var content = `As a reminder, our <a href="${link}" target="_blank">Terms of Use</a> allow educators at schools that have purchased a license to Teacher Toolbox to post student-facing materials on a password-protected site.`
    $newTermsOfUse.append(content)
    return $newTermsOfUse
  }
}

/**
 * Creates the content section of a modal.
 *
 * @param {Object} options - An object that can have a body section, which should be an HTML Element.
 * */
var __makeNewContent = function (options) {
  var $newContent = $content.clone()
  var body = options.body || $('<div><h1>Modal Content</h1></div>')
  var $newFooter = __makeNewFooter(options)

  $newContent.append(body)
  $newContent.append($newFooter)

  return $newContent
}

/**
 * Creates the content section of a modal.
 *
 * @param {Object} options - An object that can have a body section, which should be an HTML Element.
 * */
var __makeNewFooter = function (options) {
  var $newFooter = $footer.clone()
  var $newTermsOfUse = __makeNewTermsOfUse(options)
  $newFooter.append($newTermsOfUse)

  if (window.userJSON && window.userJSON.userType.indexOf('IREADY') > -1 && !options.hideCopyLink) {
    var $newCopyLink = __makeNewCopyLink(options)
    $newFooter.append($newCopyLink)
  }

  return $newFooter
}

/**
 * Creates the content section of a modal.
 *
 * @param {Object} options - An object that can have a body section, which should be an HTML Element.
 * */
var __makeNewCopyLink = function (options) {
  var assetAttr = ''
  var lessonAttr = ''
  if (options.asset !== undefined && options.asset !== null) {
    assetAttr = `data-asset-id='${options.asset.id}'`
  }
  if (options.section !== undefined && options.section !== null) {
    lessonAttr = `data-lesson-id='${options.section.id}'`
  }
  return `<div class="modal__copy-link">
    <button data-action="copy-link" ${assetAttr} ${lessonAttr}>Copy Link</button>
    <div class="modal__copy-message copied-message">Link copied!</div>
    <div class="modal__copy-message educator-message">For educators only.</div>
  </div>`
}

/**
 * Finds the modal by name
 *
 * @param {string} name - A unique identifier for this modal.
 * */
var find = function (name) {
  return $('[' + modalPrefix + '=' + name + ']')
}

/**
 * Finds all modals with the modalPrefix
 *
 * */
var findAll = function () {
  return $('[' + modalPrefix + ']')
}

var closeAll = function () {
  $('[' + modalPrefix + ']').hide()
  $('.modal__main').hide()
  $wrapper.hide()
}

/**
 * Opens a modal by name
 *
 * @param {string} name - A unique identifier for the modal to open.
 * @param {'small' | 'medium' | 'large' | 'auto' | 'asset'} [size] - Optional size for modal. Default is medium.
 *
 * */
var open = function (name, size, assetSection) {
  findAll().hide()
  activeModal = name
  section = assetSection
  var $modalToOpen = find(name)

  if ($modalToOpen.length === 0) {
    throw new Error('No modal found with title ' + name)
  }

  if (!size) {
    $modalToOpen.addClass('is-medium')
  } else {
    $modalToOpen.addClass('is-' + size)
  }

  $modalToOpen.addClass('is-active')
  $modalToOpen.show()
  $wrapper.show()
}

/**
 * Closes the modal.
 * */
var close = function () {
  if (shouldDisableClose) {
    console.info('Modal closing disabled.')
    return false
  } else {
    $wrapper.hide()

    $modal.removeClass('is-active')
    let modal = find(activeModal)

    // remove the modal if it is an asset and has a video
    if (modal.hasClass('is-asset')) {
      if (section) {
        let asset = getAssetByIdForSection(activeModal, section)
        let isVideo = isAssetVideo(asset)

        if (isVideo) {
          let modal = find(activeModal)
          modal.remove()
        }
      }
    }

    var callback = onClose[activeModal]

    if (typeof callback === 'function') {
      callback()
    }

    activeModal = null
  }
}

const preventClose = () => {
  $('#modal__wrapper').addClass('modal__wrapper--prevent-close')
  shouldDisableClose = true
}

const enableClose = () => {
  $('#modal__wrapper').removeClass('modal__wrapper--prevent-close')
  shouldDisableClose = false
}

export default {
  init,
  open,
  find,
  create,
  findOrCreate,
  preventClose,
  enableClose,
  addOptionsToModal,
  close,
  closeAll
}
