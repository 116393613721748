export const SCHOOL_SEARCH_FIELD_NAMES = [
  'firstName',
  'lastName',
  'state',
  'schoolMode',
  'schoolInput'
]

export const INSTITUTION_MODAL_FIELD_NAMES = [
  'institution-type',
  'institution-name',
  'institution-address',
  'institution-city',
  'institution-state',
  'institution-zip'
]

export const VALID_FIELD_NAMES = [
  ...SCHOOL_SEARCH_FIELD_NAMES,
  ...INSTITUTION_MODAL_FIELD_NAMES
]

// export const SCHOOL_MODES = ['SCHOOL', 'DISTRICT', 'ZIP']
export const SCHOOL_MODES = ['SCHOOL', 'ZIP']

export const INSTITUTION_MODAL_TYPE = ['School', 'Personal']
