// @flow
/// ///////////////////////
// Mappers will take in a FieldName
// and return some kind of information related to that field.
/// ///////////////////////
import {
  isValidSchoolMode,
  isValidInstitutionType
} from './RegistrationValidators'
import {
  getValueFromTextControl,
  getControlForField,
  getControlsForField,
  getValueFromRadioControls,
  getValueFromSelectOptionControls
} from './RegistrationGetters'
import { groupControlsByType } from './RegistrationGroupers'
import { FieldMapperFactory } from './RegistrationFactories'
import { SchoolSearchMode, InstitutionType } from './RegistrationTypes'

export const getDependantFieldNames = FieldMapperFactory(
  {
    firstName: [],
    lastName: [],
    state: ['firstName', 'lastName'],
    schoolMode: ['firstName', 'lastName', 'state'],
    schoolInput: ['firstName', 'lastName', 'state', 'schoolMode'],
    'institution-type': [],
    'institution-name': ['institution-type'],
    'institution-address': ['institution-type', 'institution-name'],
    'institution-city': [
      'institution-type',
      'institution-name',
      'institution-address'
    ],
    'institution-state': [
      'institution-type',
      'institution-name',
      'institution-address',
      'institution-city'
    ],
    'institution-zip': [
      'institution-type',
      'institution-name',
      'institution-address',
      'institution-city',
      'institution-state'
    ]
  },
  []
)

export const getValidatorForField = FieldMapperFactory(
  {
    firstName: value => {
      return value.length > 0
    },
    lastName: value => {
      return value.length > 0
    },
    state: value => {
      return value !== '-1'
    },
    schoolMode: (value: SchoolSearchMode) => {
      return isValidSchoolMode(value)
    },
    schoolInput: value => {
      return value.length > 2
    },
    'institution-type': (value: InstitutionType) => {
      return isValidInstitutionType(value)
    },
    'institution-name': value => {
      return value.length > 0
    },
    'institution-address': value => {
      return value.length > 0
    },
    'institution-city': value => {
      return value.length > 0
    },
    'institution-state': value => {
      return value !== '-1'
    },
    'institution-zip': value => {
      // TO DO: validate zip properly
      return value.length > 0
    }
  },
  (...args) => {
    console.error('No Validator Found')
    return false
  }
)

export const getValueAccessorForField = FieldMapperFactory(
  {
    firstName: field => {
      return getValueFromTextControl(getControlForField(field))
    },
    lastName: field => {
      return getValueFromTextControl(getControlForField(field))
    },
    state: field => {
      const options = Array.from(getControlForField(field).options)
      return getValueFromSelectOptionControls(options)
    },
    schoolMode: (field): string => {
      const controls = getControlsForField(field)
      const { radio: radios } = groupControlsByType(controls)

      return getValueFromRadioControls(radios)
    },
    schoolInput: (field): string => {
      const control = getControlForField(field)
      return getValueFromTextControl(control)
    },
    'institution-type': field => {
      const control = getControlsForField(field)
      return getValueFromRadioControls(control)
    },
    'institution-name': field => {
      const control = getControlForField(field)
      return getValueFromTextControl(control)
    },
    'institution-address': field => {
      const control = getControlForField(field)
      return getValueFromTextControl(control)
    },
    'institution-city': field => {
      const control = getControlForField(field)
      return getValueFromTextControl(control)
    },
    'institution-state': field => {
      const options = Array.from(getControlForField(field).options)
      return getValueFromSelectOptionControls(options)
    },
    'institution-zip': field => {
      const control = getControlForField(field)
      return getValueFromTextControl(control)
    }
  },
  () => false
)
