import $ from 'jquery'

// when JS is loaded, set up our starting point
// if JS fails to load, the custom select remains a plain text input
// create and set start point for the state tracker
let csState = 'initial'

const init = selectors => {
  $(selectors).each(function () {
    const selector = $(this)
    let csSelector, csInput, csList, csOptions, csStatus, aOptions

    csSelector = selector
    csInput = selector.find('input')
    csList = selector.find('ul')
    csOptions = csList.find('li')
    csStatus = selector.closest('.js-select-status')
    aOptions = Array.from(csOptions)

    // inform assistive tech (screen readers) of the names & roles of the elements in our group
    csSelector.attr('role', 'combobox')
    csSelector.attr('aria-haspopup', 'listbox')
    csSelector.attr('aria-owns', 'custom-select-list') // container owns the list...
    csInput.attr('aria-autocomplete', 'both')
    csInput.attr('aria-controls', 'custom-select-list') // ...but the input controls it
    csList.attr('role', 'listbox')
    csOptions.each(function () {
      $(this).attr('role', 'option')
      $(this).attr('tabindex', '-1') // make li elements keyboard focusable by script only
    })

    // set up a message to keep screen reader users informed of what the custom input is for/doing
    csStatus.textContent =
      csOptions.length +
      ' options available. Arrow down to browse or start typing to filter.'

    if (csSelector.find('#subject-select').length > 0) {
      setInitialSubjectInput($('#subject-select'), $(csOptions))
      var subjectInputWidth = $('#ca-select-list-subject').width()
      $('.ca-select-container--small').attr('style', 'width:' + subjectInputWidth + 'px')
    }

    if (csSelector.find('#state-select').length > 0) {
      setStateInput($('#state-select'))
      var stateInputWidth = $('#ca-select-list-states').width()
      $('.ca-select-container--large').attr('style', 'width:' + stateInputWidth + 'px')
    }

    if (selector) {
      // EVENTS
      // /////////////////////////////////
      csSelector.off('click') // Remove previous bindings
      csSelector.on('click', function () {
        const currentSelector = $(this)
        const currentFocus = findFocus()
        const list = currentSelector.find('ul')
        const selected = list.find('[selected]')
        const selectedText = selected.text()
        const input = currentSelector.find('input')
        const options = list.find('li')
        const arrOptions = Array.from(options)
        const isOpen = list.hasClass('hidden-all')

        // if a dropdown is already open and you click a different one, close all
        if ((csState === 'opened' || csState === 'filtered') && $(currentFocus).is('input') && isOpen) {
          $('.js-select').each(function () {
            toggleList($(this), $(this).find('ul'), 'Shut')
            setState('initial')
          })
        }

        if (!csSelector.hasClass('editable')) {
          if ($(currentFocus).is('input') && input.attr('data-no-clear') === undefined) {
            setPlaceholder(input, selectedText)
          }

          switch (csState) {
            case 'initial':
              toggleList(currentSelector, list, 'Open')
              options.show()
              setState('opened')
              break
            case 'opened':
              if ($(currentFocus).is(input)) {
                if (input.attr('data-no-clear') === undefined) {
                  setDefaultValue(input, selectedText)
                }
                toggleList(currentSelector, list, 'Shut')
                setState('initial')
              } else if ($(currentFocus).is('li')) {
                var _state = 'closed'
                if ($(currentFocus).attr('data-readonly') === undefined) {
                  input.prop('readonly', false)
                } else {
                  input.prop('readonly', true)
                }

                if ($(currentFocus).attr('placeholder-option') === undefined) {
                  if (csSelector.closest('.js-state-select').length === 1) {
                    setDefaultValue(input, selectedText)
                  } else {
                    let selectedVal = $(currentFocus).text()
                    setDefaultValue(input, selectedVal)
                  }
                } else {
                  let selectedVal = $(currentFocus)[0].innerText
                  setPlaceholder(input, selectedVal)
                  csSelector.addClass('editable')
                  _state = 'create'
                }
                makeChoice(
                  currentSelector,
                  input,
                  options,
                  arrOptions,
                  currentFocus
                )
                toggleList(currentSelector, list, 'Shut')
                setState(_state)
              }
              break
            case 'filtered':
              if ($(currentFocus).is('li')) {
                var _fState = 'closed'
                if ($(currentFocus).attr('data-readonly') === undefined) {
                  input.prop('readonly', false)
                } else {
                  input.prop('readonly', true)
                }
                if ($(currentFocus).attr('placeholder-option') === undefined) {
                  if (csSelector.closest('.js-state-select').length === 1) {
                    setDefaultValue(input, selectedText)
                  } else {
                    let selectedVal = $(currentFocus).text()
                    setDefaultValue(input, selectedVal)
                  }
                } else {
                  let selectedVal = $(currentFocus)[0].innerText
                  setPlaceholder(input, selectedVal)
                  csSelector.addClass('editable')
                  _fState = 'create'
                }
                makeChoice(
                  currentSelector,
                  input,
                  options,
                  arrOptions,
                  currentFocus
                )
                toggleList(currentSelector, list, 'Shut')
                setState(_fState)
              }
              break
            case 'closed':
              toggleList(currentSelector, list, 'Open')
              setState('filtered')
              break
            case 'create':
              toggleList(currentSelector, list, 'Shut')
              setState('initial')
              break
          }
        }
      })

      csSelector.on('click', '.ca-select-cancel', function () {
        const selected = csSelector.find('[data-default-option]')
        const selectedText = selected.text()
        setDefaultValue(csInput, selectedText)
        csOptions.show()
        csSelector.removeClass('editable')
      })

      csSelector.on('keyup', function (e) {
        const selector = $(this)
        if (csInput.attr('readonly') === undefined) {
          doKeyAction(selector, e.key)
        }
      })

      $(document).on('click', function (e) {
        if (!e.target.closest('.js-select-input')) {
          $('.js-select').each(function () {
            const input = $(this).find('input')
            if (input.val() === '') {
              const selected = $(this).find('[selected]')
              if (selected.length > 0) {
                const selectedText = selected.text()
                setDefaultValue(input, selectedText)
              } else {
                const placeholderVal = input.data('placeholder')
                if (placeholderVal !== undefined && placeholderVal.length > 0) {
                  setPlaceholder(input, placeholderVal)
                }
              }
            }
          })
          // click outside of the custom group
          toggleList(csSelector, csList, 'Shut')
          setState('initial')
        }
      })

      let $parentModal = csSelector.closest('.modal__main')
      if ($parentModal) {
        $parentModal.on('click', function (e) {
          if (!e.target.closest('.js-select')) {
            toggleList(csSelector, csList, 'Shut')
            setState('initial')
          }
        })
      }
    }
  })
}

// FUNCTIONS
// /////////////////////////////////
function setPlaceholder (input, value) {
  input.attr('placeholder', value).val('')
}

function removePlaceholder (input) {
  if (input.attr('placeholder')) {
    input.removeAttr('placeholder')
  }
}

function setDefaultValue (input, value) {
  removePlaceholder(input)
  input.val(value)
}

function setInitialSubjectInput (input, options) {
  const optionMap = options.map(option => {
    const subjectDisplayName = $(options[option]).attr('data-display-name')
    if (subjectDisplayName === 'Math' || subjectDisplayName === 'Ready') {
      return { subjectKey: 'M', subjectDisplayName }
    }
    if (subjectDisplayName === 'Writing') {
      return { subjectKey: 'W', subjectDisplayName }
    }
    if (subjectDisplayName === 'Reading' || subjectDisplayName === 'ELA') {
      return { subjectKey: 'R', subjectDisplayName }
    }
    if (subjectDisplayName === 'Science') {
      return { subjectKey: 'S', subjectDisplayName }
    }
  })

  const contentPageRegex = /(.*\/toolbox\/)([M,R,W,S])\.([K,1-8])(\/.*)/

  const currentPath = window.location.pathname
  const matches = currentPath.match(contentPageRegex)

  const currentSubjectKey = matches[2]

  let selectedSubjectDisplayName

  optionMap.each(option => {
    if (optionMap[option].subjectKey === currentSubjectKey) {
      selectedSubjectDisplayName = optionMap[option].subjectDisplayName
    }
  })

  selectedSubjectDisplayName && input.val(selectedSubjectDisplayName)
}

function setSubjectInput (input, optionTitle) {
  let currentPath = window.location.pathname
  currentPath = currentPath.replace(/~lessonID=(.)+~/g, '')
  currentPath = currentPath.replace(/~sp~/g, '')

  const subjectPath = $('.js-subject-select input').attr('data-value')

  input.val(optionTitle)

  if (subjectPath !== currentPath) {
    window.location.pathname = subjectPath
  }
}

function setStateInput (input, inputVal) {
  const selected = input.closest('.js-select').find('[selected]')
  const selectedText = selected.text()

  input.val(selectedText)

  if (inputVal) {
    let currentUrl = window.location.href
      .replace(/~lessonID=(.)+~/g, '')
      .replace(/~sp~/g, '')

    const contentPageRegex = /(.*\/toolbox\/)([M,R,W,S])\.([K,1-8])(\/.*)/

    const currentPath = window.location.pathname
      .replace(/~lessonID=(.)+~/g, '')
      .replace(/~sp~/g, '')

    const matches = currentPath.match(contentPageRegex)
    const currentSubjectKey = matches[2]
    const currentGradeKey = matches[3]

    const stateSubjects = input.closest('.js-select').find('[data-state="' + inputVal + '"]').attr('data-value')

    if (stateSubjects.indexOf(currentSubjectKey) < 0) {
      currentUrl = currentUrl.replace(
        contentPageRegex,
        '$1' + stateSubjects[0] + '.$3$4'
      )
    }

    if (currentUrl.indexOf('#') > -1) {
      currentUrl = currentUrl.split('#')[0]
    }

    let selectedBook = window.userJSON.books.find(book => book.stateName === inputVal)
    if (selectedBook !== undefined) {
      let selectedSubject = selectedBook.subjectsWithGrades.find(subject => subject.key === matches[2])
      if (selectedSubject === undefined) {
        selectedSubject = selectedBook.subjectsWithGrades[0]
        matches[2] = selectedSubject.key
      }

      matches[3] = (selectedSubject.grades.indexOf(matches[3]) !== -1) ? matches[3] : selectedSubject.grades[0]
    }

    let pageName = matches[3].replace(/~lessonID=(.)+~/g, '').replace(/~sp~/g, '')
    currentUrl = `${matches[1]}${matches[2]}.${pageName}${matches[4]}`

    if (currentUrl.indexOf('state=') > -1) {
      const regex = /state=([^&]+)/g
      currentUrl = currentUrl.replace(regex, 'state=' + inputVal)
    } else {
      currentUrl += currentUrl.indexOf('?') > -1 ? '&' : '?'
      currentUrl += 'state=' + inputVal
    }

    window.location = currentUrl
  }
}

function toggleList (selector, list, toggle) {
  if (toggle === 'Open') {
    list.removeClass('hidden-all')
    selector.attr('aria-expanded', 'true')
  } else {
    list.addClass('hidden-all')
    selector.attr('aria-expanded', 'false')
  }
}

function findFocus () {
  const focusPoint = document.activeElement
  return focusPoint
}

function moveFocus (input, options, arrOptions, currFocus, newFocus) {
  // grab the currently showing options, which might have been filtered
  const currentOptions = arrOptions.filter(function (option) {
    if (option.style.display === '') {
      return true
    }
  })

  // don't move if all options have been filtered out
  if (currentOptions.length === 0) {
    return
  }

  if (newFocus === 'input') {
    input.focus()
  }

  // possible start points
  switch (currFocus) {
    case input:
      if (newFocus === 'forward') {
        currentOptions[0].focus()
      } else if (newFocus === 'back') {
        currentOptions[currentOptions.length - 1].focus()
      }
      break
    case options[0]:
      if (newFocus === 'forward') {
        currentOptions[1].focus()
      } else if (newFocus === 'back') {
        input.focus()
      }
      break
    case options[options.length - 1]:
      if (newFocus === 'forward') {
        currentOptions[0].focus()
      } else if (newFocus === 'back') {
        currentOptions[currentOptions.length - 2].focus()
      }
      break
    default:
      // middle list or filtered items
      const currentItem = findFocus()
      const whichOne = currentOptions.indexOf(currentItem)
      if (newFocus === 'forward') {
        const nextOne = currentOptions[whichOne + 1]
        nextOne.focus()
      } else if (newFocus === 'back' && whichOne > 0) {
        const previousOne = currentOptions[whichOne - 1]
        previousOne.focus()
      } else {
        // if whichOne = 0
        input.focus()
      }
      break
  }
}

function doFilter (input, status, arrOptions) {
  const terms = input.val()

  const arrFilteredOptions = arrOptions.filter(function (option) {
    if (option.innerText.toLowerCase().includes(terms.toLowerCase())) {
      return true
    }
  })

  arrOptions.forEach(option => {
    $(option).hide()
  })

  arrFilteredOptions.forEach(function (option) {
    $(option).show()
  })

  setState('filtered')
  updateStatus(status, arrFilteredOptions.length)
}

function updateStatus (status, howMany) {
  status.textContent = howMany + ' options available.'
}

function makeChoice (selector, input, options, arrOptions, choice) {
  const optionTitle = $(choice).attr('data-display-name')
  let url = $(choice).attr('data-value')
  const hasStateAttr = $(choice).attr('data-state')

  if (hasStateAttr) {
    url = $(choice).attr('data-state')
  }

  input.value = optionTitle
  input.attr('data-value', url)

  if (input.hasClass('set-value')) {
    input.val(optionTitle)
    input.trigger('input')
  }

  moveFocus(input, options, arrOptions, document.activeElement, 'input')

  if (selector.find('#subject-select').length > 0) {
    setSubjectInput(input, optionTitle)
  } else if (selector.find('#state-select').length > 0) {
    setStateInput(input, url)
  }
}

function setState (newState) {
  switch (newState) {
    case 'initial':
      csState = 'initial'
      break
    case 'opened':
      csState = 'opened'
      break
    case 'filtered':
      csState = 'filtered'
      break
    case 'closed':
      csState = 'closed'
      break
    case 'create':
      csState = 'create'
      break
  }
}

function doKeyAction (currentSelector, whichKey) {
  const currentFocus = findFocus()
  const list = currentSelector.find('ul')
  const input = currentSelector.find('input')
  const options = list.find('li')
  const arrOptions = Array.from(options)
  const status = currentSelector.closest('.js-select-status')

  switch (whichKey) {
    case 'Enter':
      if (csState === 'initial') {
        // if state = initial, toggleOpen and set state to opened
        toggleList(currentSelector, list, 'Open')
        setState('opened')
      } else if (csState === 'opened' && $(currentFocus).is('li')) {
        makeChoice(currentSelector, input, options, arrOptions, currentFocus)
        toggleList(currentSelector, list, 'Shut')
        setState('closed')
      } else if (csState === 'opened' && $(currentFocus).is('input')) {
        toggleList(currentSelector, list, 'Shut')
        setState('closed')
      } else if (csState === 'filtered' && $(currentFocus).is('li')) {
        makeChoice(currentSelector, input, options, arrOptions, currentFocus)
        toggleList(currentSelector, list, 'Shut')
        setState('closed')
      } else if (csState === 'filtered' && $(currentFocus).is('input')) {
        toggleList(currentSelector, list, 'Open')
        setState('opened')
      } else {
        toggleList(currentSelector, list, 'Open')
        setState('filtered')
      }
      break

    case 'Escape':
      if (csState === 'opened' || csState === 'filtered') {
        toggleList(currentSelector, list, 'Shut')
        setState('initial')
      }
      break

    case 'ArrowDown':
      if (csState === 'initial' || csState === 'closed') {
        toggleList(currentSelector, list, 'Open')
        moveFocus(input, options, arrOptions, input, 'forward')
        setState('opened')
      } else {
        toggleList(currentSelector, list, 'Open')
        moveFocus(input, options, arrOptions, currentFocus, 'forward')
      }
      break
    case 'ArrowUp':
      if (csState === 'initial' || csState === 'closed') {
        toggleList(currentSelector, list, 'Open')
        moveFocus(input, options, arrOptions, input, 'back')
        setState('opened')
      } else {
        moveFocus(input, options, arrOptions, currentFocus, 'back')
      }
      break
    default:
      if (csState === 'initial') {
        toggleList(currentSelector, list, 'Open')
        doFilter(input, status, arrOptions)
        setState('filtered')
      } else if (csState === 'opened') {
        doFilter(input, status, arrOptions)
        setState('filtered')
      } else if (csState === 'closed') {
        doFilter(input, status, arrOptions)
        setState('filtered')
      } else if (csState !== 'create') {
        doFilter(input, status, arrOptions)
      }
      break
  }
}

export default {
  init
}
