// @flow
import { Section } from '../variations/classroom/types/Section.flow'
import { Asset } from '../variations/classroom/types/Asset.flow'
import { get, set } from 'lodash'
import { isAssetPrerequisiteForSection } from '../get/getAsset'
import { convertAssetIntoPrerequisite } from '../convert/convertAsset'

/// ///////
// Section
/// ////

export const addAssetToSection = (section: Section, asset: Asset) => {
  const assets = get(section, ['assets'], [])
  let assetToAdd = asset

  if (isAssetPrerequisiteForSection(asset, section.id)) {
    assetToAdd = convertAssetIntoPrerequisite(asset, section.id)
  }

  return set(section, 'assets', [...assets, assetToAdd])
}
