import {
  showSchoolReselectSection,
  showMainSection,
  showResultsSection,
  updateSchoolInformationSection
} from './ProfileModalPresenters'
import { SELECTORS } from './ProfileModalConstants'
import $ from 'jquery'
import { fetchSchools, getSchoolSearchFormData } from '../../CommonFetchers'
import { renderSearchResults } from '../../../registration/RegistrationRenderers'
import { getElement, getElements } from '../../get/getElement'
import {
  updateChecked,
  disableButton,
  isASchoolSelected,
  updateSchoolInputFormPlaceholderText,
  setSchoolResultElementAsUnchecked
} from '../../../registration/RegistrationPresenters'
import {
  validateSchoolResults,
  validateSchoolSearch
} from './ProfileModalValidators'
import { submitProfileForm, hideFormFieldError } from '../../common'
import {
  getSelectedSchoolInfo,
  getControlsForField,
  getSelectedSchool
} from '../../../registration/RegistrationGetters'
import reduceConcat from '../../reduce/reduceConcat'
import { getSchoolSearchFields } from './ProfileModalGetters'
import { SchoolStatus } from './ProfileModalSingletons'
import CauliflowerModal from '../../../CauliflowerModal'

export const addSchoolSelectListener = () => {
  $(SELECTORS.profileModal).on('click', SELECTORS.schoolResult, e => {
    updateChecked(e)
    validateSchoolResults()
  })
}

export const addProfileModalFormListeners = () => {
  $(SELECTORS.profileModalMainForm).submit(submitProfileForm)
  $(SELECTORS.profileModalMainForm).on(
    'keyup',
    '.form__field',
    hideFormFieldError
  )
  $(SELECTORS.profileModalSearchForm).submit(submitSearchForm)

  // We want to listen to when the profile modal is closed, but that's handled inside Cauliflower Modal.
  // Might be good to export an event to listen to from Cauliflower Modal eventually.
  // TODO: Add event for modal close that other components can listen to.
  CauliflowerModal.addOptionsToModal('profile', {
    onClose: onProfileModalClose
  })
}

const submitSearchForm = e => {
  if (e) e.preventDefault()
  const formData = getSchoolSearchFormData()
  fetchSchools(formData).then(schools => {
    const schoolResultsElement = getElement(SELECTORS.schoolReselectResults)

    schoolResultsElement.innerHTML = renderSearchResults({
      schools,
      formData
    })

    // FIXME: Find a better way to set tooltip position inside renderSearchResults
    getElements(SELECTORS.tooltips).map(
      tooltip => tooltip.classList.add('tooltip--is-right') // We don't want anything to hang off the left side of the modal -- it'd get overflow:hidden
    )

    disableButton(getElement(SELECTORS.schoolReselectSelectButton))
    showResultsSection()
  })
}

const selectSchool = () => {
  if (isASchoolSelected()) {
    const school = getSelectedSchoolInfo()
    const { id, region, name, stateOrProvince } = school
    const selectedSchool = {
      regionName: region,
      schoolId: id,
      schoolName: name,
      stateOrProvince
    }

    updateSchoolInformationSection(selectedSchool)
    SchoolStatus.updateSchool(selectedSchool)

    // TODO: Prevent modal from closing after school is updated
    // CauliflowerModal.preventClose()
    showMainSection()
  }
}

export const addSchoolReselectButtonListeners = () => {
  const { profileModalButtons } = SELECTORS
  $(SELECTORS.profileModal).on('click', profileModalButtons, e => {
    if (!e.target.dataset.action) return true
    const action = e.target.dataset.action
    switch (action) {
      case 'show-search':
        return showSchoolReselectSection(e)
      case 'show-main':
        return showMainSection(e)
      case 'show-results':
        return showResultsSection(e)
      case 'search':
        submitSearchForm(e)
        return
      case 'submit-profile-modal-form':
        submitProfileForm(e)
        return
      case 'select':
        selectSchool(e)
    }
  })
}

export const onProfileModalClose = e => {
  hideFormFieldError()

  if (isASchoolSelected()) {
    const selectedElement = getSelectedSchool()
    setSchoolResultElementAsUnchecked(selectedElement)
  }

  showMainSection(e)
}

const onSchoolSelectKeyup = () => {
  validateSchoolSearch()
}

const onSchoolSelectChange = () => {
  validateSchoolSearch()
  updateSchoolInputFormPlaceholderText() // This is only necessary for "onChange" since the school mode is only handled by radio buttons.
}

export const addSchoolSearchValidationListeners = () => {
  validateSchoolSearch() // Validate first, this will disable all the fields that should be disabled
  const controls = getSchoolSearchFields()
    .map(getControlsForField)
    .reduce(reduceConcat)

  controls.forEach(control => {
    control.addEventListener('keyup', onSchoolSelectKeyup)
    control.addEventListener('change', onSchoolSelectChange)
  })
}
