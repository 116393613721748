import $ from 'jquery'

const collapseBanner = data => {
  let $headerBannerToggle = $('#header-banner__toggle')
  let collapsed = $headerBannerToggle.is(':checked')
  let currentState = data.currentstate
  let displayName = data.displayname.replace('\\s+', '')
  let sessionKey = 'TT__Banner-Toggle__' + currentState + '__' + displayName + '__banner-collapsed'
  sessionStorage.setItem(sessionKey, collapsed)
}

export default collapseBanner
