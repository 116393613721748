export const CORE_MIN_VERSIONS = {
  'Mac OS X': '10.6',
  Windows: '5.1',
  'Chrome OS': '0.0',
  Ubuntu: '0.0',
  Chrome: '34.0',
  Firefox: '29.0',
  'Internet Explorer': '9.0',
  Safari: '6.1',
  'Safari.iPad': '6.1',
  Flash: '10.2.0'
}

export const SORT_AUDIENCES = [undefined, null, '', 'not-applicable', 'student', 'teacher']
export const SORT_GRADES = ['K', '1', '2', '3', '4', '5', '6', '7', '8']
