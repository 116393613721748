import { getAttributeFromWindow } from '../common/get/getAttribute'
import { get } from 'lodash'
import { SchoolSearchRequest } from '../registration/RegistrationTypes'
import { getFieldValue } from '../registration/RegistrationGetters'
import $ from 'jquery'
import { populateProfile } from './common'

const __objectToParams = (object: Object = {}) => {
  return Object.keys(object)
    .map(key => {
      const value = encodeURIComponent(get(object, key, ''))
      return [key, value].join('=')
    })
    .join('&')
}

const __buildUrl = path => {
  return getAttributeFromWindow('CONTEXT') + `${path}`
}

const __buildSchoolSearchUrl = (request: SchoolSearchRequest) => {
  const params = __objectToParams(request)
  return __buildUrl(`/api/v1/school`) + '?' + params
}

export const getSchoolSearchFormData = () => {
  const query = getFieldValue('schoolInput')
  const searchType = getFieldValue('schoolMode')
  const state = getFieldValue('state')

  return {
    searchType,
    query,
    state
  }
}

export const getPopulateProfile = () => {
  const CONTEXT = getAttributeFromWindow('CONTEXT')

  return $.ajax({
    url: CONTEXT + '/api/v1/user/populateProfile',
    method: 'GET',
    cache: false,
    success: populateProfile
  })
}

export const fetchSchools = (request: SchoolSearchRequest) => {
  const url = __buildSchoolSearchUrl(request)

  return fetch(url, {
    headers: {
      Accept: 'application/json'
    }
  }).then(res => res.json())
}

export const fetchAssets = (url: String) => {
  return fetch(url, {
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json'
    }
  }).then(res => res.json())
}
