import $ from 'jquery'

const copyLink = ({ assetId, lessonId }) => {
  const buttonSelector = `[data-action='copy-link'][data-asset-id='${assetId}'][data-lesson-id='${lessonId}']`
  let href = window.location.href.replace(window.location.hash, '')
  const state = window.CURRENT_STATE
  navigator.clipboard.writeText(`${href}?state=${state}&lessonId=${lessonId}&assetId=${assetId}`)

  $(buttonSelector).closest('.modal__copy-link').addClass('is-copied')

  setTimeout(() => {
    $(buttonSelector).closest('.modal__copy-link').removeClass('is-copied')
  }, 5000)
}

export default copyLink
