import $ from 'jquery'
import Form from '../Form'
import NotificationBanner from '../NotificationBanner'
import CauliflowerModal from '../CauliflowerModal'
import QuickModal from '../QuickModal'
import { getElements } from './get/getElement'
import Doctor from './doctors/Doctor'
import { getSection } from './get/getSection'
import { postUpdateProfile } from './modals/profile/ProfileModalFetchers'
import { getSelectedSchoolInfo } from '../registration/RegistrationGetters'
import { isASchoolSelected } from '../registration/RegistrationPresenters'
import { updateSchoolInformationSection } from './modals/profile/ProfileModalPresenters'
import initializeProfileModal from './modals/profile/ProfileModalInitializer'
import { SchoolStatus } from './modals/profile/ProfileModalSingletons'
import { getLandingPageUrl, getUserInstitution } from './CommonGetters'
import { openProfileModalWithData } from './CommonPresenters'

export const ASSET_TYPES = [
  'as',
  'assmt',
  'ca',
  'fluency',
  'iwl',
  'ppt',
  'quizzes',
  'rib',
  'tfi',
  'prereq',
  'tool'
]

export var showSection = function (name) {
  hideError()
  $('section').hide()
  // TODO: Fix this line below so we aren't hiding/showing this needlessly
  $('.modal__content section').show()
  $('section.' + name).show(0, function () {
    $('section.' + name)
      .find('.form__field:visible input')
      .first()
      .focus()
  })
  if (
    (name === 'landing' || name === 'addCode') &&
    window.userJSON.profileEnabled === true
  ) {
    $('#profile-btn, #logout-btn').show()
  }

  if (name === 'almost' || name === 'forgot') {
    $('section.' + name).closest('.panel').addClass('padded-container')
  } else {
    $('section.' + name).closest('.panel').removeClass('padded-container')
  }

  // Allow for hiding/showing specific elements based on what page we're navigating to
  $('[data-only-show-on]').hide()
  $(`[data-hide-on~="${name}"]`).hide()
  $(`[data-only-show-on~="${name}"]`).show()

  $('.form__field input')
    .first()
    .focus()
}

export var hideError = function () {
  $('.error, .message')
    .hide()
    .empty()
}

export var hideFormFieldError = function () {
  $('.form__field').each(function () {
    if ($(this).hasClass('has-error')) {
      $(this).removeClass('has-error')
    }
  })
}

export var isValidName = function (name) {
  if (name.length < 1) {
    return false
  } else if (name.match(/[^\w \-']/gm)) {
    return false
  }

  return true
}

export var isEmail = function (email) {
  if (email !== email.replace(/ /g, '')) {
    // Spaces are not allowed
    return false
  } else {
    var atArr = email.split('@')
    if (atArr.length !== 2) {
      // Email must be divided into exactly 2 substrings by 1 @ symbol.
      return false
    } else if (arrHasEmptyString(atArr)) {
      // @ substrings must not be empty strings
      return false
    } else {
      var dotArr = atArr[1].split('.')
      if (dotArr.length < 2) {
        // There must be 2 or more dot substrings.
        return false
      } else {
        // dot substrings must not be empty strings
        if (arrHasEmptyString(dotArr)) {
          return false
        }
      }
    }
  }

  return true
}

export var arrHasEmptyString = function (arr) {
  var arrHasText = arr.filter(function (element) {
    return element.length > 0
  })

  if (arr.length !== arrHasText.length) {
    return true
  }

  return false
}

export var getParameterByName = function (name) {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  var results = regex.exec(location.search)

  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export var formAppendArray = function (formObj, formPropName, arr) {
  var count = 0
  arr.forEach(function (node) {
    formObj[formPropName + '[' + count + ']'] = node
    count++
  })
}

export var getListOfValues = function (selector) {
  var arr = $(selector).map(function () {
    return $(this).val()
  })

  return arr.get()
}

export var formToObject = function (selector) {
  var serialArr = $(selector).serializeArray()
  var obj = {}
  for (var i = 0; i < serialArr.length; i++) {
    if (serialArr[i].name === '__proto__') {
      continue
    }
    obj[serialArr[i].name] = serialArr[i].value
  }

  return obj
}

export var populateProfile = function (data) {
  const { firstName, lastName, username } = data
  let school = data.school || getUserInstitution()
  const {
    stateOrProvince = '',
    schoolId = '',
    name: schoolName = '',
    parentAccountIdName: regionName = ''
  } = school

  $('#profile form.profile input[name="user.firstName"]').val(firstName)
  $('#profile form.profile input[name="user.lastName"]').val(lastName)
  $('#profile form.profile input[name="user.emailAddress"]').val(username)

  updateSchoolInformationSection({
    schoolId,
    schoolName,
    regionName,
    stateOrProvince
  })

  data.roles.forEach(function (obj) {
    $('#profile form.profile .roles button[value="' + obj.name + '"]').addClass(
      'selected'
    )
  })

  data.grades.forEach(function (obj) {
    $(
      '#profile form.profile .grades button[value="' + obj.name + '"]'
    ).addClass('selected')
  })
}

export const getProfileFormData = () => {
  var formData = formToObject('#profile form.profile')

  if (isASchoolSelected()) {
    const schoolData = getSelectedSchoolInfo()
    formData['user.schoolId'] = schoolData.id
  }

  formAppendArray(
    formData,
    'roles',
    getListOfValues('#profile form.profile  .roles button.selected')
  )
  formAppendArray(
    formData,
    'grades',
    getListOfValues('#profile form.profile  .grades button.selected')
  )

  return formData
}

export var submitProfileForm = function (e) {
  e.preventDefault()
  const onSuccess = data => {
    if (data.success) {
      var event = $.Event('profileUpdated')

      event.firstName = $(
        '#profile form.profile input[name="user.firstName"]'
      ).val()

      $('body').trigger(event)

      const schoolStatus = SchoolStatus.getSchoolStatus()

      if (schoolStatus.isDirty) {
        window.location.href = getLandingPageUrl()
      }

      NotificationBanner.open('Account Updated')
      CauliflowerModal.close()
    } else {
      console.log('did not receive success on register')
    }
  }

  const onError = data => {
    console.log(data)

    var response = JSON.parse(data.responseText)
    var exceptionType = response.exceptionType

    showProfileError(response.message, exceptionType)
  }

  if (isProfileModalValid()) {
    const formData = getProfileFormData()
    postUpdateProfile({ formData, onSuccess, onError })
  }
}

export var isProfileModalValid = function () {
  hideError()
  hideFormFieldError()

  Form.hideValidationForForm('profile')
  if (
    !isValidName($('#profile form.profile input[name="user.firstName"]').val())
  ) {
    Form.showErrorForField(
      'profile',
      'first-name',
      'Please enter your first name.'
    )
    return false
  }
  if (
    !isValidName($('#profile form.profile input[name="user.lastName"]').val())
  ) {
    Form.showErrorForField(
      'profile',
      'last-name',
      'Please enter your last name.'
    )
    return false
  }
  if (
    !isEmail($('#profile form.profile input[name="user.emailAddress"]').val())
  ) {
    Form.showErrorForField(
      'profile',
      'email-address',
      'Please enter a valid email address.'
    )
    return false
  }
  if (
    $('#profile form.profile input[name="currentPassword"]').val().length > 0 &&
    $('#profile form.profile input[name="user.password"]').val().length > 0
  ) {
    if (
      $('#profile form.profile input[name="user.password"]').val().length < 8
    ) {
      Form.showErrorForField(
        'profile',
        'new-password',
        'Your password must be at least 8 characters.'
      )
      return
    }
    if (
      $('#profile form.profile input[name="user.password"]').val() !==
      $('#profile form.profile input[name="user.confirmPassword"]').val()
    ) {
      Form.showErrorForField('profile', 'new-password', '')
      Form.showErrorForField(
        'profile',
        'confirm-password',
        'Your passwords don’t match. Please try again.'
      )

      return false
    }
  }
  if ($('#profile form.profile .roles').find('.selected').length < 1) {
    Form.showErrorForField(
      'profile',
      'roles',
      'Please select at least one role.'
    )
    return false
  }
  if ($('#profile form.profile .grades').find('.selected').length < 1) {
    Form.showErrorForField(
      'profile',
      'grades',
      'Please select at least one grade.'
    )
    return false
  }

  return true
}

export var showProfileError = function (error, exceptionType) {
  if (
    exceptionType ===
    'com.cainc.service.UserService.DuplicateEmailAddressException'
  ) {
    Form.showErrorForField('profile', 'email-address', error)
  } else if (
    exceptionType === 'com.cainc.service.UserService.PasswordMismatchException'
  ) {
    Form.showErrorForField('profile', 'current-password', error)
  } else {
    Form.showErrorForForm('profile', error)
  }
}

export const isRCMLink = (href: string = document.location) => {
  const { searchParams } = new URL(href)

  for (const param of searchParams.entries()) {
    const [key, value] = param
    if (key === 'isRCM' && value === 'true') return true
  }

  const USER_JSON = window.userJSON || {}
  return USER_JSON.userType === 'IREADY'
}

export const hideTopBar = () => {
  const bars = getElements('[data-top-bar]')
  bars.map(bar => bar.classList.add('top-bar--is-hidden'))
}

export const updateProfileName = e => {
  $('.profile-text .name').text(e.firstName)
}

export var populateGlobalAssetData = function (assets) {
  for (var i = 0; i < assets.length; i++) {
    const asset = assets[i]
    window.ASSETS[asset.id] = asset
  }

  window.ASSET_ARRAY = assets
}

export var init = function () {
  QuickModal.init()

  window.__createDoctor = assets => new Doctor(assets)
  window.__createDoctorFromSection = sectionId =>
    window.__createDoctor(getSection(sectionId).assets)

  var $body = $('body')
  var $profileModal = $('.modal__main.profile')

  $body.on('profileUpdated', updateProfileName)

  $body.on('click', 'button.multiple', function (e) {
    e.preventDefault()
    $(e.target).addClass('selected')
  })

  $body.on('click', 'button.selected', function (e) {
    e.preventDefault()
    $(e.target).removeClass('selected')
  })

  $profileModal.on('click', 'button.multiple', function (e) {
    // For some reason, $('body') is not inclusive of button.multiple in the profile modal.
    e.preventDefault()
    $(e.target).toggleClass('selected')
  })

  $body.on('click', 'button.selectable', function (e) {
    e.preventDefault()
    $(e.target)
      .parent()
      .children()
      .removeClass('selected')
    $(e.target).addClass('selected')
  })

  $body.on('click', '#profile-btn', function (e) {
    e.preventDefault()
    openProfileModalWithData()
  })

  //
  $body.on('click', '[data-action="open-profile-modal"]', function (e) {
    e.preventDefault()
    openProfileModalWithData()
  })

  $body.on('change', '.js-state-select select', function () {
    var val = $(this).val()

    var currentUrl = window.location.href
    currentUrl = currentUrl.replace(/~lessonID=(.)+~/g, '')
    currentUrl = currentUrl.replace(/~sp~/g, '')

    var contentPageRegex = /(.*\/toolbox\/)([M,R,W,S])\.([K,1-8])(\/.*)/

    var currentPath = window.location.pathname
    var matches = currentPath.match(contentPageRegex)

    // var currentPrefix = matches[1]
    var currentSubjectKey = matches[2]
    // var currentGrade = matches[3]

    var stateSubjects = $(this)
      .children('option:selected')
      .data('subjects')
      .split(',')

    if (stateSubjects.indexOf(currentSubjectKey) < 0) {
      currentUrl = currentUrl.replace(
        contentPageRegex,
        '$1' + stateSubjects[0] + '.$3$4'
      )
    }

    if (currentUrl.indexOf('state=') > -1) {
      var regex = /state=([^&]+)/g
      currentUrl = currentUrl.replace(regex, 'state=' + val)
    } else {
      currentUrl += currentUrl.indexOf('?') > -1 ? '&' : '?'
      currentUrl += 'state=' + val
    }

    window.location = currentUrl
  })

  $body.on('change', '.js-subject-select select', function () {
    window.location = $(this).val()
  })

  $body.on(
    'click',
    "#support-btn,.support-btn, [data-action='open-support-modal']",
    function (e) {
      e.preventDefault()
      CauliflowerModal.open('support', 'large')
    }
  )

  $('input[type="email"], input[type="password"]').on({
    keydown: function (e) {
      if (e.which === 32) return false
    },
    change: function () {
      this.value = this.value.replace(/\s/g, '')
    }
  })

  $body.on(
    'click',
    "#profile form.profile [data-action='submit']",
    submitProfileForm
  )
  $body.on('submit', '#profile form.profile', submitProfileForm)

  initializeProfileModal()
}

/**
 * Prevents execution of {callback} from occurring more than once every {delay} microseconds
 * @param {Function} callback
 * @param {Number} delay
 */
export const throttleCallback = (callback, limit) => {
  var shouldThrottle
  return function () {
    var args = arguments
    var that = this

    if (!shouldThrottle) {
      shouldThrottle = true
      let timeout = setTimeout(() => {
        shouldThrottle = false
        clearTimeout(timeout)
      }, limit)
      return callback.call(that, ...args)
    }
  }
}

export const resetPasswordMessage = (email) => `Check ${email} for an email to reset your password. You will only receive an email if there is an associated Resource Library account.`

export default {
  init,
  populateGlobalAssetData
}
