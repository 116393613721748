import CauliflowerModal from '../CauliflowerModal'
import { getAttributeFromWindow } from './get/getAttribute'
import $ from 'jquery'
import { populateProfile } from './common'

export const setElementToDisplayBlock = (element: HTMLElement) => {
  return element.setAttribute('style', 'display: block;')
}

export const openProfileModalWithData = () => {
  const CONTEXT = getAttributeFromWindow('CONTEXT')
  CauliflowerModal.open('profile')

  $.ajax({
    url: CONTEXT + '/api/v1/user/populateProfile',
    method: 'GET',
    cache: false,
    success: populateProfile
  })
}
