// @flow
import {
  SectionCollection,
  SectionId
} from '../variations/classroom/types/Section.flow'
import _, { get } from 'lodash'
import { cleanAssetData } from '../toolbox'
import { addAssetToSection } from '../set/setSection'
import {
  getAllAssetsAsArray,
  getAssociatedIdsFromAsset,
  getPrerequisitesFromAsset
} from './getAsset'

export const getAllSections = _.memoize(
  (id: SectionId): SectionCollection => {
    const assets = cleanAssetData(getAllAssetsAsArray(), id)
    return assets.reduce(reduceAssetsToSectionCollection, {})
  }
)

export const reduceAssetsToSectionCollection = (acc, asset) => {
  const associatedSectionIds = getAssociatedIdsFromAsset(asset)
  const prerequisiteIds = getPrerequisitesFromAsset(asset)

  // QUESTION: What should we do if an asset is associated with a lesson it is also a prereq for?
  const ids = _.uniq([].concat(associatedSectionIds, prerequisiteIds)) // Assets should only appear in a section once -- if it happens to be a prereq and associated

  ids.forEach(id => {
    if (!acc[id]) acc[id] = { id, assets: [] }
    const section = acc[id]

    acc[id] = addAssetToSection(section, asset)
  })

  return acc
}

export const getSection = (id: SectionId): SectionCollection => {
  const base = { id, assets: [] }
  return Object.assign(base, get(getAllSections(id), id, null))
}
