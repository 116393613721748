import $ from 'jquery'
/// /////////////////
// Forms
/// /////////////

// Selecting Form Elements

var getForm = function (formId) {
  return getFormSection(formId).find('form.form')
}

var getFormError = function (formId) {
  var $form = getForm(formId)
  var $error = $form.find('> .alert.alert-error')
  if ($error.length === 0) {
    $error = $('<div>', { class: '.alert.alert-error' })
    $form.insertBefore('.form__footer')
  }

  return $error
}

var getFormSuccess = function (formId) {
  var $form = getForm(formId)
  var $success = $form.find('> .alert.alert-success')
  if ($success.length === 0) {
    $success = $('<div>', { class: 'alert alert-success' })
    $form.find('.form__footer').before($success)
  }

  return $success
}

var getFormSection = function (formId) {
  return $('#' + formId)
}

// Show/Hide Form Validation
var showForForm = function (type, formId, validationText) {
  var $form = getForm(formId)
  var isTypeSuccess = type === 'success'
  var getValidationForForm = isTypeSuccess ? getFormSuccess : getFormError
  var $validation = getValidationForForm(formId)

  $validation.html(validationText)
  $form.removeClass('has-error has-success')
  $form.addClass(isTypeSuccess ? 'has-success' : 'has-error')
  return $form
}

var showErrorForForm = function (formId, errorText) {
  showForForm('error', formId, errorText)
}

var showSuccessForForm = function (formId, successText) {
  showForForm('success', formId, successText)
}

var hideValidationForForm = function (formId) {
  hideErrorForForm(formId)
  hideSuccessForForm(formId)
}

var hideErrorForForm = function (formId) {
  var $form = getForm(formId)
  var $fields = $form.find('.form__field')

  $form.removeClass('has-error')
  $fields.removeClass('has-error')
}

var hideSuccessForForm = function (formId) {
  var $form = getForm(formId)
  var $fields = $form.find('.form__field')

  $form.removeClass('has-success')
  $fields.removeClass('has-success')
}

/// /////////////////
// Fields
/// /////////////

// Selecting Field Elements
var getField = function (formId, fieldClass) {
  return getFormSection(formId).find('.form__field.' + fieldClass)
}

var getFieldError = function (formId, fieldClass) {
  var $field = getField(formId, fieldClass)
  var $error = $field.find('.form__field__error')

  if ($error.length === 0) {
    $error = $('<div>', { class: 'form__field__error' })
    $field.append($error)
  }

  return $error
}

var getFieldSuccess = function (formId, fieldClass) {
  var $field = getField(formId, fieldClass)
  var $success = $field.find('form__field__success')

  if ($success.length === 0) {
    $success = $('<div>', { class: 'form__field__success' })
    $field.append($success)
  }

  return $success
}

// Show/Hide Field Options
var showForField = function (type, formId, fieldClass, validationText) {
  var $field = getField(formId, fieldClass)
  var isTypeSuccess = type === 'success'
  var getValidationForField =
    type === isTypeSuccess ? getFieldSuccess : getFieldError
  var $validation = getValidationForField(formId, fieldClass)
  $validation.text(validationText)
  $field.addClass(isTypeSuccess ? 'has-success' : 'has-error')
  return $field
}
var showErrorForField = function (formId, fieldClass, errorText) {
  showForField('error', formId, fieldClass, errorText)
}

var showSuccessForField = function (formId, fieldClass, successText) {
  showForField('success', formId, fieldClass, successText)
}

var hideValidationForField = function (formId, fieldClass) {
  var $field = getField(formId, fieldClass)
  // var $error = getFieldError(formId, fieldClass)
  $field.removeClass('has-error has success')
}

/**
 * Creates a select, with options to pass.
 * @param {string}  name -  Name of the select.
 * @param {[{value: string, text: string}]} options - An array of option definitions.
 * @param {string} action? - What you'd like the data-action property to be set to.
 */

var createSelectField = (name, options, action) => {
  var $select = $('<select></select>')
  $select.attr('name', name)
  $select.addClass('select')

  if (action) {
    $select.attr('data-action', action)
  }

  var $options = options.map(option => {
    var value = option.value
    // var text = option.text
    var isPlaceholder = option.isPlaceholder
    var $option = $('<option></option>')

    $option.text(option.text)

    if (isPlaceholder) {
      $option.attr('disabled', true)
      $option.attr('selected', true)
    } else {
      $option.attr('value', value)
    }

    return $option
  })

  $select.append($options)
  return $select
}

var createStyledSelect = (name, options) => {
  let placeholder = options.find(option => option.isPlaceholder)

  return $(`<div class="ca-select js-select">
    <input type="text" id="${name}" data-no-clear data-placeholder="${placeholder !== undefined ? placeholder.text : ''}" placeholder="${placeholder !== undefined ? placeholder.text : ''}" class="ca-select__input js-select-input set-value" autocomplete="off" />
    <div class="ca-select-arrow">
      <i class="ca-select-arrow-icon"></i>
    </div>                  
    <ul class="ca-select-options hidden-all" id="ca-select-list-${name}">
  ${options.map(option => (
    !option.isPlaceholder
      ? `<li data-value="${option.value}" data-display-name="${option.text}"><span>${option.text}<span></li>`
      : ``
  )).join('')}
    </ul>
  </div>`)
}

/**
 * Creates a Cauliflower-style button, with optional
 * @param {string} text - Text you'd like the button to have
 * @param {string} action? - A string, representing an action you'd like it to take. You must set the event handler separately.
 * @param {[{key: string, value: string}]} data? - Any extra dataset properties you might want to set by default.
 */

var createButton = (text, action, data) => {
  var $button = $('<button></button>')

  $button.addClass('button secondary')
  $button.text(text)

  if (action) {
    $button.attr('data-action', action)
  }

  if (data) {
    data.forEach(function (datum) {
      $button.attr('data-' + datum.key, datum.value)
    })
  }

  $('body').append($button)
  return $button
}

/**
 * Creates a Link that looks like a Cauliflower button.
 * @param {string} text - What the link should say.
 * @param {string} action? - Where the link should go.
 */
var createLinkButton = (text, href) => {
  var $link = $('<a/>')

  $link.addClass('button secondary selectable')
  $link.text(text)
  $link.attr('href', href)

  return $link
}

export default {
  showErrorForField: showErrorForField,
  showSuccessForField: showSuccessForField,
  showErrorForForm: showErrorForForm,
  showSuccessForForm: showSuccessForForm,
  hideValidationForForm: hideValidationForForm,
  hideValidationForField: hideValidationForField,
  createButton: createButton,
  createSelectField: createSelectField,
  createLinkButton: createLinkButton,
  createStyledSelect: createStyledSelect
}
