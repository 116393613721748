import $ from 'jquery'
import { getAttributeFromWindow } from '../../get/getAttribute'

export const postUpdateProfile = ({ formData, onSuccess, onError }) => {
  if (!formData) {
    console.log('No form data sent to postUpdateProfile.')
    return
  }

  const CONTEXT = getAttributeFromWindow('CONTEXT')
  const CSRF_TOKEN = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1')

  $.ajax({
    url: CONTEXT + '/api/v1/user/updateProfile',
    headers: {
      'X-XSRF-TOKEN': CSRF_TOKEN
    },
    data: formData,
    method: 'POST',
    dataType: 'json',
    success: onSuccess,
    error: onError
  })
}
