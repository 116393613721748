// @flow
import { throttle } from 'lodash'
import { getElement, getElements } from '../common/get/getElement'
import {
  getSelectedSchoolInfo,
  getInstitutionInfo,
  getControlsForField,
  getAllFields,
  getFieldValue,
  getControlForField,
  getField
} from './RegistrationGetters'
import { renderSearchResults } from './RegistrationRenderers'
import {
  isStepTwo,
  isPopulatedStepTwo,
  isSchoolSearchValid,
  isInformationModalValid,
  isPopulatedInstitution
} from './RegistrationValidators'
import { Field, Control, SchoolSearchMode } from './RegistrationTypes'
import { groupFieldsByDisabledStatus } from './RegistrationGroupers'
import { getSchoolResultElements } from '../common/CommonGetters'

const disableControl = (control: Control) => (control.disabled = true)
const enableControl = (control: Control) => (control.disabled = false)

export const enableField = (field: Field) => {
  if (!field.classList.contains('is-disabled')) return

  field.classList.remove('is-disabled')
  const controls = getControlsForField(field)
  controls.map(enableControl)
}

export const disableField = (field: Field) => {
  if (field.classList.contains('is-disabled')) return
  field.classList.add('is-disabled')
  const controls = getControlsForField(field)
  controls.map(disableControl)
}

export const hideItem = (element: HTMLElement) => {
  if (typeof element === 'string') element = getElement(element)
  element.classList.add('hide')
}

export const showItem = (element: HTMLElement) => {
  if (typeof element === 'string') element = getElement(element)
  if (element.classList.contains('hide')) element.classList.remove('hide')
  if (element.style.display === 'none') element.style.display = 'block'
}

export const toggleItem = (element: HTMLElement) => {
  if (typeof element === 'string') element = getElement(element)
  element.classList.toggle('hide')
}

export const showSchoolSearch = () => {
  showItem('[data-school-section="user"]')
  showItem('[data-school-section="school"]')
  showItem('[data-school-section="search-buttons"]')
}

export const hideSchoolSearch = () => {
  hideItem('[data-school-section="user"]')
  hideItem('[data-school-section="school"]')
  hideItem('[data-school-section="search-buttons"]')
}

export const showSearchResults = results => {
  const searchResults = getElement('[data-school-search-results]')
  hidePopulatedSchoolSearch()
  hideSchoolSearch()

  if (results) {
    const { schools, formData } = results
    searchResults.innerHTML = renderSearchResults({ schools, formData })
  }

  showItem('[data-school-section="results"]')
  showItem('[data-school-section="result-buttons"]')
  disableButton(getElement('[data-search-result-btn="next"]'))
}

export const hideSearchResults = () => {
  hideItem('[data-school-section="results"]')
  hideItem('[data-school-section="result-buttons"]')
  // hideItem('[data-school-search-results]')
}

export const showPopulatedSchoolSearch = () => {
  hideSearchResults()
  populateSchoolInfo()
  addPopulatedClass()
  showItem('[data-school-section="user"]')
  showItem('[data-school-section="populated"]')
  showItem('[data-school-section="populated-buttons"]')
  // showItem('[data-school-section="user"]')
  // showItem('[data-school-section="user"]')
}

export const showPopulateInstitute = () => {
  hideSearchResults()
  populateInstitutionInfo()
  addPopulatedClass()
  addPopulatedInstitutionClass()
  showItem('[data-school-section="user"]')
  showItem('[data-school-section="populated"]')
  showItem('[data-school-section="populated-buttons"]')
}

export const hidePopulatedSchoolSearch = () => {
  hideItem('[data-school-section="user"]')
  hideItem('[data-school-section="populated"]')
  hideItem('[data-school-section="populated-buttons"]')
  // showItem('[data-school-section="user"]')
  // showItem('[data-school-section="user"]')
}

// const hideFooterButtons = () => {
//   const buttons = getElements('[data-registration-form] .form__footer button')
//   buttons.map(button => button.classList.add('hide'))
// }

export const showFooterButtons = () => {
  const buttons = getElements('[data-registration-form] .form__footer button')
  buttons.map(button => button.classList.remove('hide'))
}

// export const replaceResultsButtons = () => {
//   const results = getElement('[data-school-search-results]')
//   if (isStepTwo() && !results.classList.contains('hide')) {
//     hideFooterButtons()
//   }
// }

export const showPopulatedButtons = () => {
  const populatedStep2Buttons = '.step-two__buttons'
  if (isStepTwo() && isPopulatedStepTwo()) {
    showItem(populatedStep2Buttons)
  }
}

export const hideFadeAtBottom = (element: Field, container: Field) => {
  element.onscroll = function () {
    // hides fade pseudo element when the scroll has reached the bottom of the container
    if (this.scrollTop + this.offsetHeight >= this.scrollHeight) {
      container.classList.add('hide-fade')
    } else {
      container.classList.remove('hide-fade')
    }
  }
}

export const addPopulatedClass = () => {
  const step2ClassList = getElement('[data-step="2"]').classList
  if (!isPopulatedStepTwo()) {
    step2ClassList.add('step-two--has-result')
  }
}

export const removePopulatedClass = () => {
  const step2ClassList = getElement('[data-step="2"]').classList
  if (isPopulatedStepTwo()) {
    step2ClassList.remove('step-two--has-result')
  }
}

export const addPopulatedInstitutionClass = () => {
  const step2ClassList = getElement('[data-step="2"]').classList
  if (!isPopulatedInstitution()) {
    step2ClassList.add('step-two--is-institution')
  }
}

export const removePopulatedInstitutionClass = () => {
  const step2ClassList = getElement('[data-step="2"]').classList
  if (isPopulatedInstitution()) {
    step2ClassList.remove('step-two--is-institution')
  }
}

export const hideSelectedSchool = () => {
  hideItem('[data-selected-school]')
}

export const populateSchoolInfo = () => {
  const schoolInfo = getSelectedSchoolInfo()
  const schoolNameField = getElement('[data-selected-school-name]')
  const schoolIdInput = getElement('[data-selected-school-id]')
  const schoolRegionField = getElement('[data-selected-school-region]')

  schoolIdInput.value = schoolInfo.id
  schoolNameField.innerHTML = schoolInfo.name
  schoolRegionField.innerHTML = schoolInfo.region
}

export const populateInstitutionInfo = () => {
  const info = getInstitutionInfo()
  const nameField = getElement('[data-selected-school-name]')
  const regionField = getElement('[data-selected-school-region]')
  const idInput = getElement('[data-selected-school-id]')

  nameField.innerHTML = info.name
  regionField.innerHTML = info.city
  idInput.value = info.id
}

export const setSchoolResultElementAsChecked = element => {
  element.dataset.selected = 'true'
  element.classList.add('search-results__item--checked')
}

export const setSchoolResultElementAsUnchecked = element => {
  delete element.dataset.selected
  element.classList.remove('search-results__item--checked')
}

export const isElementSchoolResult = element =>
  element.matches('[data-school-result]')

export const updateChecked = (event: Event) => {
  const results = getSchoolResultElements()
  const target = event.target
  const nextButton = getElement('[data-search-result-btn="next"]')
  results.map(result => {
    result.dataset.selected = 'false'
    result.classList.remove('search-results__item--checked')
  })

  if (nextButton) {
    disableButton(nextButton)
  }

  if (isElementSchoolResult(target)) {
    setSchoolResultElementAsChecked(target)
    if (nextButton) {
      enableButton(nextButton)
    }
  }
}

export const enableSearchResultNextButton = () => {
  enableButton(getElement('[data-search-result-btn="next"]'))
}

export const isASchoolSelected = () => {
  return !!getElement('[data-school-result][data-selected="true"]')
}

export const disableButton = (button: HTMLButtonElement) => {
  button.classList.add('disabled')
  button.setAttribute('disabled', true)
}

export const enableButton = (button: HTMLButtonElement) => {
  button.classList.remove('disabled')
  return button.removeAttribute('disabled')
}

export const updateFields = ({ disabled, enabled }) => {
  disabled.map(disableField)
  enabled.map(enableField)
}

const validateAndUpdateFields = () => {
  updateFields(groupFieldsByDisabledStatus(getAllFields()))
}

export const updateSchoolInputFormPlaceholderText = () => {
  const mode: SchoolSearchMode = getFieldValue('schoolMode')
  const control = getControlForField(getField('schoolInput'))
  switch (mode) {
    case 'ZIP':
      control.placeholder = 'School Zip Code'
      return
    case 'DISTRICT':
    case 'SCHOOL':
    default:
      control.placeholder = 'School Name'
  }
}

const validateAndUpdateSearchButton = () => {
  const schoolSearchButton = getElement('[data-action="search"]')
  if (isSchoolSearchValid()) {
    enableButton(schoolSearchButton)
  } else {
    disableButton(schoolSearchButton)
  }
}

const validateAndUpdateInstitutionButton = () => {
  const institutionSubmitButton = getElement(
    '[data-button-name="institution-submit"]'
  )

  if (isInformationModalValid()) {
    enableButton(institutionSubmitButton)
  } else {
    disableButton(institutionSubmitButton)
  }
}

export const hideSchoolSearchForm = () => {
  const step = getElement('[data-step="2"]')
  hideItem(step)
}

export const hideSchoolSearchButtons = () => {
  const backButton = getElement('[data-button-name="back"]')
  const searchButton = getElement('[data-button-name="search"]')
  const buttonsToHide = [backButton, searchButton]
  buttonsToHide.map(hideItem)
}

export const validate = () => {
  validateAndUpdateFields()
  validateAndUpdateSearchButton()
  validateAndUpdateInstitutionButton()
}

export const throttledValidate = throttle(validate, 50)

export const redoSearch = e => {
  e.preventDefault()
  hidePopulatedSchoolSearch()
  showSchoolSearch()
  removePopulatedClass()
  removePopulatedInstitutionClass()
  resetInstitutionModal()
}

export const disableStateField = () => {
  disableField(getElement('[data-field-name="state"]'))
}

export const enableStateField = () => {
  enableField(getElement('[data-field-name="state"]'))
}

export const populateInstitutionState = () => {
  const stateField = getElement('[data-institution-state]')
  const info = getInstitutionInfo()
  stateField.innerHTML = info.fullState
}

export const resetInstitutionModal = () => {
  const form = getElement('[data-form-name="institution"]')
  form.reset()
  validateAndUpdateInstitutionButton()
  validateAndUpdateFields()
}

export const toggleStateField = () => {
  if (isPopulatedInstitution()) {
    hideItem('[data-state-select]')
    showItem('[data-institution-state]')
    populateInstitutionState()
  } else {
    showItem('[data-state-select]')
    hideItem('[data-institution-state]')
  }
}
