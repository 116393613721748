// @flow
import { getFieldName } from './RegistrationGetters'
import { isValidField } from './RegistrationValidators'
import { FieldMap, Field } from './RegistrationTypes'
import { VALID_FIELD_NAMES } from './RegistrationConstants'
import { MapperFactory } from '../common/CommonFactories'
/// ///////////////////////
// Factories are functions that will return a function.
// Those functions will use the value from the first parameter.
// Example:
// __AppenderFactory = (thingToAppend: string) => (baseString: string) => baseString + thingToAppend
/// ///////////////////////

const __checkForMissingFieldsInMap = (
  fieldMap,
  allFieldNames = VALID_FIELD_NAMES
) => {
  const fieldNames = Object.keys(fieldMap)
  const missingFieldNames = allFieldNames.filter(
    name => !fieldNames.includes(name)
  )
  if (missingFieldNames.length > 0) {
    console.warn(
      `The following fields are missing in Map | ${missingFieldNames.join(' ')}`
    )
  }
}

export const FieldMapperFactory = (fieldMap: FieldMap, defaultReturn: any) => {
  __checkForMissingFieldsInMap(fieldMap)

  return (field: Field = null) => {
    if (!isValidField(field)) {
      throw new Error(`Not a valid FieldName: ${field}`)
    }

    return MapperFactory(fieldMap, defaultReturn)(getFieldName(field))
  }
}
