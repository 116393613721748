// @flow
import Mustache from 'mustache'
import SchoolSearchResultsTemplate from './templates/schoolSearchResults.html'
import { isUserLoggedIn } from '../common/CommonBooleans'

export const renderSearchResults = ({ schools = [], formData = {} }) => {
  return Mustache.render(SchoolSearchResultsTemplate, {
    schools,
    formData,
    hasSchools: schools.length > 0,
    isUserLoggedIn: isUserLoggedIn()
  })
}

export const renderPopulatedCustomSchool = () => {}
