import openToast from './openToast'
import logGoogleAnalytics from './logGoogleAnalytics'
import CauliflowerModal from '../../../../CauliflowerModal'

const addToGoogleDrive = data => {
  let toastData = {
    type: 'gray-warning',
    title: 'Adding to Google Drive...',
    timeout: 15000,
    message: 'The resource is being copied to your Google Drive. This may take a few moments.',
    showDismiss: true,
    noClose: true
  }
  CauliflowerModal.closeAll()
  openToast(toastData)

  const url = window.CONTEXT + data.destination
  fetch(url)
    .then((response) => response.json())
    .then((data) => {
      toastData = {
        type: 'success',
        title: 'Copy Successful',
        message: `The resource has been uploaded to your Google Drive.<br/><a href="${data.fileUrl}" target="_blank">Open in Google Drive <svg width="12" height="19" viewBox="0 -5 14 15"><path d="M7.59 1.09L13 6.5l-5.41 5.41M13 6.5H1" fill="none" stroke="#4c94fc" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path></svg></a>`,
        showDismiss: true,
        noClose: true
      }

      openToast(toastData)
    })
    .catch((err) => {
      toastData = {
        type: 'err',
        title: 'An Error Occurred',
        message: 'There was a problem while uploading the resource to your Google Drive. Please try again.',
        showDismiss: true,
        timeout: 15000
      }

      openToast(toastData)

      console.log(err)
    })

  logGoogleAnalytics(data.id, 'Google Drive', 'GoogleSlidesCopy')
}

export default addToGoogleDrive
