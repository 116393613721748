import openAssetModal from './openAssetModal'
import toggleAssetDrawer from './toggleAssetDrawer'
import selectGrade from './selectGrade'
import goBack from './goBack'
import logAssetOpen from './logAssetOpen'
import openModal from './openModal'
import openVideoModal from './openVideoModal'
import openProfileModal from './openProfileModal'
import modalStep from './modalStep'
import collapseBanner from './collapseBanner'
import assignToGoogleClassroom from './assignToGoogleClassroom'
import openToast from './openToast'
import toggleMenu from './toggleMenu'
import addToGoogleDrive from './addToGoogleDrive'
import copyLink from './copyLink'

const HANDLERS = {
  'open-asset-modal': openAssetModal,
  'toggle-asset-drawer': toggleAssetDrawer,
  'select-grade': selectGrade,
  'go-back': goBack,
  'log-asset-open': logAssetOpen,
  'open-video-modal': openVideoModal,
  'open-profile-modal': openProfileModal,
  'open-modal': openModal,
  'modal-step': modalStep,
  'collapse-banner': collapseBanner,
  'assign-to-google-classroom': assignToGoogleClassroom,
  'open-toast': openToast,
  'toggle-menu': toggleMenu,
  'add-to-google-drive': addToGoogleDrive,
  'copy-link': copyLink
}

export default HANDLERS
