// @flow
import { StateAbbreviation } from '../../types/GeneralTypes'

export type Field = HTMLElement
export type FieldName =
  | 'firstName'
  | 'lastName'
  | 'state'
  | 'schoolMode'
  | 'schoolInput'
  | 'institution-type'
  | 'institution-name'

export type FieldMap<T> = {
  [key: FieldName]: T
}

export type Control = HTMLInputElement | HTMLSelectElement

export type SchoolSearchMode = 'SCHOOL' | 'DISTRICT' | 'ZIP'

export type InstitutionType = 'School' | 'Personal'

export type SchoolSearchRequest = {
  state: StateAbbreviation,
  searchType: SchoolSearchMode,
  query: string
}

export type ValidationStatus = {
  valid: Field[],
  invalid: Field[]
}
export type DisabledStatus = {
  enabled: Field[],
  disabled: Field[]
}
