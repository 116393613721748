import _ from 'lodash'
import { AssetType, Asset } from '../variations/classroom/types/Asset.flow'
import { SectionId } from '../variations/classroom/types/Section.flow'
import {
  sortAssets,
  isAssetPrerequisiteForSection,
  isAssetVideo
} from '../get/getAsset'
import { createAssetTypeFilter } from '../variations/classroom/actions/toggleAssetDrawer'
import { reduceAssetsToSectionCollection } from '../get/getSection'
export default class Doctor {
  constructor (assets: Asset[]) {
    this.assets = assets
  }

  globalize () {
    window.doctor = this
  }

  get (path, defaultValue = null) {
    return _.get(this.assets, path, defaultValue)
  }

  set (path, value) {
    return _.set(this.assets, path, value)
  }

  map (callback = _.identity) {
    return new Doctor(this.assets.map(callback))
  }

  groupBy (groupingFunction) {
    return _.groupBy(this.assets, groupingFunction)
  }

  groupById () {
    return _.groupBy(this.assets, ({ id }) => id)
  }
  groupByType () {
    return _.groupBy(this.assets, ({ assetType }) => assetType)
  }

  groupBySession () {
    return _.groupBy(this.assets, ({ drawerLabel }) => drawerLabel)
  }

  groupBySection () {
    return this.assets.reduce(reduceAssetsToSectionCollection, {})
  }

  groupByPrereq (sectionId: SectionId) {
    return _.groupBy(this.assets, asset =>
      isAssetPrerequisiteForSection(asset, sectionId)
    )
  }

  groupByVideo () {
    return _.groupBy(this.assets, isAssetVideo)
  }

  groupByCleanliness () {
    return _.groupBy(this.assets, ({ fixedPrereq }) =>
      fixedPrereq ? 'clean' : 'unclean'
    )
  }

  sortByType (assetTypes: AssetType[] = []) {
    let types = assetTypes
    if (!Array.isArray(types)) types = assetTypes.split(' ')
    return new Doctor(sortAssets(this.assets, types))
  }

  areAssetsSortedByTypes (assetTypes: AssetType[] = []) {
    let types = assetTypes
    if (!Array.isArray(types)) types = assetTypes.split(' ')
    const assetToString = ({
      assetType,
      isPreqreqForSection,
      lesson,
      copyrightYear,
      id
    }) =>
      [
        assetType,
        isPreqreqForSection ? 'isPrereq' : 'isNotPrereq',
        lesson,
        copyrightYear,
        id
      ].join(' | ')
    const current = this.assets.map(assetToString)
    const sorted = this.sortByType(assetTypes).assets.map(assetToString)
    const matches = current.map((current, index) => {
      return current === sorted[index] ? '👍' : '👎'
    })
    console.table({ current, sorted, matches })
    return matches.every(match => match === '👍')
  }

  filterByType (assetTypes: AssetType[] = '') {
    const filter = createAssetTypeFilter(assetTypes)
    return new Doctor(this.assets.filter(filter))
  }

  count () {
    return this.assets.length
  }

  pipe (pipe) {
    return pipe(this.assets)
  }

  getSection (id) {
    const sections = this.groupBySection()
    return new Doctor(_.get(sections, [id, 'assets'], []))
  }
}
