import {
  isASchoolSelected,
  enableButton,
  disableButton,
  disableField,
  enableField
} from '../../../registration/RegistrationPresenters'
import { getElement } from '../../get/getElement'
import { SELECTORS, SCHOOL_SEARCH_FIELD_NAMES } from './ProfileModalConstants'
import { validateField } from '../../../registration/RegistrationValidators'
import { getField } from '../../../registration/RegistrationGetters'
import { getDependantFieldNames } from './ProfileModalMappers'

export const validateSchoolResults = () => {
  const selectButton = getElement(SELECTORS.schoolReselectSelectButton)
  if (isASchoolSelected()) {
    enableButton(selectButton)
  } else {
    disableButton(selectButton)
  }
}

export const validateSchoolSearch = () => {
  const searchButton = getElement(SELECTORS.schoolReselectSearchButton)

  if (SCHOOL_SEARCH_FIELD_NAMES.map(validateField).every(Boolean)) {
    enableButton(searchButton)
    return // No need to check again, we know the form is completed.
  } else {
    disableButton(searchButton)
  }

  SCHOOL_SEARCH_FIELD_NAMES.map(fieldName => {
    const field = getField(fieldName)
    const dependantFields = getDependantFieldNames(fieldName)
    if (dependantFields.every(validateField)) {
      enableField(field)
    } else {
      disableField(field)
    }
  })
}
